export default {
  special_catalog_disclaimer: `
    * Quantity of goods is limited<br />
    * Discounts on beef apply to the entire Korzinka supermarket chain, with the exception of Korzinka DISKONT branches
  `,
  special_catalog_disclaimer2: `
    * Buy 2 the same ice cream, get the third one as a gift<br />
    * The promotion is valid from June 22 to June 23
  `,
  hard_title: `This week's super prices`,
  read_more: 'Read more',
  byd_banner_title: 'Win <br /> BYD Song Plus',
  byd_banner_description:
    'Pay for goods for 200 000 soums <br /> and use the loyalty card',
  byd_banner_link:
    'https://korzinka.uz/en/news/publichnaya-oferta-pravila-provedeniya-reklamnoy-aktsii-vyigrayte-byd-song-plus-za-pokupku-v-korzink?id=1857',
  ibt: '',
  ibt_link: '#',
  rent: 'For Landlords',
  up_to: 'up to',
  location_empty: 'Please check if the spelling is correct and try again',
  // Discl
  discle: `
    <ul>
      <li>* The cost of goods in the catalog is indicated in soums</li>
      <li>* Catalog offers apply only to the korzinka supermarket chain.</li>
      <li>* The prices and assortment of products in the catalog may not match the prices and assortment of products in korzinka DISKONT, korzinka Mahalla and  Smart stores.</li>
      <li>* In the regions, the promotion is valid for chicken eggs from local suppliers.</li>
      <li>* The quantity of promotional goods is limited.</li>
      <li>* The cost of some products may be lower than indicated in the catalog.</li>
    </ul>
  `,
  // Permanent promotion
  permanent_promotion: {
    block_heading: 'Ongoing promotions',
    block_description_1: 'Price discrepancies',
    block_description_2: 'Discount for products with expired shelf life'
  },
  billione_short: 'billion',
  // Staff
  staff: {
    title: 'We are one big family',
    tab_1: 'All',
    tab_2: 'Supervisory Board',
    tab_3: 'Korzinka Senior Management'
  },
  // Search tools help products
  search_tools_help: {
    title: 'What to buy in Korzinka?',
    products: [
      {
        product: 'Beef'
      },
      {
        product: 'Bananas'
      },
      {
        product: 'Tomatoes'
      },
      {
        product: 'Chickenʻs meat'
      },
      {
        product: 'Sunflower oil'
      },
      {
        product: 'Drink'
      },
      {
        product: 'Apples'
      },
      {
        product: 'Baby formulas'
      },
      {
        product: 'Eggs'
      },
      {
        product: 'Lamb'
      }
    ]
  },
  // Cookie
  cookie: {
    text: 'We are using cookies to improve your experience from using our web site ',
    done: 'Got it'
  },

  main: 'Main page',
  charity: 'Charitable Giving',
  loading: 'Something tasty is loading...',
  //  Header menu
  catalog: 'Catalogue',
  loyalty: 'Loyalty',
  career: 'Careers',
  about: 'About Us ',
  stores: 'Stores ',
  suppliers: 'For Suppliers',
  news: 'News',
  use_brand: 'Use of Materials',
  feedback: 'Feedback ',
  still: 'More',
  best_offer: 'Top offers',
  all_products: 'All products',
  week_offer: `This week's super prices`,
  top10Products: 'Top-10 offers',
  // Header menu

  back: 'Back',

  back_to_catalog: 'Back to catalog',

  read_also: 'Read also',

  // Main page
  // Main_page news
  all_news: 'All news',
  // Main_page news

  // Main_page work section
  work: {
    info: 'Build a partnership with Korzinka',
    title: "Let's do <br /> business together ",
    detail: 'Learn more'
  },
  // Main_page work section

  // Main-page banners
  // Main-page banners

  // Main page and Loyality page - mobile application section
  mobile: {
    title_1: 'Always  ',
    title_2: 'nearby',
    description_1: '5 000 Salom-points when',
    description_2: 'you register a new loyalty card',
    description_3: 'in the application'
  },
  // Main page and Loyality page - mobile application section
  // Main page

  //  Loyality page
  // "How to get the points" section
  get_points: {
    title_1: 'Present your loyalty card whenever you',
    description_1: 'make a <br />purchases <br />at Korzinka',
    title_2: 'Receive bonus points',
    description_2: '1% for each <br> purchase ',
    title_3: 'Gain an extra',
    description_3:
      '1% when you spend <br/>1.3 million<br/>soum per month',
    title_4: 'Gain another',
    description_4:
      '1% when you spend <br/>4.5 million<br/>soum per quarter',
    title_5: 'When paying through a mobile application',
    description_5: '0,5% on all <br />purchases',
    title_6: 'Bonuses points can be kept for up to 12 months',
    description_6: 'Use your bonus points at the checkout to <br />make purchases'
  },
  // "How to get the points" - section

  // "Earn points faster" - section
  earn_points: {
    title: 'Earn bonuses points faster',
    description:
      'Visit Korzinka with your family! We will give you up to four cards linked to the same account and that way you can save faster as a family.'
  },
  // "Earn points faster" - section

  loyalty_card_title: 'Always available',
  loyalty_card_description: 'Get 5000 bonuses as gift for app installation ',
  loyalty_get_card_title: 'How to get a loyalty card',
  about_app: 'About the app',
  download_app: 'Download the application',

  cashback: {
    get_ball: 'How to save points'
  },

  // Questions and answers section
  // Questions and answers section
  // Loyality page

  // About us - page
  about_us: {
    history_title: ' 28 years of experience and our belief in quality has made us market leaders',
    comments_title: 'What our customers say about us:',
    shops_number_desc:
      '<span> Shops in Uzbekistan </span> <br /> Find out which store format is perfect for you',
    charity_title: 'Charitable Giving',
    charity_description: `
    Since 2008, our customers have been able to donate to a range of charities working in Uzbekistan by placing cash in the collection boxes in Korzinka supermarkets This year we are collecting for the Ezgu Amal charity foundation which works with sick children.
            `,
    charity_button: 'Learn more',
    currency: 'sum'
  },
  // About us - page

  //  Catalogs
  spec_offer: 'Special Offers',
  buy: 'Buy',
  lebazar: ' on Lebazar',
  korzinka: 'on Korzinka Go',
  //  Catalogs

  // Shops
  shops: {
    search_input: 'Enter address or name ',
    search_settings: 'Search settings ',
    shops_category: 'Store category ',
    korzinka_descr: 'Supermarket chain',
    smart_descr: 'neighborhood shops',
    redtag_descr: 'Dubai clothing brand for whole family',
    flo_descr: 'Turkish shoe store chain',
    services_title: 'Services',
    regions_title: 'Cities and regions',
    address: 'Address',
    orient: 'Landmark',
    work_time: 'Work time',
    phone: 'Phone',

    parking: 'Parking'
  },
  // Shops

  // News
  page_news: {
    title: 'What’s new at Korzinka?',
    more: 'More news'
  },
  // News

  // Materials guide page
  brand_use: {
    intro_title: 'Guidance on the use of our logo',
    intro_description: `
      On this page you can download the logos of the Korzinka supermarket chain in a format that is convenient for you.<br /><br />

      Use of trademarks is possible only with the written permission of the Marketing Department of FE "Anglesey Food" LLC. To obtain permission to use a trademark, please contact us by e-mail: info@korzinka.uz.
      Trademarks are protected by the legislation of the Republic of Uzbekistan.
    `,
    logo_block_title: 'Logo-block ',
    logo_block_descr:
      'This is a composite block of a logomark and a wordmark. We have two horizontal logo blocks and one vertical one.',
    logo_block_descr_sup1: 'Main horizontal logo-block ',
    logo_block_descr_sup2: 'Additional horizontal logo-block ',
    logo_block_descr_sup3: 'Vertical logo-block ',

    logo_mark_title: 'Logomark',
    logo_mark_descr:
      'korzinka logomark/sign exists in two variations: without an oval (main) and with an oval (additional). It is used if it is not possible to use the logo-block',
    logo_mark_descr_sup1: 'Main logomark ',
    logo_mark_descr_sup2:
      'The security zone of the logomark is equal to half the height of the sign - h',
    logo_mark_descr_sup3:
      ' On a light-colored background, <br> the main logomark is always red',
    logo_mark_descr_sup4: 'Additional logomark ',
    logo_mark_descr_sup5:
      'The security zone of the logomark is equal to half the height of the sign - h',
    logo_mark_descr_sup6:
      'On a light-colored background, the main logomark is always red',
    logo_mark_descr_sup7:
      'On a light-colored background, wordmark/logomark is always red',
    logo_mark_descr_sup8:
      'On a dark colored background, wordmark/logomark is always white ',
    logo_mark_descr_sup9:
      'On a red and dark background, <br /> the main logomark is always white',

    vektor: 'vector format',
    download: 'download',
    guideline_download: 'download the guidelines'
  },
  // Materials guide page

  //  Feedback page strings
  feedback_page: {
    title: 'We are getting better than usual for you',
    question: 'Do you have a question? Contact us in a convenient way',
    use_brand: 'Information about the logos use is ',
    here: 'here',

    address_title: 'Address: ',
    address: 'Tashkent,Yakkasaray district, Kichick Khalka yuli, 120',
    index: 'postal code: 100015',

    phone_title: 'phone',
    requisites_title: 'Corporate information ',
    requisites: `
            "ANGLESEY FOOD" FE LLC<br>
            Legal address: 100066, Tashkent city, Turob Tula str., 57. At the Besh Yogoch farmers market territory <br><br>
            Head office: 100015, Tashkent city, Kichik Khalka yuli, 120<br><br>
            Operating account: 2020 8000 6005 7890 2001 <br>
            National Bank for Foreign Economic Activity of Uzbekistan, "Aсadеmiс" banking services сenter <br>
            Bank code: 00450<br>
            Individual tax number: 202 099 756<br>
            CCЕA: 47110<br>
            VAT: 320060002860 `,

    feedback_title: 'Or you can tell us honestly what you think right here ',
    request_type: 'Type of submission*',
    request_type1: 'Question',
    request_type2: 'Complaint',
    request_type3: 'Suggestion',
    request_type4: 'Gratitude',
    request_type5: 'Other ',

    project_type: 'Brand*',
    project_type1: 'Question not related to a store ',
    project_type2: 'Korzinka',
    project_type3: 'REDTAG',
    project_type4: 'Smart',
    project_type5: 'FLO',

    name_request: 'Your name*',
    phone_request: 'Contact number*',
    email_request: 'email',
    message_request: 'Your feedback*',
    message_request1: 'Add comment',
    sybmols: ' symbols',
    attachment_request: 'Attach a picture ',
    attachment_request1: 'Attach a file*',
    send_request: 'Send '
  },
  //  Feedbackpage strings

  // footer
  hotline: 'Call - centre',
  privacy_policy: 'Privacy Policy',
  design: 'Designed by — ',
  slogan: 'Better every day',
  // footer

  charity_title: 'Information about donations',

  charity_obj: {
    date: 'Date',
    gathered_sum: 'Collected (UZS)',
    where_passed: 'Transferred to',
    supermarket: 'Supermarket'
  },

  // search tools
  search: 'Search',
  search_placeholder: 'Search in Korzinka...',

  search_tabs: {
    products: 'Goods',
    top10: 'TOP-10',
    specOffer: 'Special offers',
    news: 'Latest news',
    stores: 'Stores'
  },

  empty_result_title: 'Your search did not return any results',
  empty_result_description: 'But you can see our best offers',
  in_catalog: 'In catalog',

  error_title: 'The page cannot be found',
  error_text: 'But we have some offers for you',

  telegram_link: 'https://t.me/korzinkauz',

  kodeks: 'Code of Business Ethics',
  kodeks_title: 'Code of Business Ethics ИП ООО «Anglesey Food»',
  kodeks_description: `
  <div class="postPage">
  <div class="postContent">

    <p>Content</p>
    <ul>
      <li>
        <b>1.</b>
				Goals and objectives of the Code
      </li>
      <li>
        <b>2.</b>
        Scope of application
      </li>
      <li>
        <b>3.</b>
        Mission and Vision of the company
      </li>
      <li>
        <b>4.</b>
        Corporate values
      </li>
      <li>
        <b>5.</b>
        Basic ethical principles
      </li>
      <li>
        <b>6.</b>
        Business etiquette
      </li>
      <li>
        <b>7.</b>
        Confidentiality of information
      </li>
      <li>
        <b>8.</b>
        Storage
      </li>
    </ul>
		<br/><br/>
		<p>Article 1</p>
		<br/>
		<p>Goals and objectives of the Code</p>
		<br/>
    <ul>
      <li>FE “Anglesey Food” LLC   (hereinafter referred to as the Company) strives for honest, reliable and fair relationships with all interested parties: employees, clients, partners, government agencies. The Code of Business Ethics (hereinafter referred to as the Code) defines the ethical principles and values of the Company, supporting a corporate culture that will contribute to building such relationships.
				<br/>
				<br/>The Code is intended to help employees make sound decisions that are consistent with the company's values and ethical principles in difficult situations where there are no clear rules or direct control, and describes what an employee should do when the provisions of the Code are violated.
				<br/>
				<br/>If a Company employee still has questions about what to do and the answer is not found in this document, he/she can contact members of the Ethics Commission for clarification and assistance.
      </li>
		</ul>
		<br/><br/>
		<p>Article 2</p>
		<br/>
		<p>Application area</p>
		<br/>
    <ul>
      <li>The norms and principles set out in this Code are mandatory for all employees of the Company, regardless of the level of position they occupy or the territorial location of the unit.
				<br/><br/>Employees do not have the right to make decisions or take any actions that are contrary to the values and ethical principles of the Company.
				<br/><br/>All Company employees must personally familiarize themselves with the requirements of this Code, follow them and confirm this by signing a Declaration of familiarization and agreement with the requirements of the Code (Appendix 5).
				<br/><br/>Heads of all departments are obliged to monitor employees' compliance with the requirements of this Code.
				<br/><br/>Each employee must understand that failure to comply with the requirements established in this Code entails disciplinary action, up to and including dismissal.
				<br/><br/>The process for submitting notifications, complaints about inappropriate behavior provided for by the Company Code or the legislation of the Republic of Uzbekistan, and the Company’s response to it is described in the Violation Notification Policy.
      </li>
		</ul>
		<br/><br/>
		<p>Article 3</p>
		<br/>
		<p>Mission and Vision of the Company</p>
		<br/>
    <ul>
      <li>
			<b>Company's mission:</b><br/>
      We carry the culture of modern retail trade, preserving the best national traditions.
			<br/><br/>
			<b>Company Vision:</b><br/>
Leader, reliable partner, innovator and pioneer of modern retail trade in Uzbekistan. We understand our clients, partners, and employees better than others. We have convenient locations, modern technologies, quality products, service and a friendly atmosphere.
      </li>
		</ul>
		<br/><br/>
		<p>Article 4</p>
		<br/>
		<p>Corporate values</p>
		<br/>
    <ul>
      <li>
				<b>Customer focus</b><br/>
		    Deep understanding of the client and effective satisfaction of his needs are always our priority.
				<br/><br/>
				<b>Transparency and Mutual Respect</b><br/>
				We act openly, honestly and respectfully towards our clients, partners and employees
				<br/><br/>
				<b>Engagement</b><br/>
				We expect from our employees responsibility, initiative, a sense of ownership and independence in making decisions for the benefit of the company.
				<br/><br/>
				<b>Innovation</b><br/>
				We are constantly developing and working on ourselves, introducing new technologies, improving business processes and wisely using company resources.
				<br/><br/>
				<b>Excellence in everything</b><br/>
				We are continuously improving and exceeding the expectations of our clients, partners and shareholders.
				<br/>
				<i>* In the context of this Code, the word “We” means all employees of the Company.</i>
      </li>
		</ul>
		<br/><br/>
		<p>Article 5</p>
		<br/>
		<p>Basic ethical principles</p>
		<br/>
    <ul>
      <li>
				<b>Honesty and trust</b><br/>
				All employees have a responsibility to protect the Company's reputation for integrity and transparency in the retail industry. Company employees are required to act professionally, fairly and honestly in all business dealings.
				<br/><br/>
				We respect our competitors. We constantly analyze the activities of existing and potential competitors, identify their strengths and weaknesses, but always comply with the rules of fair competition and regulations.
				<br/><br/>
				We ensure compliance with high ethical standards in relations with the media and do not allow the dissemination of false information, concealment and/or distortion of facts in our advertising activities.
				<br/><br/>
				<b>Compliance with laws and corporate regulations</b><br/>
        All Company employees must strictly adhere to the requirements of local and international legislation, internal procedures and Company regulations. The Company has zero tolerance for any illegal activity committed for personal gain or on behalf of the Company.
				<br/><br/>
				The company adheres to compliance with antitrust laws. The selection of suppliers and contractors of the Company is carried out in accordance with the principles of free competition.
				<br/><br/>
				All accounting and other documents must comply with the requirements of current legislation, applicable accounting and reporting standards, and adopted internal regulations. The Company must not have unaccounted for, unregistered or hidden assets.
				<br/><br/>
				Employees must not attempt, directly or indirectly, to improperly influence or interfere with the audit process of the Company's financial statements and internal controls. It is necessary to provide candid answers to all questions from internal or external auditors.
				<br/><br/>
				Any violation of these requirements by the employee should be immediately reported to the chief accountant and the Ethics Commission.
				<br/><br/>
				<b>Responsibility to clients</b><br/>
				The company offers its customers high quality and safe products at affordable prices, putting human health and the reliability of the goods sold above any financial gain, putting the interests of the client above short-term high profits.
				<br/><br/>
				The Company is committed to responsible marketing to customer groups that are relatively vulnerable to abusive marketing practices, such as children.
				<br/><br/>
				<b>Employee Relations </b></br>
				The Company's employees are of great value and are the key to our success. The company should strive to become a workplace where employees can realize their potential in an open and inspiring work environment. The company builds relationships with its employees on the principles of long-term cooperation, mutual respect and strict fulfillment of mutual obligations.
				<br/><br/>
				The company values its staff for: <br/>
				•	competence and professionalism, <br/>
				•	responsibility and discipline, <br/>
				•	initiative and focus on results, <br/>
				•	desire for professional growth,<br/>
				•	loyalty to the Company, <br/>
				•	integrity. <br/><br/>
				The Company strives to provide equal career opportunities for all employees and candidates. This means that the Company’s employees, regardless of position, type and method of communication, reject favoritism and treat colleagues fairly. In all personnel matters (recruitment, hiring, training of employees, remuneration and career development) we do not discriminate between people based on gender, age, race, color, language, national or social origin, religion, marital status, property status.
				<br/><br/>
				The Company establishes mechanisms to prevent, report and investigate physical, verbal, sexual or psychological violence and harassment in the workplace. The company takes the necessary measures in accordance with current legislation to provide its employees with a healthy and safe working environment. For more information, please see our Anti-Harassment and Anti-Discrimination Policy.</b>
				<br/><br/>
				The Company does not tolerate forced labor of any form. Workers must be allowed to move freely and leave their workplaces after the end of the working day.
				<br/><br/>
				The Company does not tolerate the use of child labor under any circumstances. The minimum age for full-time employment must be the same as the minimum legal age for employment.
				<br/><br/>
				The Company maintains an open door policy for all employees. This means for any employee that his immediate supervisor is obliged to listen to his subordinate and respond accordingly.
				<br/><br/>
				The Company welcomes the active participation of employees in the life of the Company, which contributes to the development of corporate culture and internal cooperation, appreciates and in every possible way encourages any ideas of employees, the implementation of which will allow us to become better and more competitive.
				<br/><br/>
				The company does its best to maintain a favorable socio-psychological atmosphere in the team. All disagreements between employees are resolved by their immediate supervisor, if necessary, in cooperation with an authorized person of the Human Resources Department. It is unacceptable to make any conflict the property of third parties.
				<br/><br/>
				<b>External communications</b></br>
				The Marketing Department is responsible for compiling and disseminating information in the media. Apart from the team of the Marketing Department, top managers (in terms of their area) and members of the Supervisory Board, no other employee can speak with the media on behalf of the Company, make statements in the press and television and radio broadcasting institutions, organizations on social networks about the activities of FE “Anglesey Food" LLC without the prior approval of the Director of the Marketing Department.
				<br/><br/>
				Each employee of the Company must understand and always remember that any point of view expressed by him as an employee of the Company or disseminated information directly relates to the Company itself, its image and affects its reputation in the market. All external communications must be fair, complete, accurate and not misleading.
				<br/><br/>
				The Company expects all its employees to adhere to the External Communications Policy.</b>
				<br/><br/>
				Company employees can take part in political activities in their free time using their own resources. Employees must carefully distinguish between their independent political activities and their activities as a representative of FE “Anglesey Food” LLC. However, unless otherwise stated in applicable laws and regulations, these activities must be conducted outside normal business hours and resources and the company name must not be used. However, the company is not responsible for the political activities of the employee.
				<br/><br/>
				<b>Relations with suppliers and business partners</b></br>
				The Company acts professionally, fairly and honestly in all relationships with suppliers and business partners; does not provide commercial advantages through illegal or unethical means and does not engage in activities that would violate competition law.
				<br/><br/>
				The Company maintains business relationships with supplier companies that maintain safe and healthy work environments and regularly reviews compliance with these standards. The company supports improvements in the company's supply chain policies and practices, including safety and the environment.
				<br/><br/>
				The Company expects all business partners to act in accordance with applicable laws.
				<br/><br/>
				Employees must not take any action that entails personal gain at the expense of the Company. Employees must not engage in fraudulent activities when conducting tenders, competitions and trades, when concluding and executing transactions, exceed official authority, or commit any other deceptive acts.
				<br/><br/>
				Employees should adhere to the following recommendations when assessing whether or not to accept gifts, the presentation of which is in one way or another related to their relationship with the Company:
				<br/>
				•	Gifts should not be solicited. Employees, their families and affiliates are strictly prohibited from soliciting or receiving gifts or favors of any kind in exchange for allowing the giver to influence decisions made within the Company.
				<br/>
				•	Gifts should not be accepted, the receipt of which may entail any obligations on the recipient towards the donor.
				<br/>
				•	Gifts should be not accepted in the form of cash or securities.
				<br/>
				•	Gifts worth more than 500,000 sum can be accepted only if refusal of the gift could be perceived as an insult. Such gifts must be reported to the Ethics Commission immediately.
				<br/><br/>
				Employees are required to report to the Ethics Commission any personal interests or activities that may harm the interests or reputation of the Company, and must evaluate potential conflicts of interest and promptly notify their supervisor if any exist. For more details, see the Conflict of Interest Statement.</b>
				<br/><br/>
				<b>Fighting bribery and corruption</b></br>
				The Company's employees must not make improper payments to obtain improper benefits in any form to government officials, government officials, employees of international organizations, and members of their families in exchange for official influence in the interests of the Company. To avoid any situations involving improper payments (including those made for the purpose of achieving commercial or other benefit for the Company), employees must use common sense.
				<br/><br/>
				Entertainment programs for government officials must be justified, must not go beyond what is reasonable, and must not be directly related to the Company’s activities. Likewise, all gifts must be of a reasonable minimum value, not exceeding UZS 500,000, and must not be intended to obtain an unfair advantage in representing the interests of the Company in certain matters.
				<br/><br/>
      </li>
		</ul>
		<br/><br/>
		<p>Article 6</p>
		<br/>
		<p>Business Etiquette</p>
		<br/>
    <ul>
      <li>
				an employee of the Company, regardless of position, type and method of communication, adheres to the following rules: <br/>
				•	showing mutual respect for colleagues; <br/>
				•	controlling emotions (do not use obscene language, speak in a raised voice, do not engage in assault);<br/>
				•	suppressing intrigues, rumors, gossip; <br/>
				•	discussing actions, not personality; <br/>
				•	respecting the private (personal) life of employees, respecting the sovereignty of the individual;<br/>
				•	not transferring friendly relations into the work environment;<br/>
				•	not showing colleagues a bad mood;<br/>
				•	thanking colleagues for a good job.
      </li>
		</ul>
		<br/><br/>
		<p>Article 7</p>
		<br/>
		<p>Confidentiality of information</p>
		<br/>
    <ul>
      <li>
				Employees must ensure the safety and prevent the dissemination of confidential information of the Company inside and outside the Company, this includes: <br/>
				•	strategic and operational plans of the Company; <br/>
				•	information on the financial performance and marketing activities of the Company; <br/>
				•	information about the Company's suppliers and partners; <br/>
				•	information about the technologies, equipment, and automated systems used in the Company; <br/>
				•	information about the Company’s technical security systems; <br/>
				•	information about the Company’s personnel (staffing table, personal data of employees, salary data, etc.); <br/>
				•	internal regulatory documents.
				<br/><br/>
				information about internal conflicts, disagreements and incidents that affect the personal lives of colleagues or hurt the feelings of colleagues.
				<br/><br/>
				Internal regulatory documents that were developed by employees during the period of work in the Company are the property of the Company. Upon joining the FE “Anglesey Food” LLC, employees undertake the obligation to maintain trade secrets. The obligation to maintain confidential information of FE “Anglesey Food” LLC is not canceled by termination of employment with the Company.
      </li>
		</ul>
		<br/><br/>
		<p>Article 8</p>
		<br/>
		<p>Storage</p>
		<br/>
    <ul>
      <li>
				The original Code of Business Conduct is kept in the Human Resources Department.
      </li>
		</ul>
  </div>
</div>
	`,

  ppl_title: 'Loyalty Program Rules',

  offerta_page_title: 'Public offer 2.0',

  // 2021 year
  year_of_charity2021: {
    table: [
      {
        date: '	29.04.2021',
        gathered_sum: '564 120 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Аэропорт'
      },
      {
        date: '08.04.2021',
        gathered_sum: '23 034 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Риёзий'
      },
      {
        date: '07.04.2021',
        gathered_sum: '17 382 152',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара 2'
      },
      {
        date: '06.04.2021',
        gathered_sum: '19 800 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },
      {
        date: '25.03.2021',
        gathered_sum: '36 514 311',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара 1'
      },
      {
        date: '24.02.2021',
        gathered_sum: '16 806 850',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ракат'
      },
      {
        date: '02.02.2021',
        gathered_sum: '21 615 500',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },
      {
        date: '28.01.2021',
        gathered_sum: '12 348 750',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Спутник-Сергели'
      },
      {
        date: '26.01.2021',
        gathered_sum: '22 358 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Караташ'
      },

      {
        date: '06.01.2021',
        gathered_sum: '14 930 000	',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Next'
      },

      {
        date: '06.01.2021',
        gathered_sum: '10 735 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Камолон'
      }
    ],
    year: 'Итого за 2021 г.',
    sum: '759 644 963'
  },
  // 2020 year
  year_of_charity2020: {
    table: [
      {
        date: '22.12.2020',
        gathered_sum: '25 026 600',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'С.Азимова'
      },
      {
        date: '16.12.2020',
        gathered_sum: '5 090 100',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Oazis'
      },
      {
        date: '07.12.2020',
        gathered_sum: '15 798 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Алмалык'
      },
      {
        date: '01.12.2020',
        gathered_sum: '12 955 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Фонд "Эзгу Амал"'
      },
      {
        date: '30.11.2020',
        gathered_sum: '2 950 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Янгийль'
      },
      {
        date: '26.11.2020',
        gathered_sum: '8 751 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Куйлюк'
      },
      {
        date: '25.11.2020',
        gathered_sum: '17 358 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Файзабад'
      },
      {
        date: '24.11.2020',
        gathered_sum: '16 375 950',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Mercato'
      },
      {
        date: '24.11.2020',
        gathered_sum: '9 741 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Андижан'
      },

      {
        date: '	23.11.2020',
        gathered_sum: '21 212 750',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Карасу'
      },

      {
        date: '19.11.2020',
        gathered_sum: '18 000 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '18.11.2020',
        gathered_sum: '22 381 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чилонзар'
      },

      {
        date: '17.11.2020',
        gathered_sum: '24 288 350',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Абай'
      },

      {
        date: '16.11.2020',
        gathered_sum: '25 393 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сайрам'
      },

      {
        date: '14.11.2020',
        gathered_sum: '34 306 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сергели'
      },

      {
        date: '14.11.2020',
        gathered_sum: '14 037 250',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Юнусабад'
      },

      {
        date: '	09.11.2020',
        gathered_sum: '21 108 150',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },

      {
        date: '09.11.2020',
        gathered_sum: '32 080 600',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Риёзий'
      },

      {
        date: '30.06.2020',
        gathered_sum: '17 631 950',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },

      {
        date: '30.06.2020',
        gathered_sum: '7 304 500',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Заамин'
      },

      {
        date: '30.06.2020',
        gathered_sum: '3 746 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Аэропорт'
      },

      {
        date: '29.06.2020',
        gathered_sum: '5 927 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Oazis'
      },

      {
        date: '29.06.2020',
        gathered_sum: '11 996 900',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ракат'
      },

      {
        date: '29.06.2020',
        gathered_sum: '6 591 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Салом'
      },

      {
        date: '27.06.2020',
        gathered_sum: '22 246 900',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара-1'
      },

      {
        date: '27.06.2020',
        gathered_sum: '9 195 591',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара-2'
      },

      {
        date: '27.06.2020',
        gathered_sum: '9 715 750',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара-3'
      },

      {
        date: '27.06.2020',
        gathered_sum: '14 496 700',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Андижан'
      },

      {
        date: '27.06.2020',
        gathered_sum: '3 010 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Асака'
      },

      {
        date: '27.06.2020',
        gathered_sum: '14 650 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Коканд'
      },

      {
        date: '27.06.2020',
        gathered_sum: '20 304 700',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бунёдкор'
      },

      {
        date: '27.06.2020',
        gathered_sum: '7 714 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чилонзар'
      },

      {
        date: '27.06.2020',
        gathered_sum: '12 116 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бешкайрагач'
      },

      {
        date: '26.06.2020',
        gathered_sum: '19 132 150',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Next'
      },

      {
        date: '26.06.2020',
        gathered_sum: '19 684 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Алайский'
      },

      {
        date: '26.06.2020',
        gathered_sum: '3 407 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Mercato'
      },

      {
        date: '26.06.2020',
        gathered_sum: '16 803 450',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Vega'
      },

      {
        date: '26.06.2020',
        gathered_sum: '6 691 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '26.06.2020',
        gathered_sum: '6 397 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Назарбек'
      },

      {
        date: '26.06.2020',
        gathered_sum: '19 058 300',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бекабад'
      },

      {
        date: '26.06.2020',
        gathered_sum: '38 166 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Карасу'
      },

      {
        date: '26.06.2020',
        gathered_sum: '7 714 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Integro'
      },

      {
        date: '24.06.2020',
        gathered_sum: '14 755 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'А.Дониш'
      },

      {
        date: '24.06.2020',
        gathered_sum: '12 015 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Юнусабад'
      },

      {
        date: '23.06.2020',
        gathered_sum: '13 955 850',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Алмалык'
      },

      {
        date: '23.06.2020',
        gathered_sum: '14 056 050',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Себзар'
      },

      {
        date: '23.06.2020',
        gathered_sum: '11 113 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Куйлюк'
      },

      {
        date: '18.06.2020',
        gathered_sum: '13 831 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Янгийюль'
      },

      {
        date: '17.06.2020',
        gathered_sum: '26 147 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чирчик'
      },

      {
        date: '10.06.2020',
        gathered_sum: '21 885 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Спутник - Сергели'
      },

      {
        date: '09.06.2020',
        gathered_sum: '16 811 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Караташ'
      },

      {
        date: '05.06.2020',
        gathered_sum: '17 438 050',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ангрен'
      },

      {
        date: '04.06.2020',
        gathered_sum: '18 243 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Учтепа'
      },

      {
        date: '03.06.2020',
        gathered_sum: '23 867 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Авиасозлар'
      },

      {
        date: '28.05.2020',
        gathered_sum: '20 804 550',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Авиасозлар'
      },

      {
        date: '27.05.2020',
        gathered_sum: '28 051 500',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Аэропорт'
      },

      {
        date: '26.05.2020',
        gathered_sum: '34 882 250',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Абай'
      },

      {
        date: '21.05.2020',
        gathered_sum: '16 929 950',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Шедевр'
      },

      {
        date: '21.05.2020',
        gathered_sum: '30 662 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сайрам'
      },

      {
        date: '19.05.2020',
        gathered_sum: '23 506 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Салом'
      },

      {
        date: '18.05.2020',
        gathered_sum: '14 406 550',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ал-Хоразмий'
      },

      {
        date: '14.05.2020',
        gathered_sum: '19 100 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '13.05.2020',
        gathered_sum: '30 366 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чиланзар'
      },

      {
        date: '08.05.2020',
        gathered_sum: '24 550 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'С. Азимова'
      },

      {
        date: '07.05.2020',
        gathered_sum: '32 900 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Риёзий'
      },

      {
        date: '06.05.2020',
        gathered_sum: '21 121 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Файзабад'
      },

      {
        date: '05.05.2020',
        gathered_sum: '36 176 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сергели'
      },

      {
        date: '26.03.2020',
        gathered_sum: '35 313 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },

      {
        date: '19.03.2020',
        gathered_sum: '26 233 750',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Юнусабад'
      },

      {
        date: '16.03.2020',
        gathered_sum: '18 581 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бешкайрагач'
      },

      {
        date: '	16.03.2020',
        gathered_sum: '21 977 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Камолон'
      },

      {
        date: '18.02.2020',
        gathered_sum: '23 726 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ракат'
      },

      {
        date: '11.02.2020',
        gathered_sum: '19 653 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Куйлюк'
      },

      {
        date: '08.02.2020',
        gathered_sum: '22 825 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Саракулька'
      },

      {
        date: '01.02.2020',
        gathered_sum: '6 415 850',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ургут'
      },

      {
        date: '31.01.2020',
        gathered_sum: '28 513 150',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Карасу'
      },

      {
        date: '30.01.2020',
        gathered_sum: '13 169 000',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Бухара 3'
      },

      {
        date: '30.01.2020',
        gathered_sum: '11 240 000',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Бухара 2'
      },

      {
        date: '30.01.2020',
        gathered_sum: '35 287 500',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Бухара'
      },

      {
        date: '30.01.2020',
        gathered_sum: '21 288 700',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Алмалык'
      },

      {
        date: '24.01.2020',
        gathered_sum: '17 363 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Караташ'
      },

      {
        date: '15.01.2020',
        gathered_sum: '22 100 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '08.01.2020',
        gathered_sum: '29 868 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Абай'
      }
    ],

    year: 'Итого за 2020 г.',
    sum: '1 521 686 691'
  },
  // 2019 year
  year_of_charity2019: {
    table: [
      {
        date: '26.12.2019',
        gathered_sum: '30 299 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Салом'
      },
      {
        date: '26.12.2019',
        gathered_sum: '29 162 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Бунёдкор'
      },
      {
        date: '11.12.2019',
        gathered_sum: '29 162 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Сайрам'
      },
      {
        date: '03.12.2019',
        gathered_sum: '32 419 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Туркменский'
      },
      {
        date: '29.11.2019',
        gathered_sum: '29 899 500',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'С.Азимова'
      },
      {
        date: '27.11.2019',
        gathered_sum: '24 733 750',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов "Мехрибонлик" Юнусабадского района города Ташкент',
        supermarket: 'Некст'
      },
      {
        date: '26.11.2019',
        gathered_sum: '24 457 000',
        where_passed: 'Детский дом №23',
        supermarket: 'Меркато'
      },
      {
        date: '21.11.2019',
        gathered_sum: '31 703 350',
        where_passed:
          '"Опа - Сингиллар" Кибрайское районное общество женщин-инвалидов.',
        supermarket: 'Чилонзар'
      },
      {
        date: '20.11.2019',
        gathered_sum: '31 574 250',
        where_passed:
          'Общества Инвалидов Узбекистана города Ташкента "Умид Нури"',
        supermarket: 'Аэропорт'
      },

      {
        date: '19.11.2019',
        gathered_sum: '28 955 600',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Риёзий'
      },

      {
        date: '15.11.2019',
        gathered_sum: '22 122 000',
        where_passed:
          'Общество Инвалидов Узбекистана города Ташкента отделение Яккасаройского района',
        supermarket: 'Учтепа'
      },

      {
        date: '13.11.2019',
        gathered_sum: '12 815 200',
        where_passed:
          'Зааминского районного фонда "Соглом авлод учун" Узбекистана',
        supermarket: 'Заамин'
      },

      {
        date: '07.11.2019',
        gathered_sum: '18 299 400',
        where_passed:
          'Ташкентский городской отдел объединения "VETERAN" воинов-ветеранов и инвалидов Узбекистана',
        supermarket: 'Бекабад'
      },

      {
        date: '01.11.2019',
        gathered_sum: '27 921 200',
        where_passed: 'РИО ва РИАТМТ Онкологиии Радиология г.Ташкента',
        supermarket: 'Сергели'
      },

      {
        date: '25.10.2019',
        gathered_sum: '19 382 300',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Бешкайрагач'
      },

      {
        date: '22.10.2019',
        gathered_sum: '10 532 150',
        where_passed: 'Бектимерское Общество Инвалидов города Ташкента',
        supermarket: 'Файзабад'
      },

      {
        date: '13.09.2019',
        gathered_sum: '15 651 000',
        where_passed: 'РЕСПУБЛИКА УЗБЕКИСТАН УП NOGIRONLARGA MARKAZI',
        supermarket: 'Шедевр'
      },

      {
        date: '06.09.2019',
        gathered_sum: '4 623 000',
        where_passed:
          'Районное Общество Инвалидов Булокбоши Андижанской области Республики Узбекистан',
        supermarket: 'Асака'
      },

      {
        date: '05.09.2019',
        gathered_sum: '16 605 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Андижан'
      },

      {
        date: '28.08.2019',
        gathered_sum: '25 352 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Абая'
      },

      {
        date: '16.08.2019',
        gathered_sum: '21 623 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'ТТЗ'
      },

      {
        date: '08.08.2019',
        gathered_sum: '20 927 100',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Юнусабад'
      },

      {
        date: '06.08.2019',
        gathered_sum: '29 007 200',
        where_passed: 'Ташкентский областной Дом Ребёнка "Кичкинтой"',
        supermarket: 'Туркменский'
      },

      {
        date: '25.07.2019',
        gathered_sum: '10 857 150',
        where_passed:
          'Ургутское отделение Узбекского Общества Инвалидов Самаркандской области',
        supermarket: 'Ургут'
      },

      {
        date: '23.07.2019',
        gathered_sum: '19 677 500',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Карасу'
      },

      {
        date: '16.07.2019',
        gathered_sum: '14 938 500',
        where_passed: 'Дом "Мехрибонлик" № 30',
        supermarket: 'Караташ'
      },

      {
        date: '09.07.2019',
        gathered_sum: '17 554 550',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Чирчик'
      },

      {
        date: '02.07.2019',
        gathered_sum: '25 720 850',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Риёзий'
      },

      {
        date: '27.06.2019',
        gathered_sum: '30 307 400',
        where_passed:
          '"Возможно Всё" Общественно-Благотворительное Объединение. г.Бухара',
        supermarket: 'Бухара 1'
      },

      {
        date: '27.06.2019',
        gathered_sum: '5 202 000',
        where_passed:
          'Бухарское городское отделение Общества слепых Узбекистана',
        supermarket: 'Бухара 3'
      },

      {
        date: '27.06.2019',
        gathered_sum: '13 042 200',
        where_passed:
          'Бухарское городское отделение Общества слепых Узбекистана',
        supermarket: 'Бухара 2'
      },

      {
        date: '11.06.2019',
        gathered_sum: '12 871 300',
        where_passed: 'Nogironlarni ximoya qilish uk',
        supermarket: 'А.Дониш'
      },

      {
        date: '28.03.2019',
        gathered_sum: '19 540 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Ракат'
      },

      {
        date: '27.03.2019',
        gathered_sum: '13 808 850',
        where_passed: 'Общество Инвалидов г.Коканд',
        supermarket: 'Коканд'
      },

      {
        date: '22.02.2019',
        gathered_sum: '10 441 600',
        where_passed: 'Бекабадское Общество Инвалидов',
        supermarket: 'Бекабад'
      },

      {
        date: '21.02.2019',
        gathered_sum: '22 569 850',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Караташ'
      },

      {
        date: '07.02.2019',
        gathered_sum: '15 760 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Алгоритм'
      },

      {
        date: '31.01.2019',
        gathered_sum: '16 850 000',
        where_passed: 'Букинское отделение Общества Инвалидов Узбекистана',
        supermarket: 'Алайский'
      },

      {
        date: '30.01.2019',
        gathered_sum: '19 513 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Карасу'
      },

      {
        date: '29.01.2019',
        gathered_sum: '30 120 920',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Туркменский'
      },

      {
        date: '22.01.2019',
        gathered_sum: '14 676 900',
        where_passed:
          '1. Фонд «Эзгу Амал» — 14 676 900 сум. 2. Отделение Узбекистанское общество инвалидов — 4 000 000 сум',
        supermarket: 'Next'
      },

      {
        date: '22.01.2019',
        gathered_sum: '4 000 000',
        where_passed: '',
        supermarket: 'Некст'
      },

      {
        date: '15.01.2019',
        gathered_sum: '33 274 400',
        where_passed: 'Онкология ва Радиология табиий маркази',
        supermarket: 'Mercato'
      },

      {
        date: '07.01.2019',
        gathered_sum: '27 393 100',
        where_passed: 'Общество инвалидов Узбекистана',
        supermarket: 'Риёзий'
      }
    ],

    year: 'Итого за 2019 г.',
    sum: '915 604 070'
  },

  // 2018 year
  year_of_charity2018: {
    table: [
      {
        date: '26.12.2018',
        gathered_sum: '28 022 000',
        where_passed:
          'аучно-исследовательский институт гематологии и переливания крови',
        supermarket: 'Сергели'
      },
      {
        date: '25.12.2018',
        gathered_sum: '17 036 000',
        where_passed:
          'Общество Инвалидов Узбекистана г.Ташкента. Отделение Студенческого городка «Ёшлик»',
        supermarket: 'Абай'
      },
      {
        date: '20.12.2018',
        gathered_sum: '15 323 350',
        where_passed:
          'Специализированная школа-интернат №102 для слабослышащих детей',
        supermarket: 'Учтепа'
      },
      {
        date: '11.12.2018',
        gathered_sum: '21 802 000',
        where_passed: 'Фонд «EZGU AMAL»',
        supermarket: 'Юнусабад'
      },
      {
        date: '28.11.2018',
        gathered_sum: '18 099 320',
        where_passed: 'ДПИ «MEHRIBON-NOGIRON»',
        supermarket: 'Чиланзар'
      },
      {
        date: '26.11.2018',
        gathered_sum: '10 610 900',
        where_passed:
          'Сергелинское, Яккасарайского района общество слепых Республика Узбекистана',
        supermarket: 'Куйлюк'
      },
      {
        date: '23.11.2018',
        gathered_sum: '16 920 950',
        where_passed:
          'Узбекское Общество Инвалидов Шайхонтахурское районное отделение города Ташкента',
        supermarket: 'Сайрам'
      },
      {
        date: '21.11.2018',
        gathered_sum: '17 626 300',
        where_passed: '«Центр Культуры Глухих» города Ташкента',
        supermarket: 'Аэропорт'
      },
      {
        date: '09.11.2018',
        gathered_sum: '14 349 945',
        where_passed:
          '«Общество Инвалидов» Чирчикского городского отделения Ташкентской области',
        supermarket: 'ТТЗ'
      },

      {
        date: '05.11.2018',
        gathered_sum: '18 238 000',
        where_passed:
          '«Общество Инвалидов» Юнусабадского отделения города Ташкента',
        supermarket: 'Бунедкор'
      },

      {
        date: '24.10.2018',
        gathered_sum: '11 725 300',
        where_passed: '«Общество Инвалидов Узбекистана» Навоинской области',
        supermarket: 'Бухара'
      },

      {
        date: '12.10.2018',
        gathered_sum: '10 663 000',
        where_passed: '«Сахобат» дом-интернат для престарелых города Андижан',
        supermarket: 'Андижан'
      },

      {
        date: '08.10.2018',
        gathered_sum: '29 621 945',
        where_passed: '«Узбекистон ногиронлар жамияти» Тошкент шахар булими',
        supermarket: 'Салом'
      },

      {
        date: '17.08.2018',
        gathered_sum: '19 824 000',
        where_passed: 'Специализированная Школа №36',
        supermarket: 'Туркменский'
      },

      {
        date: '15.08.2018',
        gathered_sum: '12 255 700',
        where_passed: '«Общество Инвалидов» города Ангрена',
        supermarket: 'Алмалык'
      },

      {
        date: '27.07.2018',
        gathered_sum: '10 344 500',
        where_passed:
          'Бухарское городское отделение Общества слепых Узбекистана',
        supermarket: 'Бухара 1'
      },

      {
        date: '25.07.2018',
        gathered_sum: '13 072 150',
        where_passed: '«Общество Инвалидов» города Ташкента',
        supermarket: 'Корасу'
      },

      {
        date: '13.07.2018',
        gathered_sum: '17 453 000',
        where_passed: 'Общество помощи инвалидам «Rumit-Inval»',
        supermarket: 'ТТЗ'
      },

      {
        date: '14.06.2018',
        gathered_sum: '14 082 175',
        where_passed:
          'Детям с нарушениями в психофизическом развитии «Областной специализированный дом ребенка»',
        supermarket: 'Аэропорт'
      },

      {
        date: '12.06.2018',
        gathered_sum: '18 418 661',
        where_passed: 'Дом ребенка города Янгиюль',
        supermarket: 'Сергели'
      },

      {
        date: '07.06.2018',
        gathered_sum: '14 460 525',
        where_passed:
          '«Узбекское общество инвалидов» Юнусабадкого районного отделения г.Ташкента',
        supermarket: 'Караташ'
      },

      {
        date: '29.05.2018',
        gathered_sum: '20 722 150',
        where_passed:
          '«Общество социальной взаимопомощи инвалидов» Мирабадского районного отделения г.Ташкента',
        supermarket: 'Азимова'
      },

      {
        date: '18.05.2018',
        gathered_sum: '14 280 750',
        where_passed:
          'Тошкент шахар «Ижтимоий Узаро Йордам МЕХРИБОНЛИК Ногиронлар Жамияти»',
        supermarket: 'Риёзий'
      },

      {
        date: '17.05.2018',
        gathered_sum: '12 091 100',
        where_passed:
          '«Общество Инвалидов Узбекистана» Яшнабадского районного отделения г.Ташкента',
        supermarket: 'Куйлюк'
      },

      {
        date: '15.05.2018',
        gathered_sum: '11 485 040',
        where_passed: 'Детский дом №23',
        supermarket: 'Integro'
      },

      {
        date: '04.05.2018',
        gathered_sum: '16 357 600',
        where_passed:
          'Детям с заболеваниями онкологии обществ «Возможно ВСЕ», «Давайте сотворим чудо»',
        supermarket: 'Бухара'
      },

      {
        date: '24.04.2018',
        gathered_sum: '11 591 900',
        where_passed:
          '«Узбекское Общество Инвалидов» Алмазарского районного отделения города Ташкента',
        supermarket: 'Vega'
      },

      {
        date: '16.04.2018',
        gathered_sum: '10 445 000',
        where_passed:
          'ОСВИ «Мехрибонлик» Яккасарайского района города Ташкента',
        supermarket: 'Next'
      },

      {
        date: '13.04.2018	',
        gathered_sum: '7 964 800',
        where_passed: 'Общество Женщин Инвалидов «Опа-Сингиллар» ЛИК',
        supermarket: 'Сайрам'
      },

      {
        date: '11.04.2018',
        gathered_sum: '16 367 400',
        where_passed: 'Научно-Исследовательский институт Гематологии',
        supermarket: 'Абая'
      },

      {
        date: '28.03.2018',
        gathered_sum: '5 813 900',
        where_passed:
          'Общество инвалидов «Мурувват» отделение Бутакора Андижанской области',
        supermarket: 'Андижан'
      },

      {
        date: '28.02.2018',
        gathered_sum: '12 787 800',
        where_passed: 'Ташкентский Онкологический диспансер',
        supermarket: 'Туркменский'
      },

      {
        date: '22.02.2018',
        gathered_sum: '13 876 800',
        where_passed: '«Общество Инвалидов Узбекистана» города Ташкента',
        supermarket: 'Карасу'
      },

      {
        date: '22.02.2018',
        gathered_sum: '17 925 830',
        where_passed: 'Специализированная школа №37',
        supermarket: 'Авиатор'
      },

      {
        date: '13.02.2018',
        gathered_sum: '17 428 960',
        where_passed:
          '«Общество Инвалидов Узбекистана» города Ташкента Отделение Студенческого городка «Ёшлик»',
        supermarket: 'Сергели'
      },

      {
        date: '06.02.2018',
        gathered_sum: '15 823 950',
        where_passed:
          'ОДИ «Ногирон–Болалар Камалак уйи». Приобретение лекарственных препаратов и продуктов питания, материальная помощь семьям с детьми инвалидами',
        supermarket: 'Ракат'
      },

      {
        date: '01.02.2018',
        gathered_sum: '15 650 800',
        where_passed:
          'Общество Женщин-Инвалидов Кибрайского района «Опа-Сингиллар»',
        supermarket: 'ТТЗ'
      },

      {
        date: '05.01.2018',
        gathered_sum: '7 666 625',
        where_passed: 'Бектемирское Общество Инвалидов города Ташкента',
        supermarket: 'Сайрам'
      },

      {
        date: '05.01.2018',
        gathered_sum: '7 129 660',
        where_passed:
          'Общество Социальной Взаимопомощи «Мехрибонлик» Юнусабадского районного отделения',
        supermarket: 'Учтепа'
      },

      {
        date: '04.01.2018',
        gathered_sum: '9 183 000',
        where_passed: '«Общество Инвалидов» Ташкентского района',
        supermarket: 'Аэропорт'
      },

      {
        date: '04.01.2018',
        gathered_sum: '13 019 900',
        where_passed: 'Международный Женский Общественный Фонд «Sharq Ayoli»',
        supermarket: 'Mercato'
      }
    ],

    year: 'Итого за 2018 г.',
    sum: '607 562 686'
  },

  //  2017 year
  year_of_charity2017: {
    table: [
      {
        date: '21.12.2017',
        gathered_sum: '7 045 900',
        where_passed: 'Школа интернат для глухонемых №80',
        supermarket: 'Алмалык'
      },
      {
        date: '19.12.2017',
        gathered_sum: '13 943 700',
        where_passed: 'Общество Инвалидов Юнусабадского отделение',
        supermarket: 'Абай'
      },
      {
        date: '13.12.2017',
        gathered_sum: '12 588 800',
        where_passed: 'Общество «Возможно Всё!», «Давайте сотворим чудо!»',
        supermarket: 'Бухара DISKONT'
      },
      {
        date: '11.12.2017',
        gathered_sum: '11 085 000',
        where_passed: 'Центр Культуры Глухих города Ташкента',
        supermarket: 'Integro'
      },
      {
        date: '09.11.2017',
        gathered_sum: '7 892 700',
        where_passed: 'Учтепинское Общество Инвалидов «Мехрибонлик»',
        supermarket: 'Караташ'
      },
      {
        date: '08.11.2017',
        gathered_sum: '11 514 200',
        where_passed: '«Мехрибонлик» №23',
        supermarket: 'Туркменский'
      },
      {
        date: '08.11.2017',
        gathered_sum: '12 445 560',
        where_passed: 'Чиланзарское Общество Инвалидов',
        supermarket: 'Юнусабад'
      },
      {
        date: '07.11.2017',
        gathered_sum: '15 021 300',
        where_passed:
          'Общество инвалидов Ташкентской области с целью организации праздников и оказания материальной помощи',
        supermarket: 'Салом'
      },
      {
        date: '05.10.2017',
        gathered_sum: '11 428 377',
        where_passed: 'Городское Общество Инвалидов г.Ташкента',
        supermarket: 'Риёзий'
      },

      {
        date: '21.08.2017',
        gathered_sum: '14 662 300',
        where_passed: 'Общество Инвалидов Алмазарского района',
        supermarket: 'Абай'
      },

      {
        date: '18.08.2017',
        gathered_sum: '13 664 700',
        where_passed: 'Общество Инвалидов Яшнобадского района',
        supermarket: 'ТТЗ'
      },

      {
        date: '18.08.2017',
        gathered_sum: '12 027 270',
        where_passed: '«Мехрибонлик» ОСВИ Мирабадского отделения',
        supermarket: 'Аэропорт'
      },

      {
        date: '16.08.2017',
        gathered_sum: '16 397 670',
        where_passed: 'ОСВИ « Мехрибонлик » Мирзо Улугбекского района',
        supermarket: 'Азимова'
      },

      {
        date: '16.08.2017',
        gathered_sum: '12 442 575',
        where_passed: 'Отделение Сергелинского района Общество Слепых',
        supermarket: 'Сергели'
      },

      {
        date: '06.07.2017',
        gathered_sum: '10 588 700',
        where_passed:
          'Узбекское Общество инвалидов Чирчикского городского отделения',
        supermarket: 'Алмалык'
      },

      {
        date: '03.07.2017',
        gathered_sum: '8 815 230',
        where_passed: 'Общество инвалидов Шайхонтахурского районного отделения',
        supermarket: 'Карасу'
      },

      {
        date: '29.06.2017',
        gathered_sum: '18 790 650',
        where_passed: 'ОО «АPLA»',
        supermarket: 'Mercato'
      },

      {
        date: '28.06.2017',
        gathered_sum: '11 490 350',
        where_passed: '«Ногирон Болалар Камалак уйи»',
        supermarket: 'Integro'
      },

      {
        date: '14.06.2017',
        gathered_sum: '10 402 135',
        where_passed: 'Институт Гематологии и переливания крови',
        supermarket: 'Туркменский'
      },

      {
        date: '12.04.2017',
        gathered_sum: '9 976 680',
        where_passed: 'Общество женщин-инвалидов «Опа-сингиллар»',
        supermarket: 'Караташ'
      },

      {
        date: '29.03.2017',
        gathered_sum: '11 958 800',
        where_passed: 'Ташкентский онкологический диспансер',
        supermarket: 'Абай'
      },

      {
        date: '24.03.2017',
        gathered_sum: '14 106 430',
        where_passed: 'Студентам-инвалидам Городского общества «Ёшлик»',
        supermarket: 'Авиатор'
      },

      {
        date: '17.03.2017',
        gathered_sum: '9 806 500',
        where_passed: 'Ташкентский онкологический диспансер',
        supermarket: 'Сергели'
      },

      {
        date: '10.03.2017',
        gathered_sum: '12 576 300',
        where_passed: 'Яккасарайский район «Мехрибонлик». Медикаменты',
        supermarket: 'ТТЗ'
      },

      {
        date: '03.03.2017',
        gathered_sum: '11 232 900',
        where_passed:
          'Юнусабадское районное отделение г.Ташкент «Узбекское общество инвалидов»',
        supermarket: ''
      },

      {
        date: '22.02.2017',
        gathered_sum: '9 961 910',
        where_passed: 'Бектемирское общество инвалидов',
        supermarket: 'Ракат'
      },

      {
        date: '12.01.2017',
        gathered_sum: '11 782 100',
        where_passed: 'Общество инвалидов Ташкентского городского отделения',
        supermarket: 'Туркменский'
      },

      {
        date: '09.01.2017',
        gathered_sum: '6 473 260',
        where_passed:
          'Общество социальной взаимопомощи инвалидов «Мехрибонлик»',
        supermarket: 'Карасу'
      },

      {
        date: '06.01.2017',
        gathered_sum: '13 424 400',
        where_passed: 'Общество Женщин-инвалидов «Опа-сингиллар»',
        supermarket: 'Integro'
      },

      {
        date: '05.01.2017',
        gathered_sum: '11 659 800',
        where_passed: 'Международный женский общественный фонд «Sharq Ayoli»',
        supermarket: 'Аэропорт'
      }
    ],

    year: 'Итого за 2017 г.',
    sum: '355 206 197'
  },

  //  2016 year
  year_of_charity2016: {
    table: [
      {
        date: '26.10.2016',
        gathered_sum: '11 510 920',
        where_passed:
          'Общество инвалидов Алмазарского районного отделения г.Ташкент',
        supermarket: 'Юнусабад'
      },
      {
        date: '31.08.2016',
        gathered_sum: '8 833 500',
        where_passed: 'Общество инвалидов Бектемирского районного отделения',
        supermarket: 'Карасу'
      },
      {
        date: '31.08.2016',
        gathered_sum: '6 729 200',
        where_passed: 'Центр культуры глухих г.Ташкент',
        supermarket: 'ТТЗ'
      },
      {
        date: '19.08.2016',
        gathered_sum: '12 395 700',
        where_passed: 'Детский дом №30',
        supermarket: 'Mercato'
      },
      {
        date: '17.08.2016',
        gathered_sum: '6 380 000',
        where_passed: 'Детский дом “Мехрибонлик” №23',
        supermarket: 'Туркменский'
      },
      {
        date: '15.06.2016',
        gathered_sum: '11 421 000',
        where_passed: 'РОСВИ “Мехрибонлик” Учтепинского района',
        supermarket: ''
      },
      {
        date: '14.06.2016',
        gathered_sum: '12 186 300',
        where_passed: 'Общество инвалидов Яшнабадского района',
        supermarket: ''
      },
      {
        date: '13.06.2016',
        gathered_sum: '6 673 000',
        where_passed: '«Sanvikt»',
        supermarket: ''
      },
      {
        date: '09.06.2016',
        gathered_sum: '12 522 500',
        where_passed: '«Status» Инвалиды спинальников',
        supermarket: ''
      },

      {
        date: '08.06.2016',
        gathered_sum: '17 173 700',
        where_passed: '«Лик»',
        supermarket: ''
      },

      {
        date: '18.05.2016',
        gathered_sum: '8 961 800',
        where_passed: 'Вспомогательная школа-интернат №105',
        supermarket: ''
      },

      {
        date: '11.05.2016',
        gathered_sum: '10 578 610',
        where_passed: 'ОО «APLA»',
        supermarket: ''
      },

      {
        date: '20.04.2016',
        gathered_sum: '8 862 500',
        where_passed: 'Общество инвалидов Мирзо-Улугбекского района',
        supermarket: ''
      },

      {
        date: '16.03.2016',
        gathered_sum: '7 647 700',
        where_passed:
          'Узбекское общество инвалидов и дети-инвалиды Алмалыкского района',
        supermarket: ''
      },

      {
        date: '24.02.2016',
        gathered_sum: '10 497 300',
        where_passed:
          'ОДИ «Ногирон –Болалар Камалак уйи». Приобретение лекарственных препаратов и продуктов питания, материальная помощь семьям с детьми инвалидами',
        supermarket: ''
      },

      {
        date: '17.02.2016',
        gathered_sum: '9 589 770',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов «Мехрибонлик» Миробадского района',
        supermarket: ''
      },

      {
        date: '27.01.2016',
        gathered_sum: '14 487 000',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов «Мехрибонлик» Миробадского района',
        supermarket: ''
      },

      {
        date: '20.01.2016',
        gathered_sum: '11 605 000',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов «Мехрибонлик» Миробадского района',
        supermarket: ''
      }
    ],

    year: 'Итого за 2016 г.',
    sum: '188 055 500'
  },

  //  2015 year
  year_of_charity2015: {
    table: [
      {
        date: '23.12.2015',
        gathered_sum: '4 729 520',
        where_passed: 'Вспомогательная школа №36',
        supermarket: 'Аэропорт'
      },
      {
        date: '16.12.2015',
        gathered_sum: '11 940 800',
        where_passed: 'Детский дом №23',
        supermarket: 'Туркменский'
      },
      {
        date: '09.12.2015',
        gathered_sum: '9 878 000',
        where_passed: '«Опа-Сингиллар»',
        supermarket: 'Сергели'
      },
      {
        date: '02.12.2015',
        gathered_sum: '6 852 025',
        where_passed: '«Опа-Сингиллар». Лечение и обследование',
        supermarket: 'Integro'
      },
      {
        date: '25.11.2015',
        gathered_sum: '7 530 800',
        where_passed: '«Мурувват 2». Подгузники',
        supermarket: 'Караташ'
      },
      {
        date: '28.10.2015',
        gathered_sum: '11 652 920',
        where_passed: '«Мурувват 1». Лекарства и Витам. продукты',
        supermarket: 'Mercato'
      },
      {
        date: '04.10.2015',
        gathered_sum: '11 065 150',
        where_passed: 'Янгиюлский Дом Малютки. На мебель',
        supermarket: 'Юнусабад'
      },
      {
        date: '28.08.2015',
        gathered_sum: '9 460 955',
        where_passed: '«Sanvikt». Разв. кн., ремонт отоп. сист. каб.',
        supermarket: 'ТТЗ'
      },
      {
        date: '21.08.2015',
        gathered_sum: '14 904 500',
        where_passed: 'Детский дом №23. Портфели и учебные принадлежности',
        supermarket: 'Туркменский'
      },

      {
        date: '21.08.2015',
        gathered_sum: '7 505 600',
        where_passed: 'Детский дом №30. Учебные принадлежности и ремонт',
        supermarket: 'Ракат'
      },

      {
        date: '31.07.2015',
        gathered_sum: '12 266 200',
        where_passed: '«Umid-Nuri». Лечение и обследование детей',
        supermarket: 'Integro'
      },

      {
        date: '31.07.2015',
        gathered_sum: '14 969 970',
        where_passed: '«Мехрибонлик», ремонт крыши',
        supermarket: 'Абая'
      },

      {
        date: '31.07.2015',
        gathered_sum: '7 588 950',
        where_passed:
          'Бектемирское общество инвалидов. Оказание материальной помощи и прод. подарки',
        supermarket: 'Аэропорт'
      },

      {
        date: '31.07.2015',
        gathered_sum: '11 029 000',
        where_passed:
          'Бектемирское общество инвалидов. Ремонт учебных помещений',
        supermarket: 'Азимова'
      },

      {
        date: '12.06.2015',
        gathered_sum: '9 124 100',
        where_passed: 'Школа №37. Организация детского праздника',
        supermarket: 'Mercato'
      },

      {
        date: '15.05.2015',
        gathered_sum: '6 639 200',
        where_passed:
          'Городская Онкология, Детский отдел. На приобретение твёрдого инвентаря',
        supermarket: 'Караташ'
      },

      {
        date: '01.05.2015',
        gathered_sum: '8 379 500',
        where_passed: 'Общество «Veteran». Материальная помощь семьям погибших',
        supermarket: 'ТТЗ'
      },

      {
        date: '13.03.2015',
        gathered_sum: '10 635 500',
        where_passed: 'OO «APLA». Подарки и продукты к Наврузу',
        supermarket: 'Туркменский'
      },

      {
        date: '05.03.2015',
        gathered_sum: '7 659 500',
        where_passed: '«Sanvikt». На керамическую мастерскую',
        supermarket: 'Сергели'
      },

      {
        date: '12.02.2015',
        gathered_sum: '10 461 595',
        where_passed: 'Центр Культуры Глухих. Работа со слепоглухонемыми',
        supermarket: 'Integro'
      },

      {
        date: '05.02.2015',
        gathered_sum: '7 135 300',
        where_passed: 'Свято-Никол. Монастырь. Помощь нуждающимся',
        supermarket: 'Mercato'
      },

      {
        date: '16.01.2015',
        gathered_sum: '9 031 900',
        where_passed: 'Детский дом №2. Покупка обуви мальчикам',
        supermarket: 'Абай'
      },

      {
        date: '12.01.2015',
        gathered_sum: '7 253 300',
        where_passed:
          'Епархия. Оказание помощи социально уязвимым слоям населения',
        supermarket: 'ТТЗ'
      }
    ],

    year: 'Итого за 2015 г.',
    sum: '217 694 285'
  },

  //  2014 year
  year_of_charity2014: {
    table: [
      {
        date: '30.12.2014',
        gathered_sum: '4 682 500',
        where_passed: 'Серг.общ. «Мехрибон» Орг. Н.Г. и Помощь Лебещак Э.',
        supermarket: ''
      },
      {
        date: '26.11.2014',
        gathered_sum: '4 506 300',
        where_passed:
          'Бектемирское общ-во инвалидов, Организация праздника ко Дню Инвалидов',
        supermarket: ''
      },
      {
        date: '20.11.2014',
        gathered_sum: '7 934 200',
        where_passed: 'Абдулазизова Азиза. Порок сердца. Операция в Германии',
        supermarket: ''
      },
      {
        date: '07.11.2014',
        gathered_sum: '3 785 500',
        where_passed: 'Нуритдинов Самандар. ДЦП. Лечение КБ 5 в Ташкенте',
        supermarket: ''
      },
      {
        date: '31.10.2014',
        gathered_sum: '6 515 300',
        where_passed:
          'Гафуржанов Жахонгир. Острый лейкоз. Лечение в Москве, РДКБ',
        supermarket: 'Азимова'
      },
      {
        date: '24.10.2014',
        gathered_sum: '7 844 700',
        where_passed: 'Шелкунов Саша. Острый лейкоз. Лечение в НИИ Гематологии',
        supermarket: 'Integro'
      },
      {
        date: '10.10.2014',
        gathered_sum: '3 733 500',
        where_passed: 'Иргашева Жамиля. Кома 2. Лечение в России',
        supermarket: 'Ракат'
      },
      {
        date: '08.10.2014',
        gathered_sum: '4 327 400',
        where_passed:
          'Аракельян Карен. Аномалии мочевого пузыря. Операция в Индии',
        supermarket: 'ТТЗ'
      },
      {
        date: '26.09.2014',
        gathered_sum: '4 794 000',
        where_passed: 'Турсунбоев Жавохир. ДЦП после огнестрельного ранения',
        supermarket: 'Сергели'
      },

      {
        date: '11.09.2014',
        gathered_sum: '6 434 700',
        where_passed: 'Таирова Махлиё. Микроцефалия ЗПМР ДЦП. Лечение в России',
        supermarket: 'Mercato'
      },

      {
        date: '10.09.2014',
        gathered_sum: '8 817 200',
        where_passed:
          'Маъмуржановы Абдуллох и Азизбек. Врожденное наследственное ЗПМР ЗПРР. Лечение в Ташкенте',
        supermarket: 'Юнусабад'
      },

      {
        date: '09.09.2014',
        gathered_sum: '5 657 980',
        where_passed: 'Тулягановы Хумоюн и Аббос. Порок сердца',
        supermarket: 'Аэропорт'
      },

      {
        date: '08.09.2014',
        gathered_sum: '4 945 100',
        where_passed: 'Курбанов Тимур. Порок сердца. Операция в Индии',
        supermarket: 'Караташ'
      },

      {
        date: '06.08.2014',
        gathered_sum: '8 960 655',
        where_passed: 'Пардаева Камила. Порок сердца. Операция в Индии',
        supermarket: 'Туркменский'
      },

      {
        date: '05.08.2014',
        gathered_sum: '4 790 200',
        where_passed: 'Малыгин Миша. Острый Лейкоз. Лечение в НИИ Гематологии',
        supermarket: 'ТТЗ'
      },

      {
        date: '04.08.2014',
        gathered_sum: '5 485 050',
        where_passed: 'Эргашов Абдукадыр. ДЦП ЗПРР Лечение в Индии',
        supermarket: 'Абай'
      },

      {
        date: '25.06.2014',
        gathered_sum: '5 035 215',
        where_passed: 'Нажимова Мафтуна. Операция на глаза в Уфе',
        supermarket: 'Mercato'
      },

      {
        date: '24.06.2014',
        gathered_sum: '5 305 400',
        where_passed:
          'Центр Культуры Глухих. Благоустройство помещений работы с инвалидами',
        supermarket: 'Караташ'
      },

      {
        date: '26.05.2014',
        gathered_sum: '5 595 000',
        where_passed: 'Улькина Виктория. На лечение в Ж/Д больнице',
        supermarket: 'Сергели'
      },

      {
        date: '21.05.2014',
        gathered_sum: '5 725 000',
        where_passed:
          '«Sanvikt». На организацию летнего отдыха детей-инвалидов',
        supermarket: 'ТТЗ'
      },

      {
        date: '05.05.2014',
        gathered_sum: '8 485 000',
        where_passed: 'Муротова Умида. Операция на глаза в Уфе',
        supermarket: 'Азимова'
      },

      {
        date: '24.04.2014',
        gathered_sum: '7 925 000',
        where_passed: 'Бахтияров Мирзахид. Лечение в Гематологии',
        supermarket: 'Абай'
      },

      {
        date: '03.04.2014',
        gathered_sum: '8 525 000',
        where_passed: 'Театр «Лик» Помощь в теат. раб. с инвал',
        supermarket: 'Туркменский'
      },

      {
        date: '02.04.2014',
        gathered_sum: '4 145 000',
        where_passed: 'Музафарова Мафтуна. Лейкоз. Лечение в Москве',
        supermarket: 'Ракат'
      },

      {
        date: '26.03.2014',
        gathered_sum: '4 233 100',
        where_passed: 'Эргашева Сожидахон. ДЦП. Операция за границей',
        supermarket: 'Mercato'
      },

      {
        date: '14.03.2014',
        gathered_sum: '7 360 000',
        where_passed: 'Янгиюльский Дом Малютки. На мебель',
        supermarket: 'Аэропорт'
      },

      {
        date: '11.03.2014',
        gathered_sum: '5 462 500',
        where_passed: 'Нурмаев Григорий. Операция в Индии',
        supermarket: 'Караташ'
      },

      {
        date: '24.01.2014',
        gathered_sum: '5 655 000',
        where_passed: 'Шарипов Абдулазиз. Лечение в онкологии',
        supermarket: 'Юнусабад'
      },

      {
        date: '23.01.2014',
        gathered_sum: '3 455 000',
        where_passed: 'Юсупова Мохира. ДЦП. Лечение в Индии',
        supermarket: 'Mercato'
      },

      {
        date: '16.01.2014',
        gathered_sum: '6 691 500',
        where_passed: 'Занозин Арсений. Сморщив-е почки. На операцию',
        supermarket: 'Сергели'
      }
    ],

    year: 'Итого за 2014 г.',
    sum: '176 812 000'
  },

  // 2013 year
  year_of_charity2013: {
    table: [
      {
        date: '20.12.2013',
        gathered_sum: '7 710 000',
        where_passed: '«Мурувват 1» на лекарства, витамины и продовольствие',
        supermarket: 'Туркменский'
      },
      {
        date: '13.12.2013',
        gathered_sum: '7 826 240',
        where_passed: 'Камилов Мурод. Операция на сердце в Индии',
        supermarket: 'Абай'
      },
      {
        date: '29.11.2013',
        gathered_sum: '3 423 000',
        where_passed: 'Тухтамурадов Сирож. Операция на сердце в Индии',
        supermarket: 'Mercato'
      },
      {
        date: '05.11.2013',
        gathered_sum: '5 344 990',
        where_passed: 'Аракельян Карен. Трансплантология почки',
        supermarket: 'Караташ'
      },
      {
        date: '24.10.2013',
        gathered_sum: '6 496 000',
        where_passed: 'Кит Дарья. Лечение печени в Москве',
        supermarket: 'Азимова'
      },
      {
        date: '23.09.2013',
        gathered_sum: '5 114 200',
        where_passed: 'Аноним. Операция в Индии',
        supermarket: 'Mercato'
      },
      {
        date: '19.09.2013',
        gathered_sum: '8 060 000',
        where_passed: 'Сергелийское общество инвалидов. К Курбан-хайиту',
        supermarket: 'Аэропорт'
      },
      {
        date: '13.09.2013',
        gathered_sum: '4 051 300',
        where_passed: '«Sanvikt» на ремонт помещений для занятий',
        supermarket: 'Юнусабад'
      },
      {
        date: '13.08.2013',
        gathered_sum: '3 780 000',
        where_passed: 'Бектемирское общество инвалидов. Ко Дню Независимости',
        supermarket: 'Ракат'
      },

      {
        date: '26.07.2013',
        gathered_sum: '3 892 000',
        where_passed: 'Аноним',
        supermarket: 'Караташ'
      },

      {
        date: '25.07.2013',
        gathered_sum: '6 793 000',
        where_passed: 'Фарходова Шахризода. Опухоль мозга – Индия',
        supermarket: 'Абай'
      },

      {
        date: '23.07.2013',
        gathered_sum: '4 827 000',
        where_passed: 'Фаткулина Сабрина. Операция за границей',
        supermarket: 'Туркменский'
      },

      {
        date: '05.07.2013',
        gathered_sum: '5 089 950',
        where_passed: 'Театр «Лик». На аренду и образ. прогр',
        supermarket: 'Mercato'
      },

      {
        date: '10.06.2013',
        gathered_sum: '5 533 695',
        where_passed: 'Городское общество «УМР», на День Защиты детей',
        supermarket: 'Сергели'
      },

      {
        date: '17.05.2013',
        gathered_sum: '4 451 600',
        where_passed:
          'Алмазарское отделение инвалидов. Материальная помощь малоимущим',
        supermarket: 'Караташ'
      },

      {
        date: '16.05.2013',
        gathered_sum: '6 265 200',
        where_passed: 'Тулкинжинов Фаррух. Операция Имплантат за границей',
        supermarket: 'Туркменский'
      },

      {
        date: '17.04.2013',
        gathered_sum: '4 836 295',
        where_passed: 'Аноним',
        supermarket: 'Азимова'
      },

      {
        date: '16.04.2013',
        gathered_sum: '3 149 900',
        where_passed: 'Аноним',
        supermarket: 'Mercato'
      },

      {
        date: '15.03.2013',
        gathered_sum: '4 553 025',
        where_passed: 'Амели Джовидон. Операция в Японии',
        supermarket: 'Абай'
      },

      {
        date: '12.03.2013',
        gathered_sum: '6 278 725',
        where_passed:
          'Городской Онкологический диспансер. На лекарственные средства для детского отделения',
        supermarket: 'Аэропорт'
      },

      {
        date: '27.02.2013',
        gathered_sum: '5 052 500',
        where_passed: 'НИИ Гематологии и Переливания крови',
        supermarket: 'Караташ'
      },

      {
        date: '19.02.2013',
        gathered_sum: '5 630 400',
        where_passed: 'Махалля «Чоштепа»',
        supermarket: 'Туркменский'
      },

      {
        date: '08.02.2013',
        gathered_sum: '5 660 450',
        where_passed: 'Детский дом «Мурувват 2»',
        supermarket: 'Mercato'
      },

      {
        date: '31.01.2013',
        gathered_sum: '6 252 440',
        where_passed: 'Саидова Муштарийбону',
        supermarket: 'Сергели'
      }
    ],

    year: 'Итого за 2013 г.',
    sum: '130 071 910'
  },

  // 2012 year
  year_of_charity2012: {
    table: [
      {
        date: '19.11.2012',
        gathered_sum: '3 728 565',
        where_passed: 'Общество глухих',
        supermarket: 'Абай'
      },
      {
        date: '16.11.2012',
        gathered_sum: '3 248 550',
        where_passed: 'Детский дом «Мурувват 1»',
        supermarket: 'Караташ'
      },
      {
        date: '30.10.2012',
        gathered_sum: '5 194 235',
        where_passed: 'Театр «Лик»',
        supermarket: ''
      },
      {
        date: '02.10.2012',
        gathered_sum: '4 607 425',
        where_passed: 'Школа-интернат №98. Для малообеспеченных',
        supermarket: 'Аэропорт'
      },
      {
        date: '21.09.2012',
        gathered_sum: '5 297 100',
        where_passed: 'Детский дом №31',
        supermarket: 'Mercato'
      },
      {
        date: '10.08.2012',
        gathered_sum: '2 978 950',
        where_passed: 'Алмазарский филиал городского Общества инвалидов',
        supermarket: 'Абай'
      },
      {
        date: '26.07.2012',
        gathered_sum: '1 943 800',
        where_passed: 'Детский дом №30',
        supermarket: 'Караташ'
      },
      {
        date: '24.07.2012',
        gathered_sum: '3 373 040',
        where_passed: 'Бектемирское отделение общества инвалидов',
        supermarket: ''
      },
      {
        date: '24.05.2012',
        gathered_sum: '1 469 830',
        where_passed: 'Марин Алексей',
        supermarket: 'Караташ'
      },

      {
        date: '16.05.2012',
        gathered_sum: '3 276 330',
        where_passed: 'Детская Площадка Детского дома №14',
        supermarket: ''
      },

      {
        date: '15.05.2012',
        gathered_sum: '2 487 350',
        where_passed: 'Детская Площадка Детского дома №14',
        supermarket: 'Абай'
      },

      {
        date: '27.04.2012',
        gathered_sum: '5 662 105',
        where_passed: 'Детский дом №15 г.Бука',
        supermarket: 'Mercato'
      },

      {
        date: '19.04.2012',
        gathered_sum: '3 623 700',
        where_passed: '«Sanvikt»',
        supermarket: 'Аэропорт'
      },

      {
        date: '30.03.2012',
        gathered_sum: '2 474 330',
        where_passed:
          'Детское отделение Республиканского Онкологического научного центра',
        supermarket: 'Караташ'
      },

      {
        date: '16.03.2012',
        gathered_sum: '2 900 400',
        where_passed: 'Дом Ребенка «Кичкинтой» г.Янгиюль',
        supermarket: ''
      },

      {
        date: '02.02.2012',
        gathered_sum: '4 208 450',
        where_passed: 'Детский дом №14 г.Чирчик',
        supermarket: 'Абай'
      },

      {
        date: '20.01.2012',
        gathered_sum: '2 785 105',
        where_passed: 'Республиканская центральная библиотека для слепых',
        supermarket: ''
      }
    ],

    year: 'Итого за 2012 г.',
    sum: '59 259 265'
  },

  // 2011 year
  year_of_charity2011: {
    table: [
      {
        date: '23.12.2011',
        gathered_sum: '2 164 400',
        where_passed: 'Детское отделение Городского Онкологического Диспансера',
        supermarket: 'Караташ'
      },
      {
        date: '09.12.2011',
        gathered_sum: '2 565 445',
        where_passed: 'Об-во социал. Взаимопомощи инв-в «Мехрибонлик»',
        supermarket: 'Аэропорт'
      },
      {
        date: '25.11.2011',
        gathered_sum: '3 000 950',
        where_passed: 'Городское отделение Узбекского общества Инвалидов',
        supermarket: ''
      },
      {
        date: '04.11.2011',
        gathered_sum: '2 751 725',
        where_passed: 'Общество глухих',
        supermarket: 'Абай'
      },
      {
        date: '30.09.2011',
        gathered_sum: '2 348 455',
        where_passed: 'Интернат для слабослышащих №102',
        supermarket: 'Караташ'
      },
      {
        date: '16.09.2011',
        gathered_sum: '2 498 410',
        where_passed: '«Дом Малютки»',
        supermarket: ''
      },
      {
        date: '28.07.2011',
        gathered_sum: '1 790 170',
        where_passed: '«Sanvikt»',
        supermarket: 'Караташ'
      },
      {
        date: '27.07.2011',
        gathered_sum: '1 913 700',
        where_passed: 'Детский дом «Мурувват 1»',
        supermarket: 'Абай'
      },
      {
        date: '26.07.2011',
        gathered_sum: '2 853 845',
        where_passed: 'Детский дом «Мурувват 2»',
        supermarket: ''
      },

      {
        date: '10.06.2011',
        gathered_sum: '2 455 490',
        where_passed: 'Бектемирский филиал общества Инвалидов',
        supermarket: 'Аэропорт'
      },

      {
        date: '20.05.2011',
        gathered_sum: '1 816 255',
        where_passed: '«Умидварлик»',
        supermarket: ''
      },

      {
        date: '06.05.2011',
        gathered_sum: '2 281 350',
        where_passed: 'Вспомогательная школа №37',
        supermarket: 'Абай'
      },

      {
        date: '08.04.2011',
        gathered_sum: '2 714 760',
        where_passed: 'Театр «Лик»',
        supermarket: ''
      },

      {
        date: '04.03.2011',
        gathered_sum: '2 924 205',
        where_passed: 'Общество «SOS Деревня»',
        supermarket: ''
      },

      {
        date: '18.02.2011',
        gathered_sum: '2 751 515',
        where_passed: 'ОО «APLA»',
        supermarket: 'Аэропорт'
      },

      {
        date: '04.01.2011',
        gathered_sum: '2 101 040',
        where_passed: '«Sanvikt»',
        supermarket: ''
      }
    ],

    year: 'Итого за 2011 г.',
    sum: '	38 931 715'
  },

  // 2010 year
  year_of_charity2010: {
    table: [
      {
        date: '11.12.2010',
        gathered_sum: '2 322 360',
        where_passed: 'Дет.ПНБ.',
        supermarket: ''
      },
      {
        date: '08.10.2010',
        gathered_sum: '2 000 600',
        where_passed: '5 ГКДБ',
        supermarket: 'Аэропорт'
      },
      {
        date: '24.09.2010',
        gathered_sum: '2 401 600',
        where_passed: 'Детский дом №23',
        supermarket: ''
      },
      {
        date: '05.08.2010',
        gathered_sum: '1 627 730',
        where_passed: 'НИИ Гематологии',
        supermarket: ''
      },
      {
        date: '15.06.2010',
        gathered_sum: '1 918 010',
        where_passed: 'Детский дом №22',
        supermarket: ''
      },
      {
        date: '27.04.2010',
        gathered_sum: '1 919 430',
        where_passed: 'Детский дом «Мурувват 2»',
        supermarket: ''
      },
      {
        date: '22.02.2010',
        gathered_sum: '1 533 100',
        where_passed: 'Детский дом «Мурувват 1»',
        supermarket: ''
      }
    ],

    year: 'Итого за 2010 г.',
    sum: '13 722 830'
  },

  // 2009 year
  year_of_charity2009: {
    table: [
      {
        date: '18.12.2009',
        gathered_sum: '1 130 800',
        where_passed: 'Детский дом №30',
        supermarket: ''
      },
      {
        date: '23.10.2009',
        gathered_sum: '1 000 085',
        where_passed: 'Детский дом №31',
        supermarket: ''
      },
      {
        date: '20.05.2009',
        gathered_sum: '1 573 125',
        where_passed: 'Детский дом №21',
        supermarket: ''
      },
      {
        date: '23.01.2009',
        gathered_sum: '970 350',
        where_passed: 'Детский дом №24',
        supermarket: ''
      }
    ],

    year: 'Итого за 2009 г.',
    sum: '4 674 360'
  },

  // 2008 year
  year_of_charity2008: {
    table: [
      {
        date: '14.11.2008',
        gathered_sum: '773 335',
        where_passed: '«Дом Малютки»',
        supermarket: ''
      }
    ],

    year: 'Итого за 2008 г.',
    sum: '773 335'
  },
  faq_title: 'FAQ',
  faq_loyalty_title: 'How it works:',
  share: 'Share',
  // footer_disclamer: "Information on the website is provided for information only and is not legally binding.:<br /><br />— The cost of goods is indicated in soums.<br />— All offers apply only to the Korzinka supermarkets chain.<br />— The appearance of the advertised product may differ from that presented in the store.<br />— Prices for products in the store may be lower than indicated in promotional materials.<br />— The quantity of promotional products may be limited.<br />— The promotion is not cumulative with other offers for these products.<br />— Cashback is not credited to the Korzinka Plus loyalty card when paying with gift certificates, as well as accumulated bonuses.<br />— Prices and assortment of products may not match the prices and assortment of products in DISKONT Korzinka stores.<br />— All products are certified.",
  footer_disclamer: `
    <p>Information on the website is provided for information only and is not legally binding.</p>
    <br />
    <p>•&nbsp;&nbsp; The cost of goods is indicated in soums.</p>
    <p>•&nbsp;&nbsp; All offers apply only to the Korzinka supermarkets chain.</p>
    <p>•&nbsp;&nbsp; The appearance of the advertised product may differ from that presented in the store.</p>
    <p>•&nbsp;&nbsp; Prices for products in the store may be lower than indicated in promotional materials.</p>
    <p>•&nbsp;&nbsp; The quantity of promotional products may be limited.</p>
    <p>•&nbsp;&nbsp; The promotion is not cumulative with other offers for these products.</p>
    <p>•&nbsp;&nbsp; Cashback is not credited to the Korzinka Plus loyalty card when paying with gift certificates, as well as accumulated bonuses.</p>
    <p>•&nbsp;&nbsp; Prices and assortment of products may not match the prices and assortment of products in DISKONT Korzinka stores.</p>
    <p>•&nbsp;&nbsp; All products are certified.</p>
    <p>Other restrictions and rules may apply. Please contact our call centre for more information on</p>
  `,
  ctm_iz_korzinki_text: 'Premium Quality',
  ctm_365kun_text: 'Availability and quality',
  cashback_tag_label: 'cashback',
  novelty_tag_label: 'new',
  selfmade_tag_label: 'self made',
  discount_by_card: 'by card',
  coupon_checker_button_label: 'Check Participation',
  coupon_checker_input_label: 'Enter your loyalty card number',
  coupon_checker_schedules_title: 'You have',
  coupon_checker_number: 'Coupon number',
  chances_title: 'chance',
  chances_title_2: 'chances',
  chances_title_3: 'chances',
  chances_error:
    'Looks like you`re not participating in the promotion yet or the data hasn`t been updated yet. If more than 60 minutes have passed since the purchase, please contact us by phone <a href="tel:+998781401414">+998 (78) 140-14-14</a>',
  // spec-catalog footer content
  spec_catalog_footer: `
          <h2>Taking care of you: we offer the lowest prices for your daily purchases!</h2>
<p>&nbsp;</p>
<p>We at Korzinka always try to make the shopping process more affordable, comfortable, and enjoyable.&nbsp;</p>
<p>As a continuation of this initiative, we launched the &quot;Eng arzon narx&quot; program, where we offer our guests the lowest prices for a basket of goods for daily purchases. To do this, we regularly conduct price monitoring among supermarkets and bazaars in Tashkent and the Tashkent region:</p>
<p>&ndash;&nbsp;LLC Darvoza Savdo (Makro)</p>
<p>&ndash;&nbsp;JV LLC Havas Food (Havas)</p>
<p>&ndash;&nbsp;JV LLC &quot;Urban Retail&quot; (Baraka)</p>
<p>&ndash;&nbsp;Individual Entrepreneur LLC &quot;Magnum Retail&quot; (Magnum)</p>
<p>&ndash;&nbsp;LLC &quot;NEW RETAIL&quot; (bi1)</p>
<p>&ndash;&nbsp;Individual Entrepreneur LLC &quot;Magnit Central Asia&quot; (M COSMETIC)</p>
<p>&ndash;&nbsp;LLC &quot;Xalq retail&quot; (Olma)</p>
<p>&ndash;&nbsp;Farhad market&nbsp;</p>
<p>&ndash;&nbsp;Yunusabad dekhkan market</p>
<p>&ndash;&nbsp;Chorsu Market</p>
<p>&nbsp;</p>
<p>To compare prices, we took products identical in brand, model, grade, caliber, size, quality, weight, and color presented in Korzinka. We monitor their prices weekly, and each of the products participating in the program is marked with advertising material and a price tag &ldquo;Eng arzon narx&rdquo; (&ldquo;The lowest price&rdquo;).&nbsp; The comparison is based on the total price of the basket.&nbsp; Products with discounts and promotions are not taken into account during monitoring.</p>
<p>The &ldquo;Eng arzon narx&rdquo; program is valid in all supermarkets of the chain, including the Korzinka, Korzinka Mahalla, Korzinka Diskont, Smart formats, and the Korzinka Go service.&nbsp; Special prices do not apply to goods purchased outside of Tashkent and the Tashkent region. We are working to launch the promotion throughout the Republic of Uzbekistan soon.</p>
<p>Promotion period: until December 31, 2024, with the possibility of extension.</p>
<p>This promotion is at the sole discretion of Anglesey Food LLC and is subject to change at any time without notice.&nbsp; Korzinka reserves the right to refuse price adjustments that cannot be verified or are outside the scope of our policy. If you have any additional questions, please contact the cashier, or store manager, or call our customer service specialists by phone: +998 (78) 140-14-14.</p>
<p>&nbsp;* The cost of goods in the catalog is indicated in soums.</p>
<p>&nbsp;* The cost of some products may be lower than indicated in the catalog.&nbsp;</p>
<p>&nbsp;* The list of products participating in the &ldquo;Eng arzon narx&rdquo; promotion is subject to change at the discretion of OOO Anglesey Food at any time without prior notice.</p>
<p>&nbsp;* Bonuses under the Korzinka Plus loyalty program are not awarded for &ldquo;Eng arzon narx&rdquo; products.</p>
`,
  // sales
  sales_text: `Offers <br/> for you`
}
