import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6ffea2ee = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _4a119cee = () => interopDefault(import('../pages/business-ethics/index.vue' /* webpackChunkName: "pages/business-ethics/index" */))
const _3f2896fa = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _dcca3c8e = () => interopDefault(import('../pages/charity/index.vue' /* webpackChunkName: "pages/charity/index" */))
const _eea8bf6e = () => interopDefault(import('../pages/coupons-disable/index.vue' /* webpackChunkName: "pages/coupons-disable/index" */))
const _58f8c43d = () => interopDefault(import('../pages/ctm/index.vue' /* webpackChunkName: "pages/ctm/index" */))
const _21266370 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _74be761a = () => interopDefault(import('../pages/feedback/index.vue' /* webpackChunkName: "pages/feedback/index" */))
const _0485a29e = () => interopDefault(import('../pages/grievancepolicy/index.vue' /* webpackChunkName: "pages/grievancepolicy/index" */))
const _91de759e = () => interopDefault(import('../pages/logotip/index.vue' /* webpackChunkName: "pages/logotip/index" */))
const _6a1d3232 = () => interopDefault(import('../pages/loyalty/index.vue' /* webpackChunkName: "pages/loyalty/index" */))
const _86a0c456 = () => interopDefault(import('../pages/loyalty-infokiosk/index.vue' /* webpackChunkName: "pages/loyalty-infokiosk/index" */))
const _92e97d12 = () => interopDefault(import('../pages/mobile/index.vue' /* webpackChunkName: "pages/mobile/index" */))
const _4bc88e30 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _442f15a4 = () => interopDefault(import('../pages/page/index.vue' /* webpackChunkName: "pages/page/index" */))
const _4219fe3e = () => interopDefault(import('../pages/pravila-programmy-loyalnosti/index.vue' /* webpackChunkName: "pages/pravila-programmy-loyalnosti/index" */))
const _74c46bac = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _bce99f4c = () => interopDefault(import('../pages/privacy-policy-infokiosk/index.vue' /* webpackChunkName: "pages/privacy-policy-infokiosk/index" */))
const _62232f19 = () => interopDefault(import('../pages/publichnaya-oferta/index.vue' /* webpackChunkName: "pages/publichnaya-oferta/index" */))
const _03bf4d94 = () => interopDefault(import('../pages/qr/index.vue' /* webpackChunkName: "pages/qr/index" */))
const _ea777ef2 = () => interopDefault(import('../pages/stores/index.vue' /* webpackChunkName: "pages/stores/index" */))
const _3d6d3924 = () => interopDefault(import('../pages/catalog/special/index.vue' /* webpackChunkName: "pages/catalog/special/index" */))
const _8b0b4bbe = () => interopDefault(import('../pages/catalog/static/_id.vue' /* webpackChunkName: "pages/catalog/static/_id" */))
const _00040fe2 = () => interopDefault(import('../pages/catalog/_id.vue' /* webpackChunkName: "pages/catalog/_id" */))
const _4f35d0c0 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _4278745c = () => interopDefault(import('../pages/page/_slug.vue' /* webpackChunkName: "pages/page/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6ffea2ee,
    name: "about___ru"
  }, {
    path: "/business-ethics",
    component: _4a119cee,
    name: "business-ethics___ru"
  }, {
    path: "/catalog",
    component: _3f2896fa,
    name: "catalog___ru"
  }, {
    path: "/charity",
    component: _dcca3c8e,
    name: "charity___ru"
  }, {
    path: "/coupons-disable",
    component: _eea8bf6e,
    name: "coupons-disable___ru"
  }, {
    path: "/ctm",
    component: _58f8c43d,
    name: "ctm___ru"
  }, {
    path: "/en",
    component: _21266370,
    name: "index___en"
  }, {
    path: "/feedback",
    component: _74be761a,
    name: "feedback___ru"
  }, {
    path: "/grievancepolicy",
    component: _0485a29e,
    name: "grievancepolicy___ru"
  }, {
    path: "/logotip",
    component: _91de759e,
    name: "logotip___ru"
  }, {
    path: "/loyalty",
    component: _6a1d3232,
    name: "loyalty___ru"
  }, {
    path: "/loyalty-infokiosk",
    component: _86a0c456,
    name: "loyalty-infokiosk___ru"
  }, {
    path: "/mobile",
    component: _92e97d12,
    name: "mobile___ru"
  }, {
    path: "/news",
    component: _4bc88e30,
    name: "news___ru"
  }, {
    path: "/page",
    component: _442f15a4,
    name: "page___ru"
  }, {
    path: "/pravila-programmy-loyalnosti",
    component: _4219fe3e,
    name: "pravila-programmy-loyalnosti___ru"
  }, {
    path: "/privacy-policy",
    component: _74c46bac,
    name: "privacy-policy___ru"
  }, {
    path: "/privacy-policy-infokiosk",
    component: _bce99f4c,
    name: "privacy-policy-infokiosk___ru"
  }, {
    path: "/publichnaya-oferta",
    component: _62232f19,
    name: "publichnaya-oferta___ru"
  }, {
    path: "/qr",
    component: _03bf4d94,
    name: "qr___ru"
  }, {
    path: "/stores",
    component: _ea777ef2,
    name: "stores___ru"
  }, {
    path: "/uz",
    component: _21266370,
    name: "index___uz"
  }, {
    path: "/catalog/special",
    component: _3d6d3924,
    name: "catalog-special___ru"
  }, {
    path: "/en/about",
    component: _6ffea2ee,
    name: "about___en"
  }, {
    path: "/en/business-ethics",
    component: _4a119cee,
    name: "business-ethics___en"
  }, {
    path: "/en/catalog",
    component: _3f2896fa,
    name: "catalog___en"
  }, {
    path: "/en/charity",
    component: _dcca3c8e,
    name: "charity___en"
  }, {
    path: "/en/coupons-disable",
    component: _eea8bf6e,
    name: "coupons-disable___en"
  }, {
    path: "/en/ctm",
    component: _58f8c43d,
    name: "ctm___en"
  }, {
    path: "/en/feedback",
    component: _74be761a,
    name: "feedback___en"
  }, {
    path: "/en/grievancepolicy",
    component: _0485a29e,
    name: "grievancepolicy___en"
  }, {
    path: "/en/logotip",
    component: _91de759e,
    name: "logotip___en"
  }, {
    path: "/en/loyalty",
    component: _6a1d3232,
    name: "loyalty___en"
  }, {
    path: "/en/loyalty-infokiosk",
    component: _86a0c456,
    name: "loyalty-infokiosk___en"
  }, {
    path: "/en/mobile",
    component: _92e97d12,
    name: "mobile___en"
  }, {
    path: "/en/news",
    component: _4bc88e30,
    name: "news___en"
  }, {
    path: "/en/page",
    component: _442f15a4,
    name: "page___en"
  }, {
    path: "/en/pravila-programmy-loyalnosti",
    component: _4219fe3e,
    name: "pravila-programmy-loyalnosti___en"
  }, {
    path: "/en/privacy-policy",
    component: _74c46bac,
    name: "privacy-policy___en"
  }, {
    path: "/en/privacy-policy-infokiosk",
    component: _bce99f4c,
    name: "privacy-policy-infokiosk___en"
  }, {
    path: "/en/publichnaya-oferta",
    component: _62232f19,
    name: "publichnaya-oferta___en"
  }, {
    path: "/en/qr",
    component: _03bf4d94,
    name: "qr___en"
  }, {
    path: "/en/stores",
    component: _ea777ef2,
    name: "stores___en"
  }, {
    path: "/uz/about",
    component: _6ffea2ee,
    name: "about___uz"
  }, {
    path: "/uz/business-ethics",
    component: _4a119cee,
    name: "business-ethics___uz"
  }, {
    path: "/uz/catalog",
    component: _3f2896fa,
    name: "catalog___uz"
  }, {
    path: "/uz/charity",
    component: _dcca3c8e,
    name: "charity___uz"
  }, {
    path: "/uz/coupons-disable",
    component: _eea8bf6e,
    name: "coupons-disable___uz"
  }, {
    path: "/uz/ctm",
    component: _58f8c43d,
    name: "ctm___uz"
  }, {
    path: "/uz/feedback",
    component: _74be761a,
    name: "feedback___uz"
  }, {
    path: "/uz/grievancepolicy",
    component: _0485a29e,
    name: "grievancepolicy___uz"
  }, {
    path: "/uz/logotip",
    component: _91de759e,
    name: "logotip___uz"
  }, {
    path: "/uz/loyalty",
    component: _6a1d3232,
    name: "loyalty___uz"
  }, {
    path: "/uz/loyalty-infokiosk",
    component: _86a0c456,
    name: "loyalty-infokiosk___uz"
  }, {
    path: "/uz/mobile",
    component: _92e97d12,
    name: "mobile___uz"
  }, {
    path: "/uz/news",
    component: _4bc88e30,
    name: "news___uz"
  }, {
    path: "/uz/page",
    component: _442f15a4,
    name: "page___uz"
  }, {
    path: "/uz/pravila-programmy-loyalnosti",
    component: _4219fe3e,
    name: "pravila-programmy-loyalnosti___uz"
  }, {
    path: "/uz/privacy-policy",
    component: _74c46bac,
    name: "privacy-policy___uz"
  }, {
    path: "/uz/privacy-policy-infokiosk",
    component: _bce99f4c,
    name: "privacy-policy-infokiosk___uz"
  }, {
    path: "/uz/publichnaya-oferta",
    component: _62232f19,
    name: "publichnaya-oferta___uz"
  }, {
    path: "/uz/qr",
    component: _03bf4d94,
    name: "qr___uz"
  }, {
    path: "/uz/stores",
    component: _ea777ef2,
    name: "stores___uz"
  }, {
    path: "/en/catalog/special",
    component: _3d6d3924,
    name: "catalog-special___en"
  }, {
    path: "/uz/catalog/special",
    component: _3d6d3924,
    name: "catalog-special___uz"
  }, {
    path: "/",
    component: _21266370,
    name: "index___ru"
  }, {
    path: "/en/catalog/static/:id?",
    component: _8b0b4bbe,
    name: "catalog-static-id___en"
  }, {
    path: "/uz/catalog/static/:id?",
    component: _8b0b4bbe,
    name: "catalog-static-id___uz"
  }, {
    path: "/catalog/static/:id?",
    component: _8b0b4bbe,
    name: "catalog-static-id___ru"
  }, {
    path: "/en/catalog/:id",
    component: _00040fe2,
    name: "catalog-id___en"
  }, {
    path: "/en/news/:slug",
    component: _4f35d0c0,
    name: "news-slug___en"
  }, {
    path: "/en/page/:slug",
    component: _4278745c,
    name: "page-slug___en"
  }, {
    path: "/uz/catalog/:id",
    component: _00040fe2,
    name: "catalog-id___uz"
  }, {
    path: "/uz/news/:slug",
    component: _4f35d0c0,
    name: "news-slug___uz"
  }, {
    path: "/uz/page/:slug",
    component: _4278745c,
    name: "page-slug___uz"
  }, {
    path: "/catalog/:id",
    component: _00040fe2,
    name: "catalog-id___ru"
  }, {
    path: "/news/:slug",
    component: _4f35d0c0,
    name: "news-slug___ru"
  }, {
    path: "/page/:slug",
    component: _4278745c,
    name: "page-slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
