export default {
  special_catalog_disclaimer: `
    *Tovar miqdori cheklangan<br />
    * Mol go‘shtiga chegirmalar Korzinka DISKONT supermarketlaridan tashqari barcha Korzinka supermarketlar tarmog‘i bo‘ylab amal qiladi
  `,
  special_catalog_disclaimer2: `
    * 2 ta bir xil muzqaymoq xarid qiling, xuddi shunday uchinchisi sovg‘aga<br />
    * Aksiya 22-iyundan 23-iyun oxirigacha davom etadi
  `,
  hard_title: `Hafta super narxlari`,
  read_more: 'Batafsil o`qish',
  byd_banner_title: 'BYD Song Plus <br /> yutib oling',
  byd_banner_description:
    '200 000 soʻmga xarid qiling <br /> va Korzinka kartangizdan foydalaning',
  byd_banner_link:
    'https://korzinka.uz/uz/news/publichnaya-oferta-pravila-provedeniya-reklamnoy-aktsii-vyigrayte-byd-song-plus-za-pokupku-v-korzink?id=1857',
  ibt: 'IBT sohasidagi siyosat',
  ibt_link: '/ibt_uz.pdf',
  rent: 'Ijaraga beruvchilarga',
  up_to: 'gacha',
  location_empty: `Kiritilgan ma'lumotlar to'g'riligini tekshiring va urinishni takrorlab ko'ring`,
  // Discle
  discle: `
    <ul>
      <li>* Katalogdagi tovarlar narxi so‘mda ko‘rsatilgan</li>
      <li>* Katalogdagi takliflar faqatgina Korzinka supermarketlar tarmog‘ida amal qiladi</li>
      <li>* Tovarlar narxi va assortimenti Korzinka DISKONT, Korzinka Mahalla va Smart do‘konlaridagi assortimentdan farq qilishi mumkin.</li>
      <li>* Viloyat hududlaridagi supermarketlarimizda aksiya mahalliy yetkazuvchilar tovuq tuxumlari uchun ham o‘z kuchida.</li>
      <li>* Aksiya doirasidagi tovarlar soni cheklangan</li>
      <li>* Ba'zi tovarlar narxi katalogda ko‘rsatilganidan arzonroq bo‘lishi mumkin</li>
    </ul>
  `,
  // Permanent promotion
  permanent_promotion: {
    block_heading: 'Doimiy aksiya',
    block_description_1: 'Narxlar nomutanosibligi',
    block_description_2: '«Yaroqlik muddati o’tgan mahsulot» aksiyasi'
  },
  billione_short: 'mlrd.',
  // Staff
  staff: {
    title: 'Biz – bir oilamiz',
    tab_1: 'Barcha',
    tab_2: 'Kuzatuv kengashi',
    tab_3: 'Korzinka bosh rahbariyati'
  },
  // Search tools help products
  search_tools_help: {
    title: 'Korzinkadan nima xarid qilamiz?',
    products: [
      {
        product: 'Mol goʻshti'
      },
      {
        product: 'Banan'
      },
      {
        product: 'Pomidor'
      },
      {
        product: 'Tovuq goʻshti'
      },
      {
        product: 'Kungaboqar yogʻi'
      },
      {
        product: 'Ichimliklar'
      },
      {
        product: 'Olma'
      },
      {
        product: 'Bolalar ozuqasi'
      },
      {
        product: 'Tuxum'
      },
      {
        product: 'Qoʻy goʻshti'
      }
    ]
  },
  // Cookie
  cookie: {
    text: 'Saytdan foydalanish qulay kechishi uchun cookie-fayllardan foydalanamiz',
    done: 'Ajoyib'
  },

  main: 'Bosh sahifa',
  charity: 'Xayriya',
  loading: 'Eng mazalisini yuklayapmiz...',
  //  Header menu
  catalog: 'Katalog',
  loyalty: 'Karta dasturi',
  career: 'Karyera',
  about: 'Biz haqimizda',
  stores: "Do'konlar",
  suppliers: "Ta'minotchilarga",
  news: 'Yangiliklar',
  use_brand: 'Materiallardan foydalanish',
  feedback: 'Fikr-mulohazalar',
  still: 'Yana',
  best_offer: 'Eng yaxshi takliflar',
  week_offer: `Bu hafta super narxlari`,
  all_products: 'Barcha tovarlar',
  top10Products: 'TOP-10 takliflar',
  // Header menu

  back: 'Orqaga',

  back_to_catalog: 'Katalogga',

  read_also: 'Shuningdek o‘qing',

  // Main page
  // Main_page news
  all_news: 'Barcha yangiliklar',
  // Main_page news

  // Main_page work section
  work: {
    info: 'Korzinka supermarketlar tarmog‘i – istiqbolli korxona',
    title: 'Keling, <br /> birga ishlaymiz',
    detail: 'Batafsil'
  },
  // Main_page work section

  // Main-page banners
  // Main-page banners

  // Main page and Loyality page - mobile application section
  mobile: {
    title_1: 'Bari ',
    title_2: 'kaftingizda!',
    description_1: 'Ilovada yangi kartani',
    description_2: 'ro‘yxatdan o‘tkazing va 5000',
    description_3: 'salom-bonuslarga ega bo‘ling'
  },
  // Main page and Loyality page - mobile application section
  // Main page

  //  Loyality page
  // "How to get the points" section
  get_points: {
    title_1: 'O‘z Korzinka kartangizni ko‘rsating',
    description_1: 'Korzinkadan <br>xaridlar <br> amalga <br> oshiring',
    title_2: 'Bonuslar oling',
    description_2: 'Har bir xarid <br>uchun 1%',
    title_3: 'Barcha xaridlar sarhisobi',
    description_3: 'Oyiga <br>1.3 mln dan <br>boshlab sarflashda ustidan 1%',
    title_4: 'Barcha xaridlar sarhisobi',
    description_4:
      'Chorakga<br>4.5 mln dan  <br> boshlab sarflashda ustidan 1%',
    title_5: 'Mobil ilova orqali to‘lov amalga oshirilganda',
    description_5: 'barcha xaridlar uchun <br> 0,5% qo‘shiladi',
    title_6: 'Bonuslar 12 oy saqlanadi',
    description_6: 'Bonuslardan xaridlar <br> uchun foydalaning'
  },
  // "How to get the points" - section

  // "Earn points faster" - section
  earn_points: {
    title: 'Bonuslarni tezroq to‘plang',
    description:
      'Oilangiz bilan Korzinkaga keling! Biz sizga yagona hisobli 4 kartani sovg‘a qilamiz, u bilan bonuslarni odatdagidan tezroq jamlaysiz.'
  },
  // "Earn points faster" - section

  loyalty_card_title: 'Karta kaftingizda',
  loyalty_card_description: 'Ilovani o‘rnatganingiz uchun 5000 bonus bor',
  loyalty_get_card_title: 'Qanday qilib kartani <br /> qo‘lga kiritsa bo‘ladi',
  about_app: 'Ilova haqida',
  download_app: 'Ilovani yuklash',

  cashback: {
    get_ball: 'Ballarni qanday olish mumkin'
  },

  // Questions and answers section
  // Questions and answers section
  // Loyality page

  // About us - page
  about_us: {
    history_title:
      '28 yillik tajriba va ishonch bizni bozor yetakchisiga aylantirdi',
    comments_title: 'Bizga yo‘llagan barcha iliq so‘zlaringizni unutmaymiz',
    shops_number_desc:
      '<span> Do‘konlar O‘zbekistonda </span> <br /> Qanday talqindagi do‘kon sizga mukammal mos tushishini bilib oling',
    charity_description: `
          2008-yildan buyon Korzinka supermarketlarida Xayriya qutilari o‘rnatila boshlandi. Ko‘ngillilar istalgan, xatto unchalik katta bo‘lmagan miqdordagi pullarni xayriya ishlariga yo‘naltirishlari mumkin.
            `,
    charity_button: 'Batafsil bilib olish',
    currency: 'so‘m'
  },
  // About us - page

  //  Catalogs
  spec_offer: 'Maxsus takliflar',
  buy: 'Sotib olish',
  lebazar: 'Lebazarga',
  korzinka: 'Korzinka Goga',
  //  Catalogs

  // Shops
  shops: {
    search_input: 'Manzil yoki nomni kiriting',
    search_settings: 'Qidiruv sozlamalari',
    shops_category: 'Do‘kon toifasi',
    korzinka_descr: 'supermarketlar tarmog‘i',
    smart_descr: 'uy yonidagi do‘konlar',
    redtag_descr: 'Butun oila uchun Dubay kiyim brendi',
    flo_descr: 'turk poyabzal do‘konlar tarmog‘i',
    services_title: 'Xizmatlar',
    regions_title: 'Shahar va viloyatlar',

    address: 'Manzil',
    orient: 'Mo‘ljal',
    work_time: 'Ish tartibi',
    phone: 'Telefon',

    parking: 'Avto turargoh'
  },
  // Shops

  // News
  page_news: {
    title: 'Korzinkada nima gap?',
    more: 'Yana yangiliklar'
  },
  // News

  // Materials guide page
  brand_use: {
    intro_title: 'Logotipimizdan foydalanish bo‘yicha qo‘llanma',
    intro_description: `
      Ushbu sahifa orqali siz Korzinka supermarketlar tarmog‘i logotiplarini o‘zingizga qulay formatda yuklab olishingiz mumkin.<br ><br />

      Savdo belgilaridan faqatgina "Anglesey Food" MCHJ XK Marketing bo‘limi yozma ruxsatnomasi mavjud bo‘lgan taqdirdagina foydalanishingiz mumkin. Savdo belgisidan foydalanish ruxsatnomasini olish uchun biz bilan elektron pochta orqali bog‘laning: info@korzinka.uz.
      Savdo belgilari O‘zbekiston Respublikasi qonunchiligi bilan himoyalanadi.
    `,
    logo_block_title: 'Logo bloki',
    logo_block_descr:
      'Bu logomark va vordmarkdan tarkib topgan kompozitsion qism hisoblanadi. Bizda ikki gorizontal va bir vertikal logoblok mavjud.',
    logo_block_descr_sup1: 'Asosiy gorizontal logo bloki',
    logo_block_descr_sup2: 'Qo‘shimcha gorizontal logo bloki',
    logo_block_descr_sup3: 'Vertikal logo bloki',

    logo_mark_title: 'Logomark',
    logo_mark_descr:
      'korzinka logomark/belgisi ikki talqinda mavjud: ovalsiz (asosiy) va ovalli (qo‘shimcha). Logoblokdan foydalanish imkonsiz bo‘lgan taqdirda ishlatiladi.',
    logo_mark_descr_sup1: 'Asosiy logomark',
    logo_mark_descr_sup2:
      'Logomarkning himoyalangan qismi h-belgi bo‘yining yarmiga teng',
    logo_mark_descr_sup3:
      ' Yorqin rangli fonda asosiy logomark <br> doim qizil rangda ishlatiladi',
    logo_mark_descr_sup4: 'Qo‘shimcha logomark',
    logo_mark_descr_sup5:
      'Logomarkning himoyalangan qismi h-belgi bo‘yining yarmiga teng',
    logo_mark_descr_sup6:
      'Yorqin rangli fonda qo‘shimcha logomark doim qizil rangda ishlatiladi',
    logo_mark_descr_sup7:
      'Yorqin rangli fonda vordmark/logomark doim qizil rangda ishlatiladi',
    logo_mark_descr_sup8:
      'Qora fonda vordmark/logomark doim oq rangda ishlatiladi',
    logo_mark_descr_sup9: 'Qizil fonda asosiy <br /> logomark doim oq rangda',

    vektor: 'vektor',
    download: 'yuklash',
    guideline_download: 'qo‘llanmani yuklash'
  },
  // Materials guide page

  //  Feedback page strings
  feedback_page: {
    title: 'Siz uchun odatdagidan yaxshi bo‘lib boryapmiz',
    question: 'Savolingiz bormi? O‘zingizga qulay usulda bizga aloqaga chiqing',
    use_brand: 'Logotiplardan foydalanish to‘g‘risida yo‘riqnoma ',
    here: 'bu yerda',

    address_title: 'manzil ',
    address: 'Toshkent shahri, Yakkasaroy tumani, Kichik halqa yo‘li, 120-uy',
    index: 'indeks: 100015',

    phone_title: 'telefon',
    requisites_title: 'Rekvizitlar',
    requisites: `
        "ANGLESEY FOOD" MChJ XK<br>
        Yuridik manzil: 100066, Toshkent shahri, Turob To‘la ko‘chasi, 57-uy, Beshyog‘och bozori hududi<br><br>
        Markaziy idora: 100015, Toshkent shahri, Kichik halqa yo‘li ko‘chasi, 120-uy<br><br>
        Hisob raqami: 2020 8000 6005 7890 2001<br>
        O‘zbekiston Respublikasi Tashqi iqtisodiy faoliyat Milliy banki "Akadеmiya" bank xizmatlari markazi <br>
        MFO: 00450 <br>
        IFUT: 47110<br>
        QQS to‘lovchi kodi: 320060002860 `,

    feedback_title: 'Yoki bizga barini boricha bu yerda so‘zlab bering ',
    request_type: 'Murojaat turi*',
    request_type1: 'Savol',
    request_type2: 'Shikoyat',
    request_type3: 'Taklif',
    request_type4: 'Minnatdorlik',
    request_type5: 'Boshqa',

    project_type: 'Brend*',
    project_type1: 'Вопрос не связан с маркетом',
    project_type2: 'Korzinka',
    project_type3: 'REDTAG',
    project_type4: 'Smart',
    project_type5: 'FLO',

    name_request: 'Ismingiz*',
    phone_request: 'Bog‘lanish raqami*',
    email_request: 'email',
    message_request: 'Sizning fikringiz*',
    message_request1: 'Izohlarni qo‘shing',
    sybmols: ' belgi',
    attachment_request: 'Surat biriktirish',
    attachment_request1: 'Fayl biriktirish*',
    send_request: 'Yuborish'
  },
  //  Feedbackpage strings

  // footer
  hotline: 'Call - markaz',
  privacy_policy: 'Maxfiylik siyosati',

  design: 'Dizayn — ',
  slogan: 'Odatdagidan yaxshi',
  // footer

  charity_title: 'Xayriya qutilaridigi mablag‘lar borasida ma’lumot',

  charity_obj: {
    date: 'Sana',
    gathered_sum: 'Yig‘ildi, so‘m',
    where_passed: 'Qayerga berildi',
    supermarket: 'Supermarket'
  },

  // search tools
  search: 'Qidiruv',
  search_placeholder: 'Korzinkadan toping...',

  search_tabs: {
    products: 'Tovarlar',
    top10: 'TOP-10',
    specOffer: 'Maxsus takliflar',
    news: 'Yangiliklar',
    stores: 'Do‘konlar'
  },

  empty_result_title: 'So‘raganingizni topa olmadik, uzr',
  empty_result_description:
    'Lekin quyida siz uchun eng yaxshi takliflarimizni jamlaganmiz',
  in_catalog: 'Katalogga',

  error_title: 'Sahifa topilmadi',
  error_text: 'Biroq bizning sizga boshqa takliflarimiz bor',

  telegram_link: 'https://t.me/korzinkauzb',

  kodeks: 'Biznes etikasi kodeksi',
  kodeks_title: '“ANGLESEY FOOD” MCHJ XK da Biznes etikasi kodeksi',
  kodeks_description: `
  <div class="postPage">
  <div class="postContent">

    <p>Mundarija </p>
    <ul>
      <li>
        <b>1.</b>
				Kodeksning maqsad va vazifalari
      </li>
      <li>
        <b>2.</b>
        Qo'llanilish doirasi
      </li>
      <li>
        <b>3.</b>
        Kompaniyaning Missiyasi va Qarashlari
      </li>
      <li>
        <b>4.</b>
        Korporativ qadriyatlar
      </li>
      <li>
        <b>5.</b>
        Asosiy axloqiy tamoyillar
      </li>
      <li>
        <b>6.</b>
        Ishbilarmonlik odobi
      </li>
      <li>
        <b>7.</b>
        Axborotning maxfiyligi
      </li>
      <li>
        <b>8.</b>
        Saqlash
      </li>
    </ul>
		<br/><br/>
		<p>1-modda</p>
		<br/>
		<p>Kodeksning maqsad va vazifalari</p>
		<br/>
    <ul>
      <li>
				“Anglesey Food” MChJ XK (keyingi o‘rinlarda “Kompaniya” deb yuritiladi) barcha manfaatdor tomonlar: xodimlar, mijozlar, hamkorlar, davlat idoralari bilan halol, ishonchli va adolatli munosabatlarga intiladi. Biznes etikasi kodeksi (keyingi o‘rinlarda “Kodeks” deb yuritiladi) korporativ madaniyatni qo‘llab-quvvatlovchi Kompaniyaning axloqiy tamoyillari va qadriyatlarini belgilaydi, bu esa bunday munosabatlarni o‘rnatishga yordam beradi.
				<br/>
				<br/>
				Kodeks xodimlarga aniq qoidalar yoki to‘g‘ridan-to‘g‘ri nazorat mavjud bo‘lmagan qiyin vaziyatlarda kompaniyaning qadriyatlari va axloqiy tamoyillariga muvofiq to‘g‘ri qarorlar qabul qilishga yordam berishi kerak va Kodeks qoidalari buzilgan taqdirda xodimning harakatlari tartibini tavsiflaydi.
				<br/>
				<br/>
				Agar Kompaniya xodimida nima qilish kerakligi haqida hali ham savollar bo‘lsa va javobni ushbu hujjatdan topolmasa, u tushuntirish va yordam olish uchun Etika bo‘yicha komissiyasi a’zolariga murojaat qilishi mumkin.
      </li>
		</ul>
		<br/><br/>
		<p>2- modda</p>
		<br/>
		<p>Qo‘llanilish doirasi </p>
		<br/>
    <ul>
      <li>
				Ushbu Kodeksda bayon etilgan normalar va tamoyillar, lavozim darajasidan, bo‘linmaning hududiy joylashuvidan qat’i nazar, Kompaniyaning barcha xodimlari uchun majburiydir.
				<br/><br/>
				Xodimlar Kompaniyaning qadriyatlari va axloqiy tamoyillariga zid bo‘lgan qarorlar qabul qilish yoki biror harakatlarni amalga oshirish huquqiga ega emas.
				<br/><br/>
				Kompaniyaning barcha xodimlari ushbu Kodeks talablari bilan shaxsan tanishishlari, ularga rioya qilishlari va buni Kodeks talablari bilan tanishish va rozilik bildirish to‘g‘risidagi Deklaratsiyani imzolash orqali tasdiqlashlari kerak (5-ilova).
				<br/><br/>
				Barcha bo‘linmalarning rahbarlari xodimlar tomonidan ushbu Kodeks talablariga rioya etilishini nazorat qilishlari shart.
				<br/><br/>
				Har bir xodim ushbu Kodeksda belgilangan talablarga rioya qilmaslik intizomiy, shu jumladan ishdan bo‘shatishgacha bo‘lgan jazoga olib kelishini tushunishi kerak.
				<br/><br/>
				Kompaniya kodeksida yoki O‘zbekiston Respublikasi qonunchiligida nazarda tutilgan nomaqbul xatti-harakatlar to‘g‘risida xabarnoma, shikoyat berish va ularga Kompaniya tomonidan javob berish tartibi Qonunbuzarliklar haqida xabar berish Siyosatida bayon etilgan.
      </li>
		</ul>
		<br/><br/>
		<p>3- modda</p>
		<br/>
		<p>Kompaniyaning Missiyasi va Qarashlari</p>
		<br/>
    <ul>
      <li>
				<b>Kompaniyaning Missiyasi: </b><br/>
				Biz eng yaxshi milliy an’analarni saqlab qolgan holda, zamonaviy chakana savdo madaniyatini olib boramiz.
				<br/><br/>
				<b>Kompaniyaning qarashlari:</b><br/>
				O‘zbekistondagi zamonaviy chakana savdoning yetakchisi, ishonchli hamkori, novatori va kashshofi. Biz mijozlarimiz, hamkorlarimiz va xodimlarimizni boshqalarga qaraganda yaxshiroq tushunamiz. Bizda qulay joylashuvlar, zamonaviy texnologiyalar, sifatli tovarlar, xizmat ko‘rsatish va do‘stona muhit mavjud.
      </li>
		</ul>
		<br/><br/>
		<p>4-modda</p>
		<br/>
		<p>Korporativ qadriyatlar</p>
		<br/>
    <ul>
      <li>
				<b>Mijozlarga yo‘naltirilganlik</b><br/>
				Mijozni chuqur tushunish va uning ehtiyojlarini samarali qondirish — doimo bizning ustuvor vazifalarimizdandir.
				<br/><br/>
				<b>Shaffoflik va o‘zaro hurmat</b><br/>
				Biz mijozlarimiz, hamkorlarimiz va xodimlarimizga nisbatan ochiq, halol va hurmat bilan harakat qilamiz.
				<br/><br/>
				<b>Jalb etilganlik</b><br/>
				Biz xodimlarimizdan mas’uliyatli, tashabbuskor bo‘lishlarini, kompaniya manfaati uchun qaror qabul qilishda egalik hissi va mustaqillikni namoyon etishlarini kutamiz.
				<br/><br/>
				<b>Innovatsionlik</b><br/>
				Biz doimo rivojlanib, o‘z ustimizda ishlayapmiz, yangi texnologiyalarni joriy qilmoqdamiz, biznes jarayonlarini takomillashtiramiz va kompaniya resurslaridan oqilona foydalanamiz.
				<br/><br/>
				<b>Har jabhada mukammallik</b><br/>
				Biz doimiy ravishda takomillashib boramiz va mijozlarimiz, hamkorlarimiz va aksiyadorlarimizning kutganlaridan ortig‘iga erishamiz.
				<br/>
				<i>* Ushbu Kodeks kontekstida “Biz” so‘zi Kompaniyaning barcha xodimlarini anglatadi..</i>
      </li>
		</ul>
		<br/><br/>
		<p>5-modda</p>
		<br/>
		<p>Asosiy axloqiy tamoyillar</p>
		<br/>
    <ul>
      <li>
				<b>Halollik va ishonch</b><br/>
				Barcha xodimlar chakana savdo sohasida halol va shaffof kompaniya sifatida o‘zini namoyon qilgan Kompaniya obro‘sini himoya qilishga majburdirlar. Kompaniya xodimlaridan barcha biznes munosabatlarida professional, adolatli va halol harakat qilishlari talab qilinadi.
				<br/><br/>
				Biz raqobatchilarimizni hurmat qilamiz. Biz doimiy ravishda mavjud va potensial raqobatchilarning faoliyatini tahlil qilamiz, ularning kuchli va zaif tomonlarini aniqlaymiz, lekin har doim halol raqobat qoidalariga va normativ-huquqiy hujjatlarga rioya qilamiz.
				<br/><br/>
				Biz ommaviy axborot vositalari bilan munosabatlarimizda yuqori axloqiy me’yorlarni qo‘llab-quvvatlaymiz va reklama faoliyatimizda noto‘g‘ri ma’lumotlar tarqatish, faktlarni yashirish va/yoki noto‘g‘ri ko‘rsatishga yo‘l qo‘ymaymiz.
				<br/><br/>
				<b>Qonunlar va korporativ normalarga rioya qilish</b><br/>
				Kompaniyaning barcha xodimlari mahalliy va xalqaro qonunlar, Kompaniyaning ichki tartiblari va qoidalari talablariga qat’iy rioya qilishlari kerak. Kompaniya shaxsiy manfaatlar uchun yoki Kompaniya nomidan qilingan har qanday noqonuniy faoliyatga yo‘l qo‘ymaydi.
				<br/><br/>
				Kompaniya monopoliyaga qarshi qonunchilik talablariga rioya qiladi. Kompaniyaning yetkazib beruvchi va pudratchilarini tanlash erkin raqobat tamoyillariga muvofiq amalga oshiriladi.
				<br/><br/>
				Barcha buxgalteriya hisobi va boshqa hujjatlar amaldagi qonunchilik talablariga, amaldagi buxgalteriya hisobi va hisobot standartlariga, qabul qilingan ichki qoidalarga muvofiq bo‘lishi kerak. Kompaniyada qayd etilmagan, ro‘yxatdan o‘tmagan va yashirin aktivlar bo‘lmasligi kerak.
				<br/><br/>
				Xodimlar to‘g‘ridan-to‘g‘ri yoki bilvosita Kompaniyaning moliyaviy hisobotlari va ichki nazorat tizimining auditorlik tekshiruviga noto‘g‘ri ta’sir ko‘rsatishga yoki aralashishga harakat qilmasliklari kerak. Ichki yoki tashqi auditorlarning barcha savollariga samimiy javob berish kerak.
				<br/><br/>
				Ushbu talablar buzilgan taqdirda xodim darhol bosh buxgalter va Etika qo‘mitasiga xabar berishi kerak.
				<br/><br/>
				<b>Mijozlar oldida javobgarlik</b><br/>
				Inson salomatligi va sotilayotgan tovarlar ishonchliligini har qanday moliyaviy foydadan ko‘ra oliy darajada ko‘rgan holda, shuningdek mijoz manfaatlarini qisqa muddatli yuqori daromaddan ustun qo‘ygan holda Kompaniya o‘z mijozlariga sifatli va xavfsiz mahsulotlarni arzon narxlarda taklif qiladi.
				<br/><br/>
				Kompaniya mahsulot marketingi suiiste’moliga nisbatan zaif bo‘lgan mijozlar guruhlari, masalan bolalar uchun mas’uliyatli marketingni o‘z zimmasiga oladi.
				<br/><br/>
				<b>Xodimlar bilan munosabatlar</b></br>
				Kompaniya xodimlari katta qadriyat sanaladi va ular muvaffaqiyatimiz kalitidir. Kompaniya xodimlar ochiq va ilhomlantiruvchi ish muhitida o‘z potentsialini ro‘yobga chiqarishi mumkin bo‘lgan ish joyiga aylanishga intilishi kerak. Kompaniya o‘z xodimlari bilan munosabatlarni uzoq muddatli hamkorlik, o‘zaro hurmat va o‘zaro majburiyatlarni so‘zsiz bajarish tamoyillari asosida quradi.
				<br/><br/>
				Kompaniya o‘z xodimlarini quyidagilar uchun qadrlaydi:<br/>
				• malaka va professionallik,<br/>
				• mas’uliyat va intizom,<br/>
				• tashabbuskorlik va natijalarga yo‘naltirilganlik,<br/>
				• kasbiy rivojlanishga intilish,<br/>
				• Kompaniyaga sodiqlik,<br/>
				• tartiblilik.<br/>
				Kompaniya barcha xodimlar va nomzodlar uchun teng martaba imkoniyatlarini taqdim etishga intiladi. Bu shuni anglatadiki, Kompaniya xodimlari lavozimi, aloqa turi va usulidan qat’i nazar, o‘z hamkasblariga nisbatan xushmuomalalikni rad etadilar.
				Kadrlar bilan bog‘liq barcha masalalarda (saralash, yollash, xodimlarni o‘qitish, ish haqi oshishi va karyeraviy o‘sish) biz odamlarni jinsi, yoshi, irqi, terisining rangi, tili, millati yoki ijtimoiy kelib chiqishi, dini, oilaviy ahvoli, mulkiy holati asosida ajratmaymiz.
				<br/><br/>
				Kompaniya ish joyidagi jismoniy, og‘zaki, jinsiy yoki psixologik zo‘ravonlik va ta’qiblarning oldini olish, xabar berish va tekshirish mexanizmlarini o‘rnatadi. Kompaniya o‘z xodimlarini sog‘lom va xavfsiz mehnat muhiti bilan ta’minlash uchun amaldagi qonunchilikka muvofiq zarur choralarni ko‘radi.
				<b>Qo‘shimcha ma’lumot uchun Zo‘ravonlik va diskriminatsiyaga qarshi Siyosatga qarang.</b>
				<br/><br/>
				Kompaniya majburiy mehnatning har qanday shakliga yo‘l qo‘ymaydi. Ishchilarga ish kuni tugaganidan keyin erkin harakatlanishi va ish joylarini tark etishiga ruxsat berilishi kerak.
				<br/><br/>
				Kompaniya hech qanday sharoitda bolalar mehnatidan foydalanishga yo‘l qo‘ymaydi. To‘liq vaqtli ish uchun minimal yosh qonunchilikda belgilangan mehnat yoshiga mos kelishi kerak.
				<br/><br/>
				Kompaniya barcha xodimlar uchun ochiq eshiklar siyosatini yuritadi. Bu har qanday xodim uchun uning bevosita rahbari o‘z bo‘ysunuvchisini tinglashi va shunga mos ravishda javob berishi shartligini anglatadi.
				<br/><br/>
				Kompaniya xodimlarning korporativ madaniyat va ichki hamkorlikni rivojlantirishga hissa qo‘shadigan kompaniya hayotidagi faol ishtirokini mamnuniyat bilan qabul qiladi, xodimlarning amalga oshirilishi yanada yaxshi va yanada raqobatbardosh bo‘lishga imkon beruchi har qanday g‘oyalarini qadrlaydi va har tomonlama rag‘batlantiradi.
				<br/><br/>
				Kompaniya jamoada qulay ijtimoiy-psixologik muhitni saqlash uchun barcha sa’y-harakatlarni amalga oshiradi. Xodimlar o‘rtasidagi barcha kelishmovchiliklar ularning bevosita rahbari tomonidan, kerak bo‘lganda, Xodimlar bilan ishlash Departamentining vakolatli shaxsi bilan hamkorlikda hal qilinadi. Har qanday nizoni uchinchi shaxslarning mulkiga aylantirish mumkin emas.
				<br/><br/>
				<b>Tashqi kommunikatsiyalar</b></br>
				Marketing departamenti axborotni yig‘ish va ommaviy axborot vositalariga tarqatish uchun javobgardir. Marketing departamenti jamoasi, top-menejerlar (o‘z yo‘nalishi bo‘yicha) va Kuzatuv kengashi a’zolaridan tashqari, boshqa hech bir xodim “Anglesey Food” MCHJ XK faoliyati haqida marketing bo‘yicha direktorning oldindan roziligisiz Kompaniya nomidan ommaviy axborot vositalari bilan suhbatlasha olmaydi, matbuot va radioeshittirish muassasalarida, ijtimoiy tarmoqlar tashkilotlarida bayonotlar bera olmaydi.
				<br/><br/>
				Kompaniyaning har bir xodimi uning Kompaniya xodimi sifatida bildirgan har qanday nuqtai nazari yoki tarqatilgan ma’lumotlar Kompaniyaning o‘ziga, uning imidjiga bevosita bog‘liqligini va uning bozordagi obro‘siga ta’sir qilishini tushunishi va doimo yodda tutishi kerak. Barcha tashqi aloqalar halol, to‘liq, to‘g‘ri va chalg‘itmaydigan bo‘lishi kerak.
				<br/><br/>
				Kompaniya barcha xodimlaridan <b>Tashqi kommunikatsiyalar  siyosatiga</b> rioya qilishlarini kutadi.
				<br/><br/>
				Kompaniya xodimlari bo‘sh vaqtlarida o‘z manbalaridan foydalangan holda siyosiy tadbirlarda ishtirok etishlari mumkin. Xodimlar o‘zlarining mustaqil siyosiy faoliyati va “Anglesey Food” MChJ XK vakili sifatidagi faoliyati o‘rtasini ehtiyotkorlik bilan chegaralashlari kerak. Biroq, bu faoliyatlar ish vaqtidan tashqari amalga oshirilishi va hech qanday manba yoki kompaniya nomidan foydalanilmasligi kerak, amaldagi qonunlar va qoidalarda boshqacha qoida nazarda tutilgan hollar bundan mustasno. Shu bilan birga, kompaniya xodimning siyosiy faoliyati uchun javobgar emas
				<br/><br/>
				<b>Yetkazib beruvchilar va biznes-hamkorlar bilan munosabatlar</b></br>
				Kompaniya yetkazib beruvchilar va biznes-hamkorlar bilan barcha munosabatlarda professional, adolatli va halol harakat qiladi; noqonuniy yoki axloqiy bo‘lmagan vositalar orqali tijorat afzalliklarini ta’minlamaydi va raqobat to‘g‘risidagi qonunni buzadigan faoliyat bilan shug‘ullanmaydi.
				<br/><br/>
				Kompaniya xavfsiz va sog‘lom ish muhitini ta’minlovchi yetkazib beruvchi kompaniyalar bilan biznes aloqalarini saqlab turadi va ushbu standartlarga rioya etilishini muntazam tekshiradi. Kompaniya o‘zining ta’minot zanjiridagi siyosati va amaliyotlarini, jumladan, xavfsizlik va atrof-muhitni yaxshilashni qo‘llab-quvvatlaydi.
				<br/><br/>
				Kompaniya barcha biznes-hamkorlaridan amaldagi qonunchilikka muvofiq harakat qilishlarini kutadi.
				<br/><br/>
				Xodimlar Kompaniya hisobidan shaxsiy manfaatlarga olib keladigan hech qanday harakat qilmasliklari kerak. Xodimlar tenderlar, tanlovlar va savdolar o‘tkazishda, bitimlar tuzishda va ularni amalga oshirishda firibgarlik faoliyati bilan shug‘ullanmasligi, o‘z mansab vakolatlaridan tashqariga chiqmasligi, shuningdek, boshqa aldov harakatlariga yo‘l qo‘ymasligi kerak.
				<br/><br/>
				Xodimlar taqdim etilishi qandaydir tarzda kompaniyaga bo‘lgan munosabat bilan bog‘liq sovg‘alarni qabul qilish yoki qabul qilmaslik to‘g‘risida baholashda quyidagi tavsiyalarga rioya qilishlari kerak:
				<br/>
				• Sovg‘a berishga erishishga urinmaslik kerak. Xodimlar, ularning oila a’zolari va affillangan shaxslarga beruvchiga Kompaniya qarorlariga ta’sir o‘tkazish imkoniyatini taqdim etish evaziga har qanday sovg‘a yoki xizmatlarni so‘rash va olish qat’iyan man etiladi.<br/>
				• Olinishi oluvchining sovg‘a qiluvchiga nisbatan har qanday majburiyatlar paydo bo‘lishiga olib kelishi mumkin bo‘lgan sovg‘alarni qabul qilmaslik kerak.<br/>
				• Naqd pul yoki qimmatli qog‘oz ko‘rinishidagi sovg‘alarni qabul qilmaslik kerak.<br/>
				• Qiymati 500 000 so‘mdan ortiq bo‘lgan sovg‘alar faqat sovg‘ani rad etish haqorat sifatida qabul qilinishi mumkin bo‘lgan taqdirdagina qabul qilinadi. Bunday sovg‘alarni taqdim etish haqida darhol Etika bo‘yicha komissiyaga xabar berish kerak.<br/>
				<br/><br/>
				Xodimlar Kompaniyaning manfaatlari va obro‘siga putur yetkazishi mumkin bo‘lgan har qanday shaxsiy manfaatlar yoki faoliyatlar to‘g‘risida Etika bo‘yicha komissiyaga xabar berishlari, shuningdek, mumkin bo‘lgan manfaatlar to‘qnashuvini baholashlari va agar mavjud bo‘lsa, darhol o‘z rahbarini xabardor qilishlari shart. Batafsil ma’lumot uchun <b>Manfaatlar to‘qnashuvi Siyosatiga</b> qarang.
				<br/><br/>
				<b>Poraxo‘rlik va korrupsiyaga qarshi kurash</b></br>
				Kompaniya xodimlari davlat mansabdor shaxslariga, davlat xizmatchilariga, xalqaro tashkilotlar xodimlariga va ularning oila a’zolariga Kompaniya manfaatlari yo‘lida rasmiy ta’sir ko‘rsatish evaziga har qanday shaklda noqonuniy foyda olish uchun g‘ayriqonuniy to‘lovlarni amalga oshirmasligi kerak. G‘ayriqonuniy to‘lovlarni amalga oshirish bilan bog‘liq har qanday vaziyatning oldini olish uchun (shu jumladan, Kompaniya uchun tijorat yoki boshqa manfaatlarga erishish maqsadida amalga oshirilgan) xodimlar sog‘lom fikrga tayanishlari kerak.
				<br/><br/>
				Davlat amaldorlari uchun ko‘ngilochar dasturlar asosli bo‘lishi, oqilona chegaralardan tashqariga chiqmasligi va Kompaniya faoliyati bilan bevosita bog‘liq bo‘lmasligi kerak. Xuddi shunday, barcha sovg‘alar qiymati 500 000 so‘mdan oshmaydigan maqbul minimal qiymatda bo‘lishi kerak va har qanday masalada Kompaniyani vakillik qilishda nohaq ustunlikka ega bo‘lishni nazarda tutmasligi kerak.
				<br/><br/>
      </li>
		</ul>
		<br/><br/>
		<p>6-modda</p>
		<br/>
		<p>Ishbilarmonlik odobi</p>
		<br/>
    <ul>
      <li>
			Kompaniyaning xodimi, lavozimidan, munosabat turi va usulidan qat’i nazar, quyidagi qoidalarga amal qiladi: <br/>
				•	Hamkasblarga o‘zaro hurmat ko‘rsatish;<br/>
				•	His-tuyg‘ularini nazorat qilish (odobsiz so‘zlarni ishlatmaslik, baland ovozda gapirmaslik, qo‘l ko‘tarmaslik);<br/>
				•	Fitnalar, mish-mishlar, g‘iybatlarni to‘xtatish;<br/>
				•	Shaxsiyatni emas, balki harakatlarni muhokama qilish;<br/>
				•	Xodimlarning xususiy (shaxsiy) hayotini hurmat qilish, shaxsning suverenitetini hurmat qilish;<br/>
				•	Ish muhitiga do‘stlikni olib kirmaslik;<br/>
				•	Hamkasblariga yomon kayfiyatini ko‘rsatmaslik;<br/>
				•	Yaxshi bajarilgan ish uchun hamkasblarga minnatdorchilik bildirish.
      </li>
		</ul>
		<br/><br/>
		<p>7-modda</p>
		<br/>
		<p>Axborotning maxfiyligi</p>
		<br/>
    <ul>
      <li>
				Xodimlar Kompaniyaning quyidagi maxfiy ma’lumotlari xavfsizligini ta’minlashi va uning Kompaniya ichida va tashqarisida tarqalishiga yo‘l qo‘ymasliklari kerak: <br/>
				•	Kompaniyaning strategik va operativ rejalari;<br/>
				•	Kompaniyaning moliyaviy natijalari va marketing faoliyati to‘g‘risidagi ma’lumotlar;<br/>
				•	Kompaniyaning yetkazib beruvchilari va hamkorlari to‘g‘risidagi ma’lumotlar;<br/>
				•	Kompaniyada qo‘llaniladigan texnologiyalar, uskunalar, avtomatlashtirilgan tizimlar haqidagi ma’lumot;<br/>
				•	Kompaniyaning texnik xavfsizlik tizimlari to‘g‘risidagi ma’lumotlar;<br/>
				•	Kompaniya xodimlari to‘g‘risidagi ma’lumotlar (shtat jadvali, xodimlarning shaxsiy ma'lumotlari, ish haqi to‘g‘risidagi ma’lumotlar va boshqalar);<br/>
				•	Ichki me’yoriy hujjatlar;<br/>
				•	Hamkasblarning shaxsiy hayotiga ta'sir qiladigan yoki hamkasblarning his-tuyg‘ulariga putur yetkazadigan ichki nizolar, kelishmovchiliklar va hodisalar haqidagi ma’lumotlar.
				<br/><br/>
				Xodimlar tomonidan Kompaniyada ishlagan davrida ishlab chiqilgan ichki me’yoriy hujjatlar Kompaniyaning mulki hisoblanadi.
				<br/><br/>
				“Anglesey Food” MChJ XK da ish boshlash bilan xodimlar tijorat sirlarini saqlash majburiyatini oladilar. “Anglesey Food” MChJ XK ning maxfiy ma’lumotlarini saqlash majburiyati Kompaniyadagi ishni tugatish bilan bekor qilinmaydi.
      </li>
		</ul>
		<br/><br/>
		<p>8-modda</p>
		<br/>
		<p>Saqlash</p>
		<br/>
    <ul>
      <li>
				Ushbu Siyosatning asl nusxasi Xodimlar bilan ishlash departamentida saqlanadi.
      </li>
		</ul>
  </div>
</div>
	`,

  ppl_title: 'Korzinka dasturi qoidalari',

  offerta_page_title: 'Ommaviy oferta 2.0',

  // 2021 year
  year_of_charity2021: {
    table: [
      {
        date: '	29.04.2021',
        gathered_sum: '564 120 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Аэропорт'
      },
      {
        date: '08.04.2021',
        gathered_sum: '23 034 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Риёзий'
      },
      {
        date: '07.04.2021',
        gathered_sum: '17 382 152',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара 2'
      },
      {
        date: '06.04.2021',
        gathered_sum: '19 800 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },
      {
        date: '25.03.2021',
        gathered_sum: '36 514 311',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара 1'
      },
      {
        date: '24.02.2021',
        gathered_sum: '16 806 850',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ракат'
      },
      {
        date: '02.02.2021',
        gathered_sum: '21 615 500',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },
      {
        date: '28.01.2021',
        gathered_sum: '12 348 750',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Спутник-Сергели'
      },
      {
        date: '26.01.2021',
        gathered_sum: '22 358 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Караташ'
      },

      {
        date: '06.01.2021',
        gathered_sum: '14 930 000	',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Next'
      },

      {
        date: '06.01.2021',
        gathered_sum: '10 735 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Камолон'
      }
    ],
    year: 'Итого за 2021 г.',
    sum: '759 644 963'
  },
  // 2020 year
  year_of_charity2020: {
    table: [
      {
        date: '22.12.2020',
        gathered_sum: '25 026 600',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'С.Азимова'
      },
      {
        date: '16.12.2020',
        gathered_sum: '5 090 100',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Oazis'
      },
      {
        date: '07.12.2020',
        gathered_sum: '15 798 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Алмалык'
      },
      {
        date: '01.12.2020',
        gathered_sum: '12 955 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Фонд "Эзгу Амал"'
      },
      {
        date: '30.11.2020',
        gathered_sum: '2 950 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Янгийль'
      },
      {
        date: '26.11.2020',
        gathered_sum: '8 751 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Куйлюк'
      },
      {
        date: '25.11.2020',
        gathered_sum: '17 358 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Файзабад'
      },
      {
        date: '24.11.2020',
        gathered_sum: '16 375 950',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Mercato'
      },
      {
        date: '24.11.2020',
        gathered_sum: '9 741 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Андижан'
      },

      {
        date: '	23.11.2020',
        gathered_sum: '21 212 750',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Карасу'
      },

      {
        date: '19.11.2020',
        gathered_sum: '18 000 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '18.11.2020',
        gathered_sum: '22 381 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чилонзар'
      },

      {
        date: '17.11.2020',
        gathered_sum: '24 288 350',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Абай'
      },

      {
        date: '16.11.2020',
        gathered_sum: '25 393 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сайрам'
      },

      {
        date: '14.11.2020',
        gathered_sum: '34 306 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сергели'
      },

      {
        date: '14.11.2020',
        gathered_sum: '14 037 250',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Юнусабад'
      },

      {
        date: '	09.11.2020',
        gathered_sum: '21 108 150',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },

      {
        date: '09.11.2020',
        gathered_sum: '32 080 600',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Риёзий'
      },

      {
        date: '30.06.2020',
        gathered_sum: '17 631 950',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },

      {
        date: '30.06.2020',
        gathered_sum: '7 304 500',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Заамин'
      },

      {
        date: '30.06.2020',
        gathered_sum: '3 746 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Аэропорт'
      },

      {
        date: '29.06.2020',
        gathered_sum: '5 927 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Oazis'
      },

      {
        date: '29.06.2020',
        gathered_sum: '11 996 900',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ракат'
      },

      {
        date: '29.06.2020',
        gathered_sum: '6 591 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Салом'
      },

      {
        date: '27.06.2020',
        gathered_sum: '22 246 900',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара-1'
      },

      {
        date: '27.06.2020',
        gathered_sum: '9 195 591',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара-2'
      },

      {
        date: '27.06.2020',
        gathered_sum: '9 715 750',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара-3'
      },

      {
        date: '27.06.2020',
        gathered_sum: '14 496 700',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Андижан'
      },

      {
        date: '27.06.2020',
        gathered_sum: '3 010 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Асака'
      },

      {
        date: '27.06.2020',
        gathered_sum: '14 650 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Коканд'
      },

      {
        date: '27.06.2020',
        gathered_sum: '20 304 700',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бунёдкор'
      },

      {
        date: '27.06.2020',
        gathered_sum: '7 714 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чилонзар'
      },

      {
        date: '27.06.2020',
        gathered_sum: '12 116 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бешкайрагач'
      },

      {
        date: '26.06.2020',
        gathered_sum: '19 132 150',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Next'
      },

      {
        date: '26.06.2020',
        gathered_sum: '19 684 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Алайский'
      },

      {
        date: '26.06.2020',
        gathered_sum: '3 407 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Mercato'
      },

      {
        date: '26.06.2020',
        gathered_sum: '16 803 450',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Vega'
      },

      {
        date: '26.06.2020',
        gathered_sum: '6 691 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '26.06.2020',
        gathered_sum: '6 397 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Назарбек'
      },

      {
        date: '26.06.2020',
        gathered_sum: '19 058 300',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бекабад'
      },

      {
        date: '26.06.2020',
        gathered_sum: '38 166 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Карасу'
      },

      {
        date: '26.06.2020',
        gathered_sum: '7 714 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Integro'
      },

      {
        date: '24.06.2020',
        gathered_sum: '14 755 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'А.Дониш'
      },

      {
        date: '24.06.2020',
        gathered_sum: '12 015 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Юнусабад'
      },

      {
        date: '23.06.2020',
        gathered_sum: '13 955 850',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Алмалык'
      },

      {
        date: '23.06.2020',
        gathered_sum: '14 056 050',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Себзар'
      },

      {
        date: '23.06.2020',
        gathered_sum: '11 113 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Куйлюк'
      },

      {
        date: '18.06.2020',
        gathered_sum: '13 831 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Янгийюль'
      },

      {
        date: '17.06.2020',
        gathered_sum: '26 147 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чирчик'
      },

      {
        date: '10.06.2020',
        gathered_sum: '21 885 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Спутник - Сергели'
      },

      {
        date: '09.06.2020',
        gathered_sum: '16 811 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Караташ'
      },

      {
        date: '05.06.2020',
        gathered_sum: '17 438 050',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ангрен'
      },

      {
        date: '04.06.2020',
        gathered_sum: '18 243 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Учтепа'
      },

      {
        date: '03.06.2020',
        gathered_sum: '23 867 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Авиасозлар'
      },

      {
        date: '28.05.2020',
        gathered_sum: '20 804 550',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Авиасозлар'
      },

      {
        date: '27.05.2020',
        gathered_sum: '28 051 500',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Аэропорт'
      },

      {
        date: '26.05.2020',
        gathered_sum: '34 882 250',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Абай'
      },

      {
        date: '21.05.2020',
        gathered_sum: '16 929 950',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Шедевр'
      },

      {
        date: '21.05.2020',
        gathered_sum: '30 662 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сайрам'
      },

      {
        date: '19.05.2020',
        gathered_sum: '23 506 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Салом'
      },

      {
        date: '18.05.2020',
        gathered_sum: '14 406 550',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ал-Хоразмий'
      },

      {
        date: '14.05.2020',
        gathered_sum: '19 100 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '13.05.2020',
        gathered_sum: '30 366 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чиланзар'
      },

      {
        date: '08.05.2020',
        gathered_sum: '24 550 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'С. Азимова'
      },

      {
        date: '07.05.2020',
        gathered_sum: '32 900 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Риёзий'
      },

      {
        date: '06.05.2020',
        gathered_sum: '21 121 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Файзабад'
      },

      {
        date: '05.05.2020',
        gathered_sum: '36 176 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сергели'
      },

      {
        date: '26.03.2020',
        gathered_sum: '35 313 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },

      {
        date: '19.03.2020',
        gathered_sum: '26 233 750',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Юнусабад'
      },

      {
        date: '16.03.2020',
        gathered_sum: '18 581 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бешкайрагач'
      },

      {
        date: '	16.03.2020',
        gathered_sum: '21 977 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Камолон'
      },

      {
        date: '18.02.2020',
        gathered_sum: '23 726 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ракат'
      },

      {
        date: '11.02.2020',
        gathered_sum: '19 653 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Куйлюк'
      },

      {
        date: '08.02.2020',
        gathered_sum: '22 825 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Саракулька'
      },

      {
        date: '01.02.2020',
        gathered_sum: '6 415 850',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ургут'
      },

      {
        date: '31.01.2020',
        gathered_sum: '28 513 150',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Карасу'
      },

      {
        date: '30.01.2020',
        gathered_sum: '13 169 000',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Бухара 3'
      },

      {
        date: '30.01.2020',
        gathered_sum: '11 240 000',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Бухара 2'
      },

      {
        date: '30.01.2020',
        gathered_sum: '35 287 500',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Бухара'
      },

      {
        date: '30.01.2020',
        gathered_sum: '21 288 700',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Алмалык'
      },

      {
        date: '24.01.2020',
        gathered_sum: '17 363 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Караташ'
      },

      {
        date: '15.01.2020',
        gathered_sum: '22 100 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '08.01.2020',
        gathered_sum: '29 868 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Абай'
      }
    ],

    year: 'Итого за 2020 г.',
    sum: '1 521 686 691'
  },
  // 2019 year
  year_of_charity2019: {
    table: [
      {
        date: '26.12.2019',
        gathered_sum: '30 299 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Салом'
      },
      {
        date: '26.12.2019',
        gathered_sum: '29 162 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Бунёдкор'
      },
      {
        date: '11.12.2019',
        gathered_sum: '29 162 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Сайрам'
      },
      {
        date: '03.12.2019',
        gathered_sum: '32 419 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Туркменский'
      },
      {
        date: '29.11.2019',
        gathered_sum: '29 899 500',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'С.Азимова'
      },
      {
        date: '27.11.2019',
        gathered_sum: '24 733 750',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов "Мехрибонлик" Юнусабадского района города Ташкент',
        supermarket: 'Некст'
      },
      {
        date: '26.11.2019',
        gathered_sum: '24 457 000',
        where_passed: 'Детский дом №23',
        supermarket: 'Меркато'
      },
      {
        date: '21.11.2019',
        gathered_sum: '31 703 350',
        where_passed:
          '"Опа - Сингиллар" Кибрайское районное общество женщин-инвалидов.',
        supermarket: 'Чилонзар'
      },
      {
        date: '20.11.2019',
        gathered_sum: '31 574 250',
        where_passed:
          'Общества Инвалидов Узбекистана города Ташкента "Умид Нури"',
        supermarket: 'Аэропорт'
      },

      {
        date: '19.11.2019',
        gathered_sum: '28 955 600',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Риёзий'
      },

      {
        date: '15.11.2019',
        gathered_sum: '22 122 000',
        where_passed:
          'Общество Инвалидов Узбекистана города Ташкента отделение Яккасаройского района',
        supermarket: 'Учтепа'
      },

      {
        date: '13.11.2019',
        gathered_sum: '12 815 200',
        where_passed:
          'Зааминского районного фонда "Соглом авлод учун" Узбекистана',
        supermarket: 'Заамин'
      },

      {
        date: '07.11.2019',
        gathered_sum: '18 299 400',
        where_passed:
          'Ташкентский городской отдел объединения "VETERAN" воинов-ветеранов и инвалидов Узбекистана',
        supermarket: 'Бекабад'
      },

      {
        date: '01.11.2019',
        gathered_sum: '27 921 200',
        where_passed: 'РИО ва РИАТМТ Онкологиии Радиология г.Ташкента',
        supermarket: 'Сергели'
      },

      {
        date: '25.10.2019',
        gathered_sum: '19 382 300',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Бешкайрагач'
      },

      {
        date: '22.10.2019',
        gathered_sum: '10 532 150',
        where_passed: 'Бектимерское Общество Инвалидов города Ташкента',
        supermarket: 'Файзабад'
      },

      {
        date: '13.09.2019',
        gathered_sum: '15 651 000',
        where_passed: 'РЕСПУБЛИКА УЗБЕКИСТАН УП NOGIRONLARGA MARKAZI',
        supermarket: 'Шедевр'
      },

      {
        date: '06.09.2019',
        gathered_sum: '4 623 000',
        where_passed:
          'Районное Общество Инвалидов Булокбоши Андижанской области Республики Узбекистан',
        supermarket: 'Асака'
      },

      {
        date: '05.09.2019',
        gathered_sum: '16 605 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Андижан'
      },

      {
        date: '28.08.2019',
        gathered_sum: '25 352 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Абая'
      },

      {
        date: '16.08.2019',
        gathered_sum: '21 623 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'ТТЗ'
      },

      {
        date: '08.08.2019',
        gathered_sum: '20 927 100',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Юнусабад'
      },

      {
        date: '06.08.2019',
        gathered_sum: '29 007 200',
        where_passed: 'Ташкентский областной Дом Ребёнка "Кичкинтой"',
        supermarket: 'Туркменский'
      },

      {
        date: '25.07.2019',
        gathered_sum: '10 857 150',
        where_passed:
          'Ургутское отделение Узбекского Общества Инвалидов Самаркандской области',
        supermarket: 'Ургут'
      },

      {
        date: '23.07.2019',
        gathered_sum: '19 677 500',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Карасу'
      },

      {
        date: '16.07.2019',
        gathered_sum: '14 938 500',
        where_passed: 'Дом "Мехрибонлик" № 30',
        supermarket: 'Караташ'
      },

      {
        date: '09.07.2019',
        gathered_sum: '17 554 550',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Чирчик'
      },

      {
        date: '02.07.2019',
        gathered_sum: '25 720 850',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Риёзий'
      },

      {
        date: '27.06.2019',
        gathered_sum: '30 307 400',
        where_passed:
          '"Возможно Всё" Общественно-Благотворительное Объединение. г.Бухара',
        supermarket: 'Бухара 1'
      },

      {
        date: '27.06.2019',
        gathered_sum: '5 202 000',
        where_passed:
          'Бухарское городское отделение Общества слепых Узбекистана',
        supermarket: 'Бухара 3'
      },

      {
        date: '27.06.2019',
        gathered_sum: '13 042 200',
        where_passed:
          'Бухарское городское отделение Общества слепых Узбекистана',
        supermarket: 'Бухара 2'
      },

      {
        date: '11.06.2019',
        gathered_sum: '12 871 300',
        where_passed: 'Nogironlarni ximoya qilish uk',
        supermarket: 'А.Дониш'
      },

      {
        date: '28.03.2019',
        gathered_sum: '19 540 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Ракат'
      },

      {
        date: '27.03.2019',
        gathered_sum: '13 808 850',
        where_passed: 'Общество Инвалидов г.Коканд',
        supermarket: 'Коканд'
      },

      {
        date: '22.02.2019',
        gathered_sum: '10 441 600',
        where_passed: 'Бекабадское Общество Инвалидов',
        supermarket: 'Бекабад'
      },

      {
        date: '21.02.2019',
        gathered_sum: '22 569 850',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Караташ'
      },

      {
        date: '07.02.2019',
        gathered_sum: '15 760 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Алгоритм'
      },

      {
        date: '31.01.2019',
        gathered_sum: '16 850 000',
        where_passed: 'Букинское отделение Общества Инвалидов Узбекистана',
        supermarket: 'Алайский'
      },

      {
        date: '30.01.2019',
        gathered_sum: '19 513 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Карасу'
      },

      {
        date: '29.01.2019',
        gathered_sum: '30 120 920',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Туркменский'
      },

      {
        date: '22.01.2019',
        gathered_sum: '14 676 900',
        where_passed:
          '1. Фонд «Эзгу Амал» — 14 676 900 сум. 2. Отделение Узбекистанское общество инвалидов — 4 000 000 сум',
        supermarket: 'Next'
      },

      {
        date: '22.01.2019',
        gathered_sum: '4 000 000',
        where_passed: '',
        supermarket: 'Некст'
      },

      {
        date: '15.01.2019',
        gathered_sum: '33 274 400',
        where_passed: 'Онкология ва Радиология табиий маркази',
        supermarket: 'Mercato'
      },

      {
        date: '07.01.2019',
        gathered_sum: '27 393 100',
        where_passed: 'Общество инвалидов Узбекистана',
        supermarket: 'Риёзий'
      }
    ],

    year: 'Итого за 2019 г.',
    sum: '915 604 070'
  },

  // 2018 year
  year_of_charity2018: {
    table: [
      {
        date: '26.12.2018',
        gathered_sum: '28 022 000',
        where_passed:
          'аучно-исследовательский институт гематологии и переливания крови',
        supermarket: 'Сергели'
      },
      {
        date: '25.12.2018',
        gathered_sum: '17 036 000',
        where_passed:
          'Общество Инвалидов Узбекистана г.Ташкента. Отделение Студенческого городка «Ёшлик»',
        supermarket: 'Абай'
      },
      {
        date: '20.12.2018',
        gathered_sum: '15 323 350',
        where_passed:
          'Специализированная школа-интернат №102 для слабослышащих детей',
        supermarket: 'Учтепа'
      },
      {
        date: '11.12.2018',
        gathered_sum: '21 802 000',
        where_passed: 'Фонд «EZGU AMAL»',
        supermarket: 'Юнусабад'
      },
      {
        date: '28.11.2018',
        gathered_sum: '18 099 320',
        where_passed: 'ДПИ «MEHRIBON-NOGIRON»',
        supermarket: 'Чиланзар'
      },
      {
        date: '26.11.2018',
        gathered_sum: '10 610 900',
        where_passed:
          'Сергелинское, Яккасарайского района общество слепых Республика Узбекистана',
        supermarket: 'Куйлюк'
      },
      {
        date: '23.11.2018',
        gathered_sum: '16 920 950',
        where_passed:
          'Узбекское Общество Инвалидов Шайхонтахурское районное отделение города Ташкента',
        supermarket: 'Сайрам'
      },
      {
        date: '21.11.2018',
        gathered_sum: '17 626 300',
        where_passed: '«Центр Культуры Глухих» города Ташкента',
        supermarket: 'Аэропорт'
      },
      {
        date: '09.11.2018',
        gathered_sum: '14 349 945',
        where_passed:
          '«Общество Инвалидов» Чирчикского городского отделения Ташкентской области',
        supermarket: 'ТТЗ'
      },

      {
        date: '05.11.2018',
        gathered_sum: '18 238 000',
        where_passed:
          '«Общество Инвалидов» Юнусабадского отделения города Ташкента',
        supermarket: 'Бунедкор'
      },

      {
        date: '24.10.2018',
        gathered_sum: '11 725 300',
        where_passed: '«Общество Инвалидов Узбекистана» Навоинской области',
        supermarket: 'Бухара'
      },

      {
        date: '12.10.2018',
        gathered_sum: '10 663 000',
        where_passed: '«Сахобат» дом-интернат для престарелых города Андижан',
        supermarket: 'Андижан'
      },

      {
        date: '08.10.2018',
        gathered_sum: '29 621 945',
        where_passed: '«Узбекистон ногиронлар жамияти» Тошкент шахар булими',
        supermarket: 'Салом'
      },

      {
        date: '17.08.2018',
        gathered_sum: '19 824 000',
        where_passed: 'Специализированная Школа №36',
        supermarket: 'Туркменский'
      },

      {
        date: '15.08.2018',
        gathered_sum: '12 255 700',
        where_passed: '«Общество Инвалидов» города Ангрена',
        supermarket: 'Алмалык'
      },

      {
        date: '27.07.2018',
        gathered_sum: '10 344 500',
        where_passed:
          'Бухарское городское отделение Общества слепых Узбекистана',
        supermarket: 'Бухара 1'
      },

      {
        date: '25.07.2018',
        gathered_sum: '13 072 150',
        where_passed: '«Общество Инвалидов» города Ташкента',
        supermarket: 'Корасу'
      },

      {
        date: '13.07.2018',
        gathered_sum: '17 453 000',
        where_passed: 'Общество помощи инвалидам «Rumit-Inval»',
        supermarket: 'ТТЗ'
      },

      {
        date: '14.06.2018',
        gathered_sum: '14 082 175',
        where_passed:
          'Детям с нарушениями в психофизическом развитии «Областной специализированный дом ребенка»',
        supermarket: 'Аэропорт'
      },

      {
        date: '12.06.2018',
        gathered_sum: '18 418 661',
        where_passed: 'Дом ребенка города Янгиюль',
        supermarket: 'Сергели'
      },

      {
        date: '07.06.2018',
        gathered_sum: '14 460 525',
        where_passed:
          '«Узбекское общество инвалидов» Юнусабадкого районного отделения г.Ташкента',
        supermarket: 'Караташ'
      },

      {
        date: '29.05.2018',
        gathered_sum: '20 722 150',
        where_passed:
          '«Общество социальной взаимопомощи инвалидов» Мирабадского районного отделения г.Ташкента',
        supermarket: 'Азимова'
      },

      {
        date: '18.05.2018',
        gathered_sum: '14 280 750',
        where_passed:
          'Тошкент шахар «Ижтимоий Узаро Йордам МЕХРИБОНЛИК Ногиронлар Жамияти»',
        supermarket: 'Риёзий'
      },

      {
        date: '17.05.2018',
        gathered_sum: '12 091 100',
        where_passed:
          '«Общество Инвалидов Узбекистана» Яшнабадского районного отделения г.Ташкента',
        supermarket: 'Куйлюк'
      },

      {
        date: '15.05.2018',
        gathered_sum: '11 485 040',
        where_passed: 'Детский дом №23',
        supermarket: 'Integro'
      },

      {
        date: '04.05.2018',
        gathered_sum: '16 357 600',
        where_passed:
          'Детям с заболеваниями онкологии обществ «Возможно ВСЕ», «Давайте сотворим чудо»',
        supermarket: 'Бухара'
      },

      {
        date: '24.04.2018',
        gathered_sum: '11 591 900',
        where_passed:
          '«Узбекское Общество Инвалидов» Алмазарского районного отделения города Ташкента',
        supermarket: 'Vega'
      },

      {
        date: '16.04.2018',
        gathered_sum: '10 445 000',
        where_passed:
          'ОСВИ «Мехрибонлик» Яккасарайского района города Ташкента',
        supermarket: 'Next'
      },

      {
        date: '13.04.2018	',
        gathered_sum: '7 964 800',
        where_passed: 'Общество Женщин Инвалидов «Опа-Сингиллар» ЛИК',
        supermarket: 'Сайрам'
      },

      {
        date: '11.04.2018',
        gathered_sum: '16 367 400',
        where_passed: 'Научно-Исследовательский институт Гематологии',
        supermarket: 'Абая'
      },

      {
        date: '28.03.2018',
        gathered_sum: '5 813 900',
        where_passed:
          'Общество инвалидов «Мурувват» отделение Бутакора Андижанской области',
        supermarket: 'Андижан'
      },

      {
        date: '28.02.2018',
        gathered_sum: '12 787 800',
        where_passed: 'Ташкентский Онкологический диспансер',
        supermarket: 'Туркменский'
      },

      {
        date: '22.02.2018',
        gathered_sum: '13 876 800',
        where_passed: '«Общество Инвалидов Узбекистана» города Ташкента',
        supermarket: 'Карасу'
      },

      {
        date: '22.02.2018',
        gathered_sum: '17 925 830',
        where_passed: 'Специализированная школа №37',
        supermarket: 'Авиатор'
      },

      {
        date: '13.02.2018',
        gathered_sum: '17 428 960',
        where_passed:
          '«Общество Инвалидов Узбекистана» города Ташкента Отделение Студенческого городка «Ёшлик»',
        supermarket: 'Сергели'
      },

      {
        date: '06.02.2018',
        gathered_sum: '15 823 950',
        where_passed:
          'ОДИ «Ногирон–Болалар Камалак уйи». Приобретение лекарственных препаратов и продуктов питания, материальная помощь семьям с детьми инвалидами',
        supermarket: 'Ракат'
      },

      {
        date: '01.02.2018',
        gathered_sum: '15 650 800',
        where_passed:
          'Общество Женщин-Инвалидов Кибрайского района «Опа-Сингиллар»',
        supermarket: 'ТТЗ'
      },

      {
        date: '05.01.2018',
        gathered_sum: '7 666 625',
        where_passed: 'Бектемирское Общество Инвалидов города Ташкента',
        supermarket: 'Сайрам'
      },

      {
        date: '05.01.2018',
        gathered_sum: '7 129 660',
        where_passed:
          'Общество Социальной Взаимопомощи «Мехрибонлик» Юнусабадского районного отделения',
        supermarket: 'Учтепа'
      },

      {
        date: '04.01.2018',
        gathered_sum: '9 183 000',
        where_passed: '«Общество Инвалидов» Ташкентского района',
        supermarket: 'Аэропорт'
      },

      {
        date: '04.01.2018',
        gathered_sum: '13 019 900',
        where_passed: 'Международный Женский Общественный Фонд «Sharq Ayoli»',
        supermarket: 'Mercato'
      }
    ],

    year: 'Итого за 2018 г.',
    sum: '607 562 686'
  },

  //  2017 year
  year_of_charity2017: {
    table: [
      {
        date: '21.12.2017',
        gathered_sum: '7 045 900',
        where_passed: 'Школа интернат для глухонемых №80',
        supermarket: 'Алмалык'
      },
      {
        date: '19.12.2017',
        gathered_sum: '13 943 700',
        where_passed: 'Общество Инвалидов Юнусабадского отделение',
        supermarket: 'Абай'
      },
      {
        date: '13.12.2017',
        gathered_sum: '12 588 800',
        where_passed: 'Общество «Возможно Всё!», «Давайте сотворим чудо!»',
        supermarket: 'Бухара DISKONT'
      },
      {
        date: '11.12.2017',
        gathered_sum: '11 085 000',
        where_passed: 'Центр Культуры Глухих города Ташкента',
        supermarket: 'Integro'
      },
      {
        date: '09.11.2017',
        gathered_sum: '7 892 700',
        where_passed: 'Учтепинское Общество Инвалидов «Мехрибонлик»',
        supermarket: 'Караташ'
      },
      {
        date: '08.11.2017',
        gathered_sum: '11 514 200',
        where_passed: '«Мехрибонлик» №23',
        supermarket: 'Туркменский'
      },
      {
        date: '08.11.2017',
        gathered_sum: '12 445 560',
        where_passed: 'Чиланзарское Общество Инвалидов',
        supermarket: 'Юнусабад'
      },
      {
        date: '07.11.2017',
        gathered_sum: '15 021 300',
        where_passed:
          'Общество инвалидов Ташкентской области с целью организации праздников и оказания материальной помощи',
        supermarket: 'Салом'
      },
      {
        date: '05.10.2017',
        gathered_sum: '11 428 377',
        where_passed: 'Городское Общество Инвалидов г.Ташкента',
        supermarket: 'Риёзий'
      },

      {
        date: '21.08.2017',
        gathered_sum: '14 662 300',
        where_passed: 'Общество Инвалидов Алмазарского района',
        supermarket: 'Абай'
      },

      {
        date: '18.08.2017',
        gathered_sum: '13 664 700',
        where_passed: 'Общество Инвалидов Яшнобадского района',
        supermarket: 'ТТЗ'
      },

      {
        date: '18.08.2017',
        gathered_sum: '12 027 270',
        where_passed: '«Мехрибонлик» ОСВИ Мирабадского отделения',
        supermarket: 'Аэропорт'
      },

      {
        date: '16.08.2017',
        gathered_sum: '16 397 670',
        where_passed: 'ОСВИ « Мехрибонлик » Мирзо Улугбекского района',
        supermarket: 'Азимова'
      },

      {
        date: '16.08.2017',
        gathered_sum: '12 442 575',
        where_passed: 'Отделение Сергелинского района Общество Слепых',
        supermarket: 'Сергели'
      },

      {
        date: '06.07.2017',
        gathered_sum: '10 588 700',
        where_passed:
          'Узбекское Общество инвалидов Чирчикского городского отделения',
        supermarket: 'Алмалык'
      },

      {
        date: '03.07.2017',
        gathered_sum: '8 815 230',
        where_passed: 'Общество инвалидов Шайхонтахурского районного отделения',
        supermarket: 'Карасу'
      },

      {
        date: '29.06.2017',
        gathered_sum: '18 790 650',
        where_passed: 'ОО «АPLA»',
        supermarket: 'Mercato'
      },

      {
        date: '28.06.2017',
        gathered_sum: '11 490 350',
        where_passed: '«Ногирон Болалар Камалак уйи»',
        supermarket: 'Integro'
      },

      {
        date: '14.06.2017',
        gathered_sum: '10 402 135',
        where_passed: 'Институт Гематологии и переливания крови',
        supermarket: 'Туркменский'
      },

      {
        date: '12.04.2017',
        gathered_sum: '9 976 680',
        where_passed: 'Общество женщин-инвалидов «Опа-сингиллар»',
        supermarket: 'Караташ'
      },

      {
        date: '29.03.2017',
        gathered_sum: '11 958 800',
        where_passed: 'Ташкентский онкологический диспансер',
        supermarket: 'Абай'
      },

      {
        date: '24.03.2017',
        gathered_sum: '14 106 430',
        where_passed: 'Студентам-инвалидам Городского общества «Ёшлик»',
        supermarket: 'Авиатор'
      },

      {
        date: '17.03.2017',
        gathered_sum: '9 806 500',
        where_passed: 'Ташкентский онкологический диспансер',
        supermarket: 'Сергели'
      },

      {
        date: '10.03.2017',
        gathered_sum: '12 576 300',
        where_passed: 'Яккасарайский район «Мехрибонлик». Медикаменты',
        supermarket: 'ТТЗ'
      },

      {
        date: '03.03.2017',
        gathered_sum: '11 232 900',
        where_passed:
          'Юнусабадское районное отделение г.Ташкент «Узбекское общество инвалидов»',
        supermarket: ''
      },

      {
        date: '22.02.2017',
        gathered_sum: '9 961 910',
        where_passed: 'Бектемирское общество инвалидов',
        supermarket: 'Ракат'
      },

      {
        date: '12.01.2017',
        gathered_sum: '11 782 100',
        where_passed: 'Общество инвалидов Ташкентского городского отделения',
        supermarket: 'Туркменский'
      },

      {
        date: '09.01.2017',
        gathered_sum: '6 473 260',
        where_passed:
          'Общество социальной взаимопомощи инвалидов «Мехрибонлик»',
        supermarket: 'Карасу'
      },

      {
        date: '06.01.2017',
        gathered_sum: '13 424 400',
        where_passed: 'Общество Женщин-инвалидов «Опа-сингиллар»',
        supermarket: 'Integro'
      },

      {
        date: '05.01.2017',
        gathered_sum: '11 659 800',
        where_passed: 'Международный женский общественный фонд «Sharq Ayoli»',
        supermarket: 'Аэропорт'
      }
    ],

    year: 'Итого за 2017 г.',
    sum: '355 206 197'
  },

  //  2016 year
  year_of_charity2016: {
    table: [
      {
        date: '26.10.2016',
        gathered_sum: '11 510 920',
        where_passed:
          'Общество инвалидов Алмазарского районного отделения г.Ташкент',
        supermarket: 'Юнусабад'
      },
      {
        date: '31.08.2016',
        gathered_sum: '8 833 500',
        where_passed: 'Общество инвалидов Бектемирского районного отделения',
        supermarket: 'Карасу'
      },
      {
        date: '31.08.2016',
        gathered_sum: '6 729 200',
        where_passed: 'Центр культуры глухих г.Ташкент',
        supermarket: 'ТТЗ'
      },
      {
        date: '19.08.2016',
        gathered_sum: '12 395 700',
        where_passed: 'Детский дом №30',
        supermarket: 'Mercato'
      },
      {
        date: '17.08.2016',
        gathered_sum: '6 380 000',
        where_passed: 'Детский дом “Мехрибонлик” №23',
        supermarket: 'Туркменский'
      },
      {
        date: '15.06.2016',
        gathered_sum: '11 421 000',
        where_passed: 'РОСВИ “Мехрибонлик” Учтепинского района',
        supermarket: ''
      },
      {
        date: '14.06.2016',
        gathered_sum: '12 186 300',
        where_passed: 'Общество инвалидов Яшнабадского района',
        supermarket: ''
      },
      {
        date: '13.06.2016',
        gathered_sum: '6 673 000',
        where_passed: '«Sanvikt»',
        supermarket: ''
      },
      {
        date: '09.06.2016',
        gathered_sum: '12 522 500',
        where_passed: '«Status» Инвалиды спинальников',
        supermarket: ''
      },

      {
        date: '08.06.2016',
        gathered_sum: '17 173 700',
        where_passed: '«Лик»',
        supermarket: ''
      },

      {
        date: '18.05.2016',
        gathered_sum: '8 961 800',
        where_passed: 'Вспомогательная школа-интернат №105',
        supermarket: ''
      },

      {
        date: '11.05.2016',
        gathered_sum: '10 578 610',
        where_passed: 'ОО «APLA»',
        supermarket: ''
      },

      {
        date: '20.04.2016',
        gathered_sum: '8 862 500',
        where_passed: 'Общество инвалидов Мирзо-Улугбекского района',
        supermarket: ''
      },

      {
        date: '16.03.2016',
        gathered_sum: '7 647 700',
        where_passed:
          'Узбекское общество инвалидов и дети-инвалиды Алмалыкского района',
        supermarket: ''
      },

      {
        date: '24.02.2016',
        gathered_sum: '10 497 300',
        where_passed:
          'ОДИ «Ногирон –Болалар Камалак уйи». Приобретение лекарственных препаратов и продуктов питания, материальная помощь семьям с детьми инвалидами',
        supermarket: ''
      },

      {
        date: '17.02.2016',
        gathered_sum: '9 589 770',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов «Мехрибонлик» Миробадского района',
        supermarket: ''
      },

      {
        date: '27.01.2016',
        gathered_sum: '14 487 000',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов «Мехрибонлик» Миробадского района',
        supermarket: ''
      },

      {
        date: '20.01.2016',
        gathered_sum: '11 605 000',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов «Мехрибонлик» Миробадского района',
        supermarket: ''
      }
    ],

    year: 'Итого за 2016 г.',
    sum: '188 055 500'
  },

  //  2015 year
  year_of_charity2015: {
    table: [
      {
        date: '23.12.2015',
        gathered_sum: '4 729 520',
        where_passed: 'Вспомогательная школа №36',
        supermarket: 'Аэропорт'
      },
      {
        date: '16.12.2015',
        gathered_sum: '11 940 800',
        where_passed: 'Детский дом №23',
        supermarket: 'Туркменский'
      },
      {
        date: '09.12.2015',
        gathered_sum: '9 878 000',
        where_passed: '«Опа-Сингиллар»',
        supermarket: 'Сергели'
      },
      {
        date: '02.12.2015',
        gathered_sum: '6 852 025',
        where_passed: '«Опа-Сингиллар». Лечение и обследование',
        supermarket: 'Integro'
      },
      {
        date: '25.11.2015',
        gathered_sum: '7 530 800',
        where_passed: '«Мурувват 2». Подгузники',
        supermarket: 'Караташ'
      },
      {
        date: '28.10.2015',
        gathered_sum: '11 652 920',
        where_passed: '«Мурувват 1». Лекарства и Витам. продукты',
        supermarket: 'Mercato'
      },
      {
        date: '04.10.2015',
        gathered_sum: '11 065 150',
        where_passed: 'Янгиюлский Дом Малютки. На мебель',
        supermarket: 'Юнусабад'
      },
      {
        date: '28.08.2015',
        gathered_sum: '9 460 955',
        where_passed: '«Sanvikt». Разв. кн., ремонт отоп. сист. каб.',
        supermarket: 'ТТЗ'
      },
      {
        date: '21.08.2015',
        gathered_sum: '14 904 500',
        where_passed: 'Детский дом №23. Портфели и учебные принадлежности',
        supermarket: 'Туркменский'
      },

      {
        date: '21.08.2015',
        gathered_sum: '7 505 600',
        where_passed: 'Детский дом №30. Учебные принадлежности и ремонт',
        supermarket: 'Ракат'
      },

      {
        date: '31.07.2015',
        gathered_sum: '12 266 200',
        where_passed: '«Umid-Nuri». Лечение и обследование детей',
        supermarket: 'Integro'
      },

      {
        date: '31.07.2015',
        gathered_sum: '14 969 970',
        where_passed: '«Мехрибонлик», ремонт крыши',
        supermarket: 'Абая'
      },

      {
        date: '31.07.2015',
        gathered_sum: '7 588 950',
        where_passed:
          'Бектемирское общество инвалидов. Оказание материальной помощи и прод. подарки',
        supermarket: 'Аэропорт'
      },

      {
        date: '31.07.2015',
        gathered_sum: '11 029 000',
        where_passed:
          'Бектемирское общество инвалидов. Ремонт учебных помещений',
        supermarket: 'Азимова'
      },

      {
        date: '12.06.2015',
        gathered_sum: '9 124 100',
        where_passed: 'Школа №37. Организация детского праздника',
        supermarket: 'Mercato'
      },

      {
        date: '15.05.2015',
        gathered_sum: '6 639 200',
        where_passed:
          'Городская Онкология, Детский отдел. На приобретение твёрдого инвентаря',
        supermarket: 'Караташ'
      },

      {
        date: '01.05.2015',
        gathered_sum: '8 379 500',
        where_passed: 'Общество «Veteran». Материальная помощь семьям погибших',
        supermarket: 'ТТЗ'
      },

      {
        date: '13.03.2015',
        gathered_sum: '10 635 500',
        where_passed: 'OO «APLA». Подарки и продукты к Наврузу',
        supermarket: 'Туркменский'
      },

      {
        date: '05.03.2015',
        gathered_sum: '7 659 500',
        where_passed: '«Sanvikt». На керамическую мастерскую',
        supermarket: 'Сергели'
      },

      {
        date: '12.02.2015',
        gathered_sum: '10 461 595',
        where_passed: 'Центр Культуры Глухих. Работа со слепоглухонемыми',
        supermarket: 'Integro'
      },

      {
        date: '05.02.2015',
        gathered_sum: '7 135 300',
        where_passed: 'Свято-Никол. Монастырь. Помощь нуждающимся',
        supermarket: 'Mercato'
      },

      {
        date: '16.01.2015',
        gathered_sum: '9 031 900',
        where_passed: 'Детский дом №2. Покупка обуви мальчикам',
        supermarket: 'Абай'
      },

      {
        date: '12.01.2015',
        gathered_sum: '7 253 300',
        where_passed:
          'Епархия. Оказание помощи социально уязвимым слоям населения',
        supermarket: 'ТТЗ'
      }
    ],

    year: 'Итого за 2015 г.',
    sum: '217 694 285'
  },

  //  2014 year
  year_of_charity2014: {
    table: [
      {
        date: '30.12.2014',
        gathered_sum: '4 682 500',
        where_passed: 'Серг.общ. «Мехрибон» Орг. Н.Г. и Помощь Лебещак Э.',
        supermarket: ''
      },
      {
        date: '26.11.2014',
        gathered_sum: '4 506 300',
        where_passed:
          'Бектемирское общ-во инвалидов, Организация праздника ко Дню Инвалидов',
        supermarket: ''
      },
      {
        date: '20.11.2014',
        gathered_sum: '7 934 200',
        where_passed: 'Абдулазизова Азиза. Порок сердца. Операция в Германии',
        supermarket: ''
      },
      {
        date: '07.11.2014',
        gathered_sum: '3 785 500',
        where_passed: 'Нуритдинов Самандар. ДЦП. Лечение КБ 5 в Ташкенте',
        supermarket: ''
      },
      {
        date: '31.10.2014',
        gathered_sum: '6 515 300',
        where_passed:
          'Гафуржанов Жахонгир. Острый лейкоз. Лечение в Москве, РДКБ',
        supermarket: 'Азимова'
      },
      {
        date: '24.10.2014',
        gathered_sum: '7 844 700',
        where_passed: 'Шелкунов Саша. Острый лейкоз. Лечение в НИИ Гематологии',
        supermarket: 'Integro'
      },
      {
        date: '10.10.2014',
        gathered_sum: '3 733 500',
        where_passed: 'Иргашева Жамиля. Кома 2. Лечение в России',
        supermarket: 'Ракат'
      },
      {
        date: '08.10.2014',
        gathered_sum: '4 327 400',
        where_passed:
          'Аракельян Карен. Аномалии мочевого пузыря. Операция в Индии',
        supermarket: 'ТТЗ'
      },
      {
        date: '26.09.2014',
        gathered_sum: '4 794 000',
        where_passed: 'Турсунбоев Жавохир. ДЦП после огнестрельного ранения',
        supermarket: 'Сергели'
      },

      {
        date: '11.09.2014',
        gathered_sum: '6 434 700',
        where_passed: 'Таирова Махлиё. Микроцефалия ЗПМР ДЦП. Лечение в России',
        supermarket: 'Mercato'
      },

      {
        date: '10.09.2014',
        gathered_sum: '8 817 200',
        where_passed:
          'Маъмуржановы Абдуллох и Азизбек. Врожденное наследственное ЗПМР ЗПРР. Лечение в Ташкенте',
        supermarket: 'Юнусабад'
      },

      {
        date: '09.09.2014',
        gathered_sum: '5 657 980',
        where_passed: 'Тулягановы Хумоюн и Аббос. Порок сердца',
        supermarket: 'Аэропорт'
      },

      {
        date: '08.09.2014',
        gathered_sum: '4 945 100',
        where_passed: 'Курбанов Тимур. Порок сердца. Операция в Индии',
        supermarket: 'Караташ'
      },

      {
        date: '06.08.2014',
        gathered_sum: '8 960 655',
        where_passed: 'Пардаева Камила. Порок сердца. Операция в Индии',
        supermarket: 'Туркменский'
      },

      {
        date: '05.08.2014',
        gathered_sum: '4 790 200',
        where_passed: 'Малыгин Миша. Острый Лейкоз. Лечение в НИИ Гематологии',
        supermarket: 'ТТЗ'
      },

      {
        date: '04.08.2014',
        gathered_sum: '5 485 050',
        where_passed: 'Эргашов Абдукадыр. ДЦП ЗПРР Лечение в Индии',
        supermarket: 'Абай'
      },

      {
        date: '25.06.2014',
        gathered_sum: '5 035 215',
        where_passed: 'Нажимова Мафтуна. Операция на глаза в Уфе',
        supermarket: 'Mercato'
      },

      {
        date: '24.06.2014',
        gathered_sum: '5 305 400',
        where_passed:
          'Центр Культуры Глухих. Благоустройство помещений работы с инвалидами',
        supermarket: 'Караташ'
      },

      {
        date: '26.05.2014',
        gathered_sum: '5 595 000',
        where_passed: 'Улькина Виктория. На лечение в Ж/Д больнице',
        supermarket: 'Сергели'
      },

      {
        date: '21.05.2014',
        gathered_sum: '5 725 000',
        where_passed:
          '«Sanvikt». На организацию летнего отдыха детей-инвалидов',
        supermarket: 'ТТЗ'
      },

      {
        date: '05.05.2014',
        gathered_sum: '8 485 000',
        where_passed: 'Муротова Умида. Операция на глаза в Уфе',
        supermarket: 'Азимова'
      },

      {
        date: '24.04.2014',
        gathered_sum: '7 925 000',
        where_passed: 'Бахтияров Мирзахид. Лечение в Гематологии',
        supermarket: 'Абай'
      },

      {
        date: '03.04.2014',
        gathered_sum: '8 525 000',
        where_passed: 'Театр «Лик» Помощь в теат. раб. с инвал',
        supermarket: 'Туркменский'
      },

      {
        date: '02.04.2014',
        gathered_sum: '4 145 000',
        where_passed: 'Музафарова Мафтуна. Лейкоз. Лечение в Москве',
        supermarket: 'Ракат'
      },

      {
        date: '26.03.2014',
        gathered_sum: '4 233 100',
        where_passed: 'Эргашева Сожидахон. ДЦП. Операция за границей',
        supermarket: 'Mercato'
      },

      {
        date: '14.03.2014',
        gathered_sum: '7 360 000',
        where_passed: 'Янгиюльский Дом Малютки. На мебель',
        supermarket: 'Аэропорт'
      },

      {
        date: '11.03.2014',
        gathered_sum: '5 462 500',
        where_passed: 'Нурмаев Григорий. Операция в Индии',
        supermarket: 'Караташ'
      },

      {
        date: '24.01.2014',
        gathered_sum: '5 655 000',
        where_passed: 'Шарипов Абдулазиз. Лечение в онкологии',
        supermarket: 'Юнусабад'
      },

      {
        date: '23.01.2014',
        gathered_sum: '3 455 000',
        where_passed: 'Юсупова Мохира. ДЦП. Лечение в Индии',
        supermarket: 'Mercato'
      },

      {
        date: '16.01.2014',
        gathered_sum: '6 691 500',
        where_passed: 'Занозин Арсений. Сморщив-е почки. На операцию',
        supermarket: 'Сергели'
      }
    ],

    year: 'Итого за 2014 г.',
    sum: '176 812 000'
  },

  // 2013 year
  year_of_charity2013: {
    table: [
      {
        date: '20.12.2013',
        gathered_sum: '7 710 000',
        where_passed: '«Мурувват 1» на лекарства, витамины и продовольствие',
        supermarket: 'Туркменский'
      },
      {
        date: '13.12.2013',
        gathered_sum: '7 826 240',
        where_passed: 'Камилов Мурод. Операция на сердце в Индии',
        supermarket: 'Абай'
      },
      {
        date: '29.11.2013',
        gathered_sum: '3 423 000',
        where_passed: 'Тухтамурадов Сирож. Операция на сердце в Индии',
        supermarket: 'Mercato'
      },
      {
        date: '05.11.2013',
        gathered_sum: '5 344 990',
        where_passed: 'Аракельян Карен. Трансплантология почки',
        supermarket: 'Караташ'
      },
      {
        date: '24.10.2013',
        gathered_sum: '6 496 000',
        where_passed: 'Кит Дарья. Лечение печени в Москве',
        supermarket: 'Азимова'
      },
      {
        date: '23.09.2013',
        gathered_sum: '5 114 200',
        where_passed: 'Аноним. Операция в Индии',
        supermarket: 'Mercato'
      },
      {
        date: '19.09.2013',
        gathered_sum: '8 060 000',
        where_passed: 'Сергелийское общество инвалидов. К Курбан-хайиту',
        supermarket: 'Аэропорт'
      },
      {
        date: '13.09.2013',
        gathered_sum: '4 051 300',
        where_passed: '«Sanvikt» на ремонт помещений для занятий',
        supermarket: 'Юнусабад'
      },
      {
        date: '13.08.2013',
        gathered_sum: '3 780 000',
        where_passed: 'Бектемирское общество инвалидов. Ко Дню Независимости',
        supermarket: 'Ракат'
      },

      {
        date: '26.07.2013',
        gathered_sum: '3 892 000',
        where_passed: 'Аноним',
        supermarket: 'Караташ'
      },

      {
        date: '25.07.2013',
        gathered_sum: '6 793 000',
        where_passed: 'Фарходова Шахризода. Опухоль мозга – Индия',
        supermarket: 'Абай'
      },

      {
        date: '23.07.2013',
        gathered_sum: '4 827 000',
        where_passed: 'Фаткулина Сабрина. Операция за границей',
        supermarket: 'Туркменский'
      },

      {
        date: '05.07.2013',
        gathered_sum: '5 089 950',
        where_passed: 'Театр «Лик». На аренду и образ. прогр',
        supermarket: 'Mercato'
      },

      {
        date: '10.06.2013',
        gathered_sum: '5 533 695',
        where_passed: 'Городское общество «УМР», на День Защиты детей',
        supermarket: 'Сергели'
      },

      {
        date: '17.05.2013',
        gathered_sum: '4 451 600',
        where_passed:
          'Алмазарское отделение инвалидов. Материальная помощь малоимущим',
        supermarket: 'Караташ'
      },

      {
        date: '16.05.2013',
        gathered_sum: '6 265 200',
        where_passed: 'Тулкинжинов Фаррух. Операция Имплантат за границей',
        supermarket: 'Туркменский'
      },

      {
        date: '17.04.2013',
        gathered_sum: '4 836 295',
        where_passed: 'Аноним',
        supermarket: 'Азимова'
      },

      {
        date: '16.04.2013',
        gathered_sum: '3 149 900',
        where_passed: 'Аноним',
        supermarket: 'Mercato'
      },

      {
        date: '15.03.2013',
        gathered_sum: '4 553 025',
        where_passed: 'Амели Джовидон. Операция в Японии',
        supermarket: 'Абай'
      },

      {
        date: '12.03.2013',
        gathered_sum: '6 278 725',
        where_passed:
          'Городской Онкологический диспансер. На лекарственные средства для детского отделения',
        supermarket: 'Аэропорт'
      },

      {
        date: '27.02.2013',
        gathered_sum: '5 052 500',
        where_passed: 'НИИ Гематологии и Переливания крови',
        supermarket: 'Караташ'
      },

      {
        date: '19.02.2013',
        gathered_sum: '5 630 400',
        where_passed: 'Махалля «Чоштепа»',
        supermarket: 'Туркменский'
      },

      {
        date: '08.02.2013',
        gathered_sum: '5 660 450',
        where_passed: 'Детский дом «Мурувват 2»',
        supermarket: 'Mercato'
      },

      {
        date: '31.01.2013',
        gathered_sum: '6 252 440',
        where_passed: 'Саидова Муштарийбону',
        supermarket: 'Сергели'
      }
    ],

    year: 'Итого за 2013 г.',
    sum: '130 071 910'
  },

  // 2012 year
  year_of_charity2012: {
    table: [
      {
        date: '19.11.2012',
        gathered_sum: '3 728 565',
        where_passed: 'Общество глухих',
        supermarket: 'Абай'
      },
      {
        date: '16.11.2012',
        gathered_sum: '3 248 550',
        where_passed: 'Детский дом «Мурувват 1»',
        supermarket: 'Караташ'
      },
      {
        date: '30.10.2012',
        gathered_sum: '5 194 235',
        where_passed: 'Театр «Лик»',
        supermarket: ''
      },
      {
        date: '02.10.2012',
        gathered_sum: '4 607 425',
        where_passed: 'Школа-интернат №98. Для малообеспеченных',
        supermarket: 'Аэропорт'
      },
      {
        date: '21.09.2012',
        gathered_sum: '5 297 100',
        where_passed: 'Детский дом №31',
        supermarket: 'Mercato'
      },
      {
        date: '10.08.2012',
        gathered_sum: '2 978 950',
        where_passed: 'Алмазарский филиал городского Общества инвалидов',
        supermarket: 'Абай'
      },
      {
        date: '26.07.2012',
        gathered_sum: '1 943 800',
        where_passed: 'Детский дом №30',
        supermarket: 'Караташ'
      },
      {
        date: '24.07.2012',
        gathered_sum: '3 373 040',
        where_passed: 'Бектемирское отделение общества инвалидов',
        supermarket: ''
      },
      {
        date: '24.05.2012',
        gathered_sum: '1 469 830',
        where_passed: 'Марин Алексей',
        supermarket: 'Караташ'
      },

      {
        date: '16.05.2012',
        gathered_sum: '3 276 330',
        where_passed: 'Детская Площадка Детского дома №14',
        supermarket: ''
      },

      {
        date: '15.05.2012',
        gathered_sum: '2 487 350',
        where_passed: 'Детская Площадка Детского дома №14',
        supermarket: 'Абай'
      },

      {
        date: '27.04.2012',
        gathered_sum: '5 662 105',
        where_passed: 'Детский дом №15 г.Бука',
        supermarket: 'Mercato'
      },

      {
        date: '19.04.2012',
        gathered_sum: '3 623 700',
        where_passed: '«Sanvikt»',
        supermarket: 'Аэропорт'
      },

      {
        date: '30.03.2012',
        gathered_sum: '2 474 330',
        where_passed:
          'Детское отделение Республиканского Онкологического научного центра',
        supermarket: 'Караташ'
      },

      {
        date: '16.03.2012',
        gathered_sum: '2 900 400',
        where_passed: 'Дом Ребенка «Кичкинтой» г.Янгиюль',
        supermarket: ''
      },

      {
        date: '02.02.2012',
        gathered_sum: '4 208 450',
        where_passed: 'Детский дом №14 г.Чирчик',
        supermarket: 'Абай'
      },

      {
        date: '20.01.2012',
        gathered_sum: '2 785 105',
        where_passed: 'Республиканская центральная библиотека для слепых',
        supermarket: ''
      }
    ],

    year: 'Итого за 2012 г.',
    sum: '59 259 265'
  },

  // 2011 year
  year_of_charity2011: {
    table: [
      {
        date: '23.12.2011',
        gathered_sum: '2 164 400',
        where_passed: 'Детское отделение Городского Онкологического Диспансера',
        supermarket: 'Караташ'
      },
      {
        date: '09.12.2011',
        gathered_sum: '2 565 445',
        where_passed: 'Об-во социал. Взаимопомощи инв-в «Мехрибонлик»',
        supermarket: 'Аэропорт'
      },
      {
        date: '25.11.2011',
        gathered_sum: '3 000 950',
        where_passed: 'Городское отделение Узбекского общества Инвалидов',
        supermarket: ''
      },
      {
        date: '04.11.2011',
        gathered_sum: '2 751 725',
        where_passed: 'Общество глухих',
        supermarket: 'Абай'
      },
      {
        date: '30.09.2011',
        gathered_sum: '2 348 455',
        where_passed: 'Интернат для слабослышащих №102',
        supermarket: 'Караташ'
      },
      {
        date: '16.09.2011',
        gathered_sum: '2 498 410',
        where_passed: '«Дом Малютки»',
        supermarket: ''
      },
      {
        date: '28.07.2011',
        gathered_sum: '1 790 170',
        where_passed: '«Sanvikt»',
        supermarket: 'Караташ'
      },
      {
        date: '27.07.2011',
        gathered_sum: '1 913 700',
        where_passed: 'Детский дом «Мурувват 1»',
        supermarket: 'Абай'
      },
      {
        date: '26.07.2011',
        gathered_sum: '2 853 845',
        where_passed: 'Детский дом «Мурувват 2»',
        supermarket: ''
      },

      {
        date: '10.06.2011',
        gathered_sum: '2 455 490',
        where_passed: 'Бектемирский филиал общества Инвалидов',
        supermarket: 'Аэропорт'
      },

      {
        date: '20.05.2011',
        gathered_sum: '1 816 255',
        where_passed: '«Умидварлик»',
        supermarket: ''
      },

      {
        date: '06.05.2011',
        gathered_sum: '2 281 350',
        where_passed: 'Вспомогательная школа №37',
        supermarket: 'Абай'
      },

      {
        date: '08.04.2011',
        gathered_sum: '2 714 760',
        where_passed: 'Театр «Лик»',
        supermarket: ''
      },

      {
        date: '04.03.2011',
        gathered_sum: '2 924 205',
        where_passed: 'Общество «SOS Деревня»',
        supermarket: ''
      },

      {
        date: '18.02.2011',
        gathered_sum: '2 751 515',
        where_passed: 'ОО «APLA»',
        supermarket: 'Аэропорт'
      },

      {
        date: '04.01.2011',
        gathered_sum: '2 101 040',
        where_passed: '«Sanvikt»',
        supermarket: ''
      }
    ],

    year: 'Итого за 2011 г.',
    sum: '	38 931 715'
  },

  // 2010 year
  year_of_charity2010: {
    table: [
      {
        date: '11.12.2010',
        gathered_sum: '2 322 360',
        where_passed: 'Дет.ПНБ.',
        supermarket: ''
      },
      {
        date: '08.10.2010',
        gathered_sum: '2 000 600',
        where_passed: '5 ГКДБ',
        supermarket: 'Аэропорт'
      },
      {
        date: '24.09.2010',
        gathered_sum: '2 401 600',
        where_passed: 'Детский дом №23',
        supermarket: ''
      },
      {
        date: '05.08.2010',
        gathered_sum: '1 627 730',
        where_passed: 'НИИ Гематологии',
        supermarket: ''
      },
      {
        date: '15.06.2010',
        gathered_sum: '1 918 010',
        where_passed: 'Детский дом №22',
        supermarket: ''
      },
      {
        date: '27.04.2010',
        gathered_sum: '1 919 430',
        where_passed: 'Детский дом «Мурувват 2»',
        supermarket: ''
      },
      {
        date: '22.02.2010',
        gathered_sum: '1 533 100',
        where_passed: 'Детский дом «Мурувват 1»',
        supermarket: ''
      }
    ],

    year: 'Итого за 2010 г.',
    sum: '13 722 830'
  },

  // 2009 year
  year_of_charity2009: {
    table: [
      {
        date: '18.12.2009',
        gathered_sum: '1 130 800',
        where_passed: 'Детский дом №30',
        supermarket: ''
      },
      {
        date: '23.10.2009',
        gathered_sum: '1 000 085',
        where_passed: 'Детский дом №31',
        supermarket: ''
      },
      {
        date: '20.05.2009',
        gathered_sum: '1 573 125',
        where_passed: 'Детский дом №21',
        supermarket: ''
      },
      {
        date: '23.01.2009',
        gathered_sum: '970 350',
        where_passed: 'Детский дом №24',
        supermarket: ''
      }
    ],

    year: 'Итого за 2009 г.',
    sum: '4 674 360'
  },

  // 2008 year
  year_of_charity2008: {
    table: [
      {
        date: '14.11.2008',
        gathered_sum: '773 335',
        where_passed: '«Дом Малютки»',
        supermarket: ''
      }
    ],

    year: 'Итого за 2008 г.',
    sum: '773 335'
  },
  faq_title: "Ko'p so'raladigan savollar",
  faq_loyalty_title: 'Bu qanday ishlaydi:',
  share: 'Ulashmoq',
  // footer_disclamer: "Saytdagi ma’lumot tanishib chiqish uchun berilgan va ommaviy oferta hisoblanmaydi. Ma’lumot o‘z ichiga quyidagilarni olishi yoki olmasligi mumkin:<br /><br />— Tovarlar narxi so‘mda ko‘rsatilgan.<br />— Barcha takliflar faqat Korzinka supermarketlarida amal qiladi.<br />— Reklama qilinayotgan tovarning tashqi ko‘rinishi do‘konda taqdim etilganidan farq qilishi mumkin.<br />— Do‘kondagi tovarlar narxi reklama materiallarida belgilanganidan arzon bo‘lishi mumkin.<br />— Aksiya doirasidagi tovar miqdori cheklangan bo‘lishi mumkin.<br />— Aksiya mazkur tovarlar bo‘yicha boshqa takliflar bilan qo‘shib hisoblanmaydi.<br />— Sovg‘a sertifikati, shuningdek, yig‘ilgan bonuslar bilan to‘lovda Korzinka Plus kartasiga keshbek tushmaydi.<br />— Narxlar va tovarlar assortimenti Korzinka DISKONT do‘konlaridagi narx va tovarlar assortimentiga to‘g‘ri kelmasligi mumkin.<br />— Barcha tovarlar sertifikatlangan.",
  footer_disclamer: `
    <p>Saytdagi ma’lumot tanishib chiqish uchun berilgan va ommaviy oferta hisoblanmaydi. Ma’lumot o‘z ichiga quyidagilarni olishi yoki olmasligi mumkin:</p>
    <br />
    <p>•&nbsp;&nbsp; Tovarlar narxi so‘mda ko‘rsatilgan.</p>
    <p>•&nbsp;&nbsp; Barcha takliflar faqat Korzinka supermarketlarida amal qiladi.</p>
    <p>•&nbsp;&nbsp; Reklama qilinayotgan tovarning tashqi ko‘rinishi do‘konda taqdim etilganidan farq qilishi mumkin.</p>
    <p>•&nbsp;&nbsp; Do‘kondagi tovarlar narxi reklama materiallarida belgilanganidan arzon bo‘lishi mumkin.</p>
    <p>•&nbsp;&nbsp; Aksiya doirasidagi tovar miqdori cheklangan bo‘lishi mumkin.</p>
    <p>•&nbsp;&nbsp; Aksiya mazkur tovarlar bo‘yicha boshqa takliflar bilan qo‘shib hisoblanmaydi.</p>
    <p>•&nbsp;&nbsp; Sovg‘a sertifikati, shuningdek, yig‘ilgan bonuslar bilan to‘lovda Korzinka Plus kartasiga keshbek tushmaydi.</p>
    <p>•&nbsp;&nbsp; Narxlar va tovarlar assortimenti Korzinka DISKONT do‘konlaridagi narx va tovarlar assortimentiga to‘g‘ri kelmasligi mumkin.</p>
    <p>•&nbsp;&nbsp; Barcha tovarlar sertifikatlangan.</p>
  `,
  ctm_iz_korzinki_text: 'Premium Sifat',
  ctm_365kun_text: 'Mavjudlik va sifat',
  cashback_tag_label: 'cashback',
  novelty_tag_label: 'yangi',
  selfmade_tag_label: 'biz tomonidan yaratilgan',
  discount_by_card: 'kartasi orqali',
  coupon_checker_button_label: 'Ishtirokni tekshiring',
  coupon_checker_input_label: 'Loyalnost karta raqamingizni kiriting',
  coupon_checker_schedules_title: 'Sizda',
  coupon_checker_number: 'Ishtirok raqami',
  chances_title: 'imkoniyat',
  chances_title_2: 'imkoniyatlar',
  chances_title_3: 'imkoniyatlar',
  chances_error:
    'Siz hali aksiyada qatnashmayotgan koʻrinasiz yoki ma`lumotlaringiz yangilanmagan. Xarid qilganingizdan keyin 60 daqiqadan ko‘proq o‘tgan bo‘lsa — biz bilan <a href="tel:+998781401414">+998 (78) 140-14-14</a> raqami orqali bog‘laning',
  // spec-catalog footer content
  spec_catalog_footer: `
     <h2>Sizga mehr bilan: biz kundalik xaridlar uchun eng arzon narxlarni taklif etamiz!</h2>
<p>&nbsp;</p>
<p>Biz Korzinkalarimizda xaridlaringiz doim unumli, jarayonning o&lsquo;zi esa &ndash; qulay va yoqimli bo&lsquo;lishiga doim intilamiz.</p>
<p>Ushbu tashabbusimiz davomi sifatida biz &ldquo;Eng arzon narx&rdquo; aksiyasini boshladik. Uning doirasida biz mehmonlarimizga kundalik xaridlar uchun tovarlardan tuzilgan savatga eng arzon narxlarni taklif etamiz. Buning uchun biz doimiy ravishda Toshkent va Toshkent viloyatlari supermarket hamda bozorlari orasida narx monitoringini o&lsquo;tkazib boramiz:</p>
<p>&ndash;&nbsp;&ldquo;Darvoza Savdo&rdquo; MChJ (Makro)</p>
<p>&ndash;&nbsp;&ldquo;Havas Food&rdquo; MChJ QK (Havas)</p>
<p>&ndash;&nbsp;&ldquo;Urban Retail&rdquo; MChJ QK (Baraka)</p>
<p>&ndash;&nbsp;&ldquo;Magnum Retail&rdquo; MChJ XK (Magnum)</p>
<p>&ndash;&nbsp;&ldquo;NEW RETAIL&rdquo; MChJ (bi1)</p>
<p>&ndash;&nbsp;&ldquo;Магнит Средняя Азия&rdquo; MChJ XK (M COSMETIC)</p>
<p>&ndash;&nbsp;&ldquo;Xalq retail&rdquo; MChJ (Olma)</p>
<p>&ndash; Farhod bozori</p>
<p>&ndash; Yunusobod dehqon bozori</p>
<p>&ndash; Chorsu bozor</p>
<p>&nbsp;</p>
<p>Narxlarni taqqoslash uchun Korzinkada taqdim etilgan, brendi, modeli, navi, kalibri, o&lsquo;lchami, sifati, ta&rsquo;mi va rangi bo&lsquo;yicha o&lsquo;xshash mahsulotlar olindi. Biz har hafta ularning narxini kuzatib boramiz, aksiyada ishtirok etayotgan mahsulotlarning har biri &ldquo;Eng arzon narx&rdquo; reklama materiali va narxnomasi bilan belgilangn. Taqqoslash uchun har bir tovarning alohida emas, balki savatning umumiy narxi olinadi. Monitoringda chegirma va aksiyadagi narxlar nazarda tutilmaydi.</p>
<p>&ldquo;Eng arzon narx&rdquo; aksiyasi tarmoqning barcha supermarketlarida, jumladan Korzinka, Korzinka Mahalla, Korzinka Diskont, Smart formatlari va Korzinka Go onlayn xizmatida amal qilmoqda. Maxsus narxlar
Toshkent shahri va Toshkent viloyatidan tashqarida xarid qilingan tovarlar uchun amal qilmaydi. Biz yaqin vaqt ichida aksiyani butun O&lsquo;zbekiston Respublikasi bo&lsquo;ylab taqdim etishni ustida ishlayapmiz.</p>
<p>Aksiyani o&lsquo;tkazish muddati: 2024-yil 31-dekabr oxirigacha (uzaytirish imkoniyatini saqlab qolgan holda).</p>
<p>Ushbu aksiya faqat Anglesey Food MChJ kompaniyasining ixtiyoriga ko&lsquo;ra amal qilayotgan bo&lsquo;lib, oldindan ogohlantirishsiz istalgan vaqtda o&lsquo;zgartirilishi mumkin. Korzinka tekshirish imkoni bo&lsquo;lmagan yoki siyosatimiz doirasidan tashqarida bo&lsquo;lgan narxlarni o&lsquo;zgartirishni rad etish huquqini o&lsquo;zida saqlab qoladi. Qo&lsquo;shimcha savollarga javob olish uchun, iltimos, kassirga, do&lsquo;kon menejeriga murojaat qiling yoki mijozlar bilan ishlash mutaxassislarimiz bilan ushbu raqam orqali bog&lsquo;laning: +998 (78) 140-14-14.</p>
<p>* Katalogdagi tovarlar narxi so&lsquo;mda ko&lsquo;rsatilgan.</p>
<p>* Ba&rsquo;zi tovarlar narxi katalogda ko&lsquo;rsatilganidan ko&lsquo;ra arzon bo&lsquo;lishi mumkin.</p>
<p>* &ldquo;Eng arzon narx&rdquo; aksiyasida ishtirok etayotgan tovarlar ro&lsquo;yxati Anglesey Food MChJ kompaniyasi qaroriga ko&lsquo;ra istalgan vaqtda ogohlantirishsiz o&lsquo;zgartirilishi mumkin.</p>
<p>* Korzinka Plus karta dasturi bo&lsquo;yicha bonuslar &ldquo;Eng arzon narx&rdquo; tovarlari uchun hisoblanmaydi.</p>
`,
  // sales
  sales_text: `Siz uchun <br/> takliflar`
}
