export default {
  special_catalog_disclaimer: `
    * Количество товара ограничено<br />
    * Скидки на говядину распространяются на всю сеть супермаркетов Корзинка, за исключением филиалов Корзинка DISKONT
  `,
  special_catalog_disclaimer2: `
    * При покупке 2 одинаковых видов мороженого вы получаете третье такое же в подарок<br />
    * Акция действует с 22 июня по 23 июня
  `,
  hard_title: `Супер цены этой недели`,
  read_more: 'Читать далее',
  byd_banner_title: 'Выиграйте <br /> BYD Song Plus',
  byd_banner_description:
    'Оплатите товары на 200 000 сум <br /> и используйте карту лояльности',
  byd_banner_link:
    'https://korzinka.uz/news/publichnaya-oferta-pravila-provedeniya-reklamnoy-aktsii-vyigrayte-byd-song-plus-za-pokupku-v-korzink?id=1857',
  ibt: 'Политика в области ИСМ',
  ibt_link: '/ibt_ru.pdf',
  rent: 'Арендодателям',
  up_to: 'до',
  location_empty: 'Проверьте правильность введенных данных и повторите попытку',
  // Discle
  discle: `
    <ul>
      <li>* Стоимость товаров в каталоге указана в сумах</li>
      <li>* Предложения каталога распространяются только на сеть супермаркетов korzinka.</li>
      <li>* Цены и ассортимент товаров в каталоге могут не совпадать с ценами и ассортиментом товаров в магазинах korzinka DISKONT, korzinka Mahalla и Smart.</li>
      <li>* В регионах акция действует на куриные яйца от местных поставщиков.</li>
      <li>* Количество акционного товара ограничено.</li>
      <li>* Стоимость некоторых товаров может быть ниже, чем указано в каталоге</li>
    </ul>
  `,
  // Permanent promotion
  permanent_promotion: {
    block_heading: 'Постоянная акция',
    block_description_1: 'Несоответствие цены',
    block_description_2: 'Товар с истекшим сроком годности'
  },

  billione_short: 'млрд.',

  // Staff
  staff: {
    title: 'Мы одна семья',
    tab_1: 'Все',
    tab_2: 'Наблюдательный совет',
    tab_3: 'Высшее руководство Корзинки'
  },
  // Search tools help products
  search_tools_help: {
    title: 'Что купить в Корзинке?',
    products: [
      {
        product: 'Говядина'
      },
      {
        product: 'Бананы'
      },
      {
        product: 'Помидоры'
      },
      {
        product: 'Мясо курицы'
      },
      {
        product: 'Масло подсолнечное'
      },
      {
        product: 'Напиток'
      },
      {
        product: 'Яблоки'
      },
      {
        product: 'Смеси детские'
      },
      {
        product: 'Яйца'
      },
      {
        product: 'Баранина'
      }
    ]
  },

  // Cookie
  cookie: {
    text: 'Мы используем cookie-файлы, чтобы пользоваться сайтом было удобно',
    done: 'Хорошо'
  },

  main: 'Главная',
  charity: 'Благотворительность',
  loading: 'Загружаем самое вкусное...',
  //  Header menu
  catalog: 'Каталог',
  loyalty: 'Лояльность',
  career: 'Карьера',
  about: 'О нас',
  stores: 'Магазины',
  suppliers: 'Поставщикам',
  news: 'Новости',
  use_brand: 'Использование материалов',
  feedback: 'Обратная связь',
  still: 'Ещё',
  best_offer: 'Лучшие предложения',
  week_offer: `Супер цены этой недели`,
  all_products: 'Все предложения',
  top10Products: 'ТОП-10 товаров',
  // Header menu

  back: 'Назад',
  back_to_catalog: 'Назад в каталог',
  read_also: 'Читайте также',

  // Main page
  // Main_page news
  all_news: 'Все новости',
  // Main_page news

  // Main_page work section
  work: {
    info: 'Сеть супермаркетов «Корзинка» — процветающая компания',
    title: 'Давайте <br /> работать вместе',
    detail: 'Подробнее'
  },
  // Main_page work section

  // Main-page banners
  // Main-page banners

  // Main page and Loyality page - mobile application section
  mobile: {
    title_1: 'Мы всегда',
    title_2: 'под рукой',
    description_1: 'Дарим 5 000 Salom-бонусов',
    description_2: 'за регистрацию новой',
    description_3: 'карты в приложении'
  },
  // Main page and Loyality page - mobile application section
  // Main page

  //  Loyality page
  // "How to get the points" section
  get_points: {
    title_1: 'Предъявляйте карту лояльности',
    description_1: 'Совершайте <br />покупки <br />в Корзинке',
    title_2: 'Получайте бонусы',
    description_2: '1% за каждую <br />покупку',
    title_3: 'Суммируются все покупки',
    description_3: '1% сверху при <br />трате от 1.3 млн <br />в месяц',
    title_4: 'Суммируются все покупки',
    description_4: '1% сверху при <br />трате от 4.5 млн <br />в квартал',
    title_5: 'При оплате через мобильное приложение',
    description_5: '+0,5% на все <br />покупки',
    title_6: 'Бонусы хранятся 12 месяцев',
    description_6: 'Тратьте бонусы <br />на покупки'
  },
  // "How to get the points" - section

  // "Earn points faster" - section
  earn_points: {
    title: 'Зарабатывайте бонусы быстрее',
    description:
      'Ходите в Корзинку всей семьей! Мы подарим вам 4 карты с единым накопительным счетом, и вы сможете зарабатывать бонусы быстрее.'
  },
  // "Earn points faster" - section

  loyalty_card_title: 'Карты всегда под рукой',
  loyalty_card_description: 'Дарим 5000 бонусов за установку приложения',
  loyalty_get_card_title: 'Как получить карту',
  about_app: 'О приложении',
  download_app: 'Скачать приложение',

  cashback: {
    get_ball: 'Как получить бонусы'
  },

  // Questions and answers section
  // Questions and answers section
  // Loyality page

  // About us - page

  testShop: 'нет магазинов | {n} магазин | {n} магазина | {n} магазинов',
  about_us: {
    history_title: '28 лет опыта и веры сделали нас лидерами рынка',
    comments_title: 'Помним все теплые слова, что вы нам сказали',
    shops_number_desc:
      '<span> {label} в Узбекистане </span> <br /> Узнайте, какой  формат идеально подходит вам',
    charity_title: 'Собрали на благотворительность',
    charity_description: `
      С 2008 года в супермаркетах Korzinka стали устанавливаться Боксы милосердия. Желающие могут пожертвовать любую, пусть даже небольшую, сумму денег на
      благотворительные цели.
        `,
    charity_button: 'Узнать подробнее',
    currency: 'сум'
  },
  // About us - page

  //  Catalogs
  spec_offer: 'Спецпредложения',
  buy: 'Купить',
  lebazar: 'На Lebazar',
  korzinka: 'На Korzinka Go',
  //  Catalogs

  // Shops
  shops: {
    search_input: 'Введите адрес или название',
    search_settings: 'Настройки поиска',
    shops_category: 'Категория магазина',
    korzinka_descr: 'сеть супермаркетов',
    smart_descr: 'магазины у дома',
    redtag_descr: 'Дубайский бренд одежды для всей семьи',
    flo_descr: 'сеть магазинов турецкой обуви',
    services_title: 'Сервисы',
    regions_title: 'Города и области',

    address: 'Адрес',
    orient: 'Ориентир',
    work_time: 'Режим работы',
    phone: 'Телефон',

    parking: 'Парковка'
  },
  // Shops

  // News
  page_news: {
    title: 'Что нового в Корзинке?',
    more: 'Ещё новости'
  },
  // News

  // Materials guide page
  brand_use: {
    intro_title: 'Руководство по использованию нашего логотипа',
    intro_description: `
      На этой страничке вы можете скачать логотипы сети супермаркетов «Корзинка» в удобном для вас формате.<br ><br />

      Использование товарных знаков возможно только с письменного разрешения отдела маркетинга ИП ООО «Anglesey Food». Для получения разрешения на использование товарного знака свяжитесь с нами по электронной почте: info@korzinka.uz.
      Товарные знаки защищаются законодательством Республики Узбекистан.
    `,
    logo_block_title: 'Логоблок',
    logo_block_descr:
      'Это композиционный блок из логомарка и вордмарка. У нас есть два горизонтальных логоблока и один вертикальный.',
    logo_block_descr_sup1: 'Основной горизонтальный логоблок',
    logo_block_descr_sup2: 'Дополнительный горизонтальный логоблок',
    logo_block_descr_sup3: 'Вертикальный логоблок',

    logo_mark_title: 'Логомарк',
    logo_mark_descr:
      'Логомарк/знак korzinka существует в двух вариациях: без овала (основной) и с овалом (дополнительный). Используется в случае, если нет возможности использовать логоблок',
    logo_mark_descr_sup1: 'Основной логомарк',
    logo_mark_descr_sup2:
      'Охранная зона логомарка равна половине высоты знака — h',
    logo_mark_descr_sup3:
      ' На светлом цветном фоне основной<br> логомарк всегда красного цвета',
    logo_mark_descr_sup4: 'Дополнительный логомарк',
    logo_mark_descr_sup5:
      'Охранная зона логомарка равна половине высоты знака — h',
    logo_mark_descr_sup6:
      'На светлом цветном фоне основной логомарк всегда красного цвета',
    logo_mark_descr_sup7:
      'На светлом фоне вордмарк/логомарк всегда красного цвета',
    logo_mark_descr_sup8:
      'На темном фоне вордмарк/логомарк всегда белого цвета',
    logo_mark_descr_sup9:
      'На красном фоне основной <br /> логомарк всегда белого цвета',

    vektor: 'вектор',
    download: 'скачать',
    guideline_download: 'скачать руководство'
  },
  // Materials guide page

  //  Feedback page strings
  feedback_page: {
    title: 'Cтановимся всё лучше и лучше для вас',
    question: 'У вас есть вопрос? Свяжитесь с нами удобным способом',
    use_brand: 'Информация по использованию логотипов находится',
    here: 'здесь',

    address_title: 'адрес',
    address: 'Ташкент, Яккасарайский р-н, ул. Кичик Халка йули, 120,',
    index: 'индекс: 100015',

    phone_title: 'телефон',
    requisites_title: 'Реквизиты',
    requisites: `
    ИП ООО «ANGLESEY FOOD»<br>
    Юр. адрес: 100066, г. Ташкент, ул. Туроб Тула, д. 57. Территория базара Беш Ёгоч<br><br>
    Центральный офис: 100015, г. Ташкент, ул. Кичик халка йули, д. 120<br><br>
    Р/c: 2020 8000 6005 7890 2001<br>
    Академический центр банковских услуг АО «Узнацбанк»<br>
    МФО: 00450<br>
    ИНН: 202 099 756<br>
    ОКЭД: 47110<br>
    Рег. код плательщика НДС: 320060002860 `,

    feedback_title: ' Или расскажите нам всё, как есть, прямо тут ',
    request_type: 'Тип обращения*',
    request_type1: 'Вопрос',
    request_type2: 'Жалоба',
    request_type3: 'Предложение',
    request_type4: 'Благодарность',
    request_type5: 'Другое',

    project_type: 'Бренд*',
    project_type1: 'Вопрос не связан с маркетом',
    project_type2: 'Korzinka',
    project_type3: 'REDTAG',
    project_type4: 'Smart',
    project_type5: 'FLO',

    name_request: 'Ваше имя*',
    phone_request: 'Телефон*',
    email_request: 'email',
    message_request: 'Ваш отзыв*',
    message_request1: 'Добавьте комментарий',
    sybmols: ' символов',
    attachment_request: 'Прикрепить фотографию',
    attachment_request1: 'Прикрепить файл*',
    send_request: 'Отправить'
  },
  //  Feedbackpage strings

  // footer
  hotline: 'Колл - центр',
  privacy_policy: 'Политика конфиденциальности',
  design: 'Дизайн — ',
  slogan: 'Всё лучше и лучше',
  // footer

  charity_title: 'Информация о средствах из боксов милосердия',

  charity_obj: {
    date: 'Дата',
    gathered_sum: 'Собранно сум',
    where_passed: 'Куда передали',
    supermarket: 'Супермаркет'
  },

  // search tools
  search: 'Найти',
  search_placeholder: 'Найти в Корзинке...',

  search_tabs: {
    products: 'Товары',
    top10: 'ТОП-10',
    specOffer: 'Специальные предложения',
    news: 'Новости',
    stores: 'Магазины'
  },

  empty_result_title: 'По вашему запросу ничего не найдено',
  empty_result_description: 'Но вы можете посмотреть наши лучшие предложения',
  in_catalog: 'В каталог',

  error_title: 'Страница не найдена',
  error_text: 'Но мы можем кое-что вам предложить',

  telegram_link: 'https://t.me/korzinkauz',

  kodeks: 'Кодекс деловой этики',
  kodeks_title: 'КОДЕКС ДЕЛОВОЙ ЭТИКИ ИП ООО «Anglesey Food»',
  kodeks_description: `
  <div class="postPage">
  <div class="postContent">

    <p>Содержание</p>
    <ul>
      <li>
        <b>1.</b>
				Цели и задачи Кодекса
      </li>
      <li>
        <b>2.</b>
        Область применения
      </li>
      <li>
        <b>3.</b>
        Миссия и Видение компании
      </li>
      <li>
        <b>4.</b>
        Корпоративные ценности
      </li>
      <li>
        <b>5.</b>
        Базовые этические принципы
      </li>
      <li>
        <b>6.</b>
        Деловой этикет
      </li>
      <li>
        <b>7.</b>
        Конфиденциальность информации
      </li>
      <li>
        <b>8.</b>
        Хранение
      </li>
    </ul>
		<br/><br/>
		<p>Статья 1</p>
		<br/>
		<p>Цели и задачи Кодекса</p>
		<br/>
    <ul>
      <li>
				ИП ООО «Anglesey Food» (далее Компания) стремится к честным, надежным и справедливым взаимоотношениям со всеми заинтересованными сторонами: работниками, клиентами, партнерами, государственными органами. Кодекс деловой этики (далее Кодекс) определяет этические принципы и ценности Компании, поддерживающие корпоративную культуру, которая будет способствовать выстраиванию таких взаимоотношений.
				<br/>
				<br/>
				Кодекс должен помочь работникам принимать правильные решения, соответствующие ценностям и этическим принципам компании, в сложных ситуациях, когда отсутствуют четкие правила или прямой контроль, и описывает порядок действий работника, когда положения Кодекса нарушены.
				<br/>
				<br/>
				Если у работника Компании все же возникают вопросы как поступить, и не найден ответ в настоящем документе, он может обратиться к членам комиссии по этике за разъяснениями и помощью.
      </li>
		</ul>
		<br/><br/>
		<p>Статья 2</p>
		<br/>
		<p>Область применения</p>
		<br/>
    <ul>
      <li>
				Нормы и принципы, изложенные в настоящем Кодексе, обязательны для всех работников Компании, независимо от уровня занимаемой ими должности, территориального месторасположения подразделения.
				<br/><br/>
				Работники не вправе принимать решения или осуществлять какие-либо действия, которые идут вразрез с ценностями и этическими принципами работы Компании.
				<br/><br/>
				Все работники Компании должны лично ознакомиться с требованиями настоящего Кодекса, следовать им и подтвердить это, подписав Декларацию об ознакомлении и согласии с требованиями Кодекса (Приложение 5).
				<br/><br/>
				Руководители всех подразделений обязаны контролировать соблюдение работниками требований настоящего Кодекса.
				<br/><br/>
				Каждый работник должен понимать, что несоблюдение им требований, установленных в данном Кодексе, влечет за собой привлечение к дисциплинарной ответственности, вплоть до увольнения.
				<br/><br/>
				Процесс подачи уведомления, жалоб на неуместное поведение, предусмотренное Кодексом Компании или законодательством Республики Узбекистан, и реагирования на него со стороны Компании, описан в Политике по уведомлению о нарушениях.
      </li>
		</ul>
		<br/><br/>
		<p>Статья 3</p>
		<br/>
		<p>Миссия и Видение Компании</p>
		<br/>
    <ul>
      <li>
			<b>Миссия Компании:</b><br/>
			Мы несём культуру современной розничной торговли, сохраняя лучшие национальные традиции.
			<br/><br/>
			<b>Видение Компании:</b><br/>
			Лидер, надёжный партнёр, новатор и пионер современной розничной торговли Узбекистана. Мы лучше других понимаем своих клиентов, партнёров, сотрудников. У нас удобные локации, современные технологии, качественные товары, сервис и дружелюбная атмосфера.
      </li>
		</ul>
		<br/><br/>
		<p>Статья 4</p>
		<br/>
		<p>Корпоративные ценности</p>
		<br/>
    <ul>
      <li>
				<b>Клиентоориентированность</b><br/>
				Глубокое понимание клиента и эффективное удовлетворение его потребностей - всегда в приоритете наших задач.
				<br/><br/>
				<b>Прозрачность и Взаимоуважение</b><br/>
				Мы действуем открыто, честно и уважительно по отношению к своим клиентам, партнерам и сотрудникам.
				<br/><br/>
				<b>Вовлечённость</b><br/>
				Мы ожидаем от наших сотрудников ответственности, инициативности, проявления чувства собственника и самостоятельности в принятии решений во благо компании.
				<br/><br/>
				<b>Инновационность</b><br/>
				Мы постоянно развиваемся и работаем над собой, внедряя новые технологии, совершенствуя бизнес-процессы и грамотно используя ресурсы компании.
				<br/><br/>
				<b>Превосходство во всем</b><br/>
				Мы непрерывно совершенствуемся и превосходим ожидания наших клиентов, партнеров и акционеров.
				<br/>
				<i>* В контексте данного Кодекса под словом «Мы» подразумеваются все работники Компании.</i>
      </li>
		</ul>
		<br/><br/>
		<p>Статья 5</p>
		<br/>
		<p>Базовые этические принципы</p>
		<br/>
    <ul>
      <li>
				<b>Честность и доверие</b><br/>
				Все работники обязаны защищать репутацию Компании, которая зарекомендовала себя как честная и прозрачная компания в отрасли розничной торговли. Работники Компании обязаны действовать профессионально, справедливо и честно во всех деловых отношениях.
				<br/><br/>
				Мы уважаем наших конкурентов. Мы постоянно анализируем деятельность существующих и потенциальных конкурентов, выявляем слабые и сильные их стороны, но всегда соблюдаем правила честной конкурентной борьбы и нормативные правовые акты.
				<br/><br/>
				Мы следим за соблюдением высоких этических стандартов в отношениях со средствами массовой информации и не допускаем распространения недостоверной информации, сокрытия и/или искажения фактов в своей рекламной деятельности.
				<br/><br/>
				<b>Соблюдение законодательства и корпоративных норм</b><br/>
				Все работники Компании должны строго придерживаться соблюдения требований местного и международного законодательства, внутренних процедур и регламентов Компании. Компания придерживается нулевой толерантности в отношении любой незаконной активности, совершаемой для извлечения личной выгоды или от имени Компании.
				<br/><br/>
				Компания придерживается соблюдения требований антимонопольного законодательства. Выбор поставщиков и контрагентов Компании осуществляется в соответствии с принципами свободной конкуренции.
				<br/><br/>
				Все бухгалтерские и другие документы должны соответствовать требованиям действующего законодательства, применяемым стандартам бухгалтерского учета и отчетности, принятым внутренним нормам. В Компании не должно быть неучтенных, незарегистрированных и скрытых активов.
				<br/><br/>
				Работники не должны пытаться прямо или косвенно оказывать неправомерное воздействие или вмешательство в процесс аудиторской проверки финансовой отчетности и системы внутреннего контроля Компании. Необходимо давать искренние ответы на все вопросы внутренних или внешних аудиторов.
				<br/><br/>
				О любом нарушении данных требований работнику следует незамедлительно сообщать главному бухгалтеру и Комитету по этике.
				<br/><br/>
				<b>Ответственность перед клиентами </b><br/>
				Компания предлагает своим клиентам качественные и безопасные продукты по доступным ценам, ставя во главу угла здоровье человека и надежность продаваемых товаров выше любой финансовой выгоды, ставя интересы клиента выше краткосрочной высокой прибыли.
				<br/><br/>
				Компания придерживается ответственного маркетинга для групп клиентов, которые относительно уязвимы для злоупотреблений при маркетинге продукции, например детей.
				<br/><br/>
				<b>Отношения с работниками </b></br>
				Работники Компании представляют большую ценность и являются ключом к нашему успеху. Компания должна стремиться стать таким рабочим местом, где работники могли бы реализовать свой потенциал в открытой и вдохновляющей рабочей обстановке. Компания строит отношения со своими работниками на принципах долгосрочного сотрудничества, взаимного уважения и неукоснительного исполнения взаимных обязательств.
				<br/><br/>
				Компания ценит свой персонал за: <br/>
				•	компетентность и профессионализм, <br/>
				•	ответственность и дисциплинированность, <br/>
				•	инициативность и нацеленность на результат, <br/>
				•	стремление к профессиональному росту,<br/>
				•	лояльность к Компании, <br/>
				•	порядочность. <br/><br/>
				Компания стремится обеспечить равные карьерные возможности для всех работников и кандидатов. Это значит, что работники Компании, независимо от должности, вида и способа общения отвергают фаворитизм и справедливо относятся к коллегам.
				Во всех кадровых вопросах (подбор, наем, обучение работников, оплата труда и карьерный рост) мы не делаем различий между людьми по признакам пола, возраста, расы, цвета кожи, языка, национального или социального происхождения, религии, семейного положения, имущественного положения.
				<br/><br/>
				Компания устанавливает механизмы для предотвращения, информирования и расследования физического, словесного, сексуального или психологического насилия и домогательств на рабочем месте. Компания принимает необходимые меры в соответствии с действующим законодательством, чтобы обеспечить своим работникам здоровую и безопасную рабочую среду.
				<b>Более подробно см. Политику по противодействию домогательствам и дискриминации.</b>
				<br/><br/>
				Компания не приемлет принудительный труд любой формы. Работникам должно быть разрешено свободно передвигаться и покидать свои рабочие места после окончания рабочего дня.
				<br/><br/>
				Компания ни при каких обстоятельствах не приемлет использование детского труда. Минимальный возраст для полной занятости должен соответствовать минимальному законодательно установленному возрасту для приема на работу.
				<br/><br/>
				Компания поддерживает политику открытых дверей для всех работников. Это означает для любого работника, что его непосредственный руководитель обязан выслушать своего подчиненного и отреагировать соответствующим образом.
				<br/><br/>
				Компания приветствует активное участие работников в жизни Компании, что способствует развитию корпоративной культуры и внутреннего сотрудничества, по достоинству оценивает и всячески поощряет любые идеи работников, реализация которых позволит стать лучше и конкурентоспособнее.
				<br/><br/>
				Компания всеми силами способствует сохранению благоприятного социально-психологического климата в коллективе. Все разногласия работников решаются их непосредственным руководителем при необходимости в сотрудничестве с уполномоченным лицом Департамента по работе с персоналом. Недопустимо делать какой бы то ни было конфликт достоянием третьих лиц.
				<br/><br/>
				<b>Внешние коммуникации</b></br>
				Департамент маркетинга несет ответственность за составление и распространение информации в средствах массовой информации. Помимо команды Департамента маркетинга, топ-менеджеров (в части своего направления) и членов Наблюдательного совета, ни один другой работник не может от имени Компании разговаривать со СМИ, делать заявления в прессе и телерадиовещательных учреждениях, организациях в социальных сетях о деятельности ИП ООО «Anglesey Food» без предварительного одобрения Директора Департамента маркетинга.
				<br/><br/>
				Каждый работник Компании должен понимать и всегда помнить, что любая высказанная им как работником Компании точка зрения или распространенная информация непосредственно соотносится с самой Компанией, ее имиджем и влияет на ее репутацию на рынке. Все внешние сообщения должны быть честными, полными, точными и не должны вводить никого в заблуждение.
				<br/><br/>
				Компания ожидает, что все её работники придерживаются <b>Политики по внешним коммуникациям.</b>
				<br/><br/>
				Работники Компании могут принимать участие в политической деятельности в свое свободное время с использованием собственных ресурсов. Работники должны тщательно разграничивать свою самостоятельную политическую деятельность и деятельность в качестве представителя ИП ООО «Anglesey Food». Однако, за исключением случаев, когда в соответствующих законах и нормативных актах не указано иное, эти действия должны осуществляться в нерабочее время, при этом ресурсы и название компании не должно быть использовано. При этом компания не несет ответственность за политическую деятельность работника.
				<br/><br/>
				<b>Отношения с поставщиками и бизнес-партнерами</b></br>
				Компания действует профессионально, справедливо и честно во всех взаимоотношениях с поставщиками и деловыми партнерами; не предоставляет коммерческих преимуществ незаконными или неэтичными способами и не участвует в деятельности, которая нарушала бы закон о конкуренции.
				<br/><br/>
				Компания поддерживает деловые отношения с компаниями - поставщиками, поддерживающими безопасную и здоровую производственную среду, и регулярно проверяет соблюдение этих стандартов. Компания поддерживает улучшения в политиках и практике компания в цепочке поставок, включающих безопасность и окружающую среду.
				<br/><br/>
				Компания ожидает, что все деловые партнеры будут действовать в соответствии с действующим законодательством.
				<br/><br/>
				Работники не должны предпринимать каких-либо действий, влекущих за собой личную выгоду за счет Компании. Работники не должны осуществлять мошеннические действия при проведении тендеров, конкурсов и торгов, при заключении и исполнении сделок, превышать должностные полномочия, а также совершать любые другие обманные действия.
				<br/><br/>
				Работники должны придерживаться нижеперечисленных рекомендаций, оценивая, принимать или нет подарки, преподнесение которых так или иначе связано с отношением к Компании:
				<br/>
				•	Не следует добиваться предоставления подарков. Работникам, членам их семей и аффилированным лицам категорически запрещается добиваться получения и получать подарки или услуги любого рода в обмен на предоставление дарителю возможности влиять на решения, принимаемые в Компании.
				<br/>
				•	Не следует принимать подарки, получение которых может повлечь возникновение у принимающего каких-либо обязательств по отношению к дарителю.
				<br/>
				•	Не следует принимать подарки в виде наличности или ценных бумаг.
				<br/>
				•	Подарки стоимостью более 500 000 сум могут быть приняты только в том случае, если отказ от подарка может быть воспринят, как оскорбление. О преподнесении таких подарков следует немедленно сообщить Комиссии по этике.
				<br/><br/>
				Работники обязаны сообщить Комиссии по этике о личном интересе или деятельности, способной причинить ущерб интересам и репутации Компании, а также должны оценивать потенциальный конфликт интересов, и немедленно уведомить своего руководителя при его наличии. <b>Более подробно см. Положение о конфликте интересов.</b>
				<br/><br/>
				<b>Борьба с взяточничеством и коррупцией</b></br>
				Работники Компании не должны совершать неправомерных платежей для извлечения неправомерной выгоды ни в каком виде представителям власти, государственными служащими, служащими международных организаций, и членам их семей в обмен на официальное влияние в интересах Компании. Во избежание любых ситуаций с совершением неправомерных платежей (в том числе совершаемых с целью достижения коммерческой или иной выгоды для Компании), работники должны руководствоваться здравым смыслом.
				<br/><br/>
				Программы развлекательного характера для представителей власти должны быть обоснованы, не должны выходить за рамки разумного и не должны иметь непосредственного отношения к деятельности Компании. Аналогично, все подарки должны быть разумно минимальной стоимости, не превышающей 500 000 сум, и не должны преследовать цели получения незаслуженного преимущества при представлении интересов Компании в тех или иных вопросах.
				<br/><br/>
      </li>
		</ul>
		<br/><br/>
		<p>Статья 6</p>
		<br/>
		<p>Деловой этикет</p>
		<br/>
    <ul>
      <li>
				Работник Компании, независимо от должности, вида и способа общения, соблюдает следующие правила: <br/>
				•	проявлять взаимное уважение к коллегам; <br/>
				•	контролировать свои эмоции (не употреблять нецензурную лексику, говорить на повышенных тонах, не заниматься рукоприкладством); <br/>
				•	пресекать интриги, слухи, сплетни; <br/>
				•	обсуждать действия, а не личность; <br/>
				•	уважать частную (личную) жизнь работников, соблюдая суверенитет личности;<br/>
				•	не переносить дружеские отношения в рабочую обстановку;<br/>
				•	не демонстрировать коллегам свое плохое настроение;<br/>
				•	благодарить коллег за хорошо выполненную работу.
      </li>
		</ul>
		<br/><br/>
		<p>Статья 7</p>
		<br/>
		<p>Конфиденциальность информации</p>
		<br/>
    <ul>
      <li>
				Работники должны обеспечивать сохранность и не допускать распространения внутри и за пределами Компании конфиденциальной информации Компании, к ней относятся: <br/>
				•	стратегические и оперативные планы Компании; <br/>
				•	сведения о финансовых показателях и маркетинговой деятельности Компании; <br/>
				•	сведения о поставщиках и партнерах Компании; <br/>
				•	сведения о применяемых в Компании технологиях, оборудовании, автоматизированных системах; <br/>
				•	сведения о технических системах охраны Компании; <br/>
				•	сведения о персонале Компании (штатное расписание, персональные данные работников, данные о заработной плате и др.); <br/>
				•	внутренние нормативные документы. <br/>
				•	сведения о внутренних конфликтах, разногласиях и происшествиях, которые затрагивают личную жизнь коллег или задевают чувства коллег.
				<br/><br/>
				Внутренние нормативные документы, которые были разработаны работниками в течение периода работы в Компании, являются собственностью Компании.
				<br/><br/>
				Поступая на работу в ИП ООО «Anglesey Food», работники принимают на себя обязательства соблюдения коммерческой тайны. Обязательство хранить конфиденциальную информацию ИП ООО «Anglesey Food» не отменяется с прекращением работы в Компании.
      </li>
		</ul>
		<br/><br/>
		<p>Статья 8</p>
		<br/>
		<p>Хранение</p>
		<br/>
    <ul>
      <li>
				Оригинал Кодекса деловой этики хранится в Департаменте по работе с персоналом.
      </li>
		</ul>
  </div>
</div>
	`,

  ppl_title: 'Правила программы лояльности',

  offerta_page_title: 'Договор публичной оферты 2.0',

  // 2021 year
  year_of_charity2021: {
    table: [
      {
        date: '	29.04.2021',
        gathered_sum: '564 120 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Аэропорт'
      },
      {
        date: '08.04.2021',
        gathered_sum: '23 034 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Риёзий'
      },
      {
        date: '07.04.2021',
        gathered_sum: '17 382 152',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара 2'
      },
      {
        date: '06.04.2021',
        gathered_sum: '19 800 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },
      {
        date: '25.03.2021',
        gathered_sum: '36 514 311',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара 1'
      },
      {
        date: '24.02.2021',
        gathered_sum: '16 806 850',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ракат'
      },
      {
        date: '02.02.2021',
        gathered_sum: '21 615 500',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },
      {
        date: '28.01.2021',
        gathered_sum: '12 348 750',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Спутник-Сергели'
      },
      {
        date: '26.01.2021',
        gathered_sum: '22 358 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Караташ'
      },

      {
        date: '06.01.2021',
        gathered_sum: '14 930 000	',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Next'
      },

      {
        date: '06.01.2021',
        gathered_sum: '10 735 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Камолон'
      }
    ],
    year: 'Итого за 2021 г.',
    sum: '759 644 963'
  },
  // 2020 year
  year_of_charity2020: {
    table: [
      {
        date: '22.12.2020',
        gathered_sum: '25 026 600',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'С.Азимова'
      },
      {
        date: '16.12.2020',
        gathered_sum: '5 090 100',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Oazis'
      },
      {
        date: '07.12.2020',
        gathered_sum: '15 798 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Алмалык'
      },
      {
        date: '01.12.2020',
        gathered_sum: '12 955 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Фонд "Эзгу Амал"'
      },
      {
        date: '30.11.2020',
        gathered_sum: '2 950 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Янгийль'
      },
      {
        date: '26.11.2020',
        gathered_sum: '8 751 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Куйлюк'
      },
      {
        date: '25.11.2020',
        gathered_sum: '17 358 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Файзабад'
      },
      {
        date: '24.11.2020',
        gathered_sum: '16 375 950',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Mercato'
      },
      {
        date: '24.11.2020',
        gathered_sum: '9 741 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Андижан'
      },

      {
        date: '	23.11.2020',
        gathered_sum: '21 212 750',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Карасу'
      },

      {
        date: '19.11.2020',
        gathered_sum: '18 000 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '18.11.2020',
        gathered_sum: '22 381 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чилонзар'
      },

      {
        date: '17.11.2020',
        gathered_sum: '24 288 350',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Абай'
      },

      {
        date: '16.11.2020',
        gathered_sum: '25 393 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сайрам'
      },

      {
        date: '14.11.2020',
        gathered_sum: '34 306 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сергели'
      },

      {
        date: '14.11.2020',
        gathered_sum: '14 037 250',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Юнусабад'
      },

      {
        date: '	09.11.2020',
        gathered_sum: '21 108 150',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },

      {
        date: '09.11.2020',
        gathered_sum: '32 080 600',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Риёзий'
      },

      {
        date: '30.06.2020',
        gathered_sum: '17 631 950',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },

      {
        date: '30.06.2020',
        gathered_sum: '7 304 500',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Заамин'
      },

      {
        date: '30.06.2020',
        gathered_sum: '3 746 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Аэропорт'
      },

      {
        date: '29.06.2020',
        gathered_sum: '5 927 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Oazis'
      },

      {
        date: '29.06.2020',
        gathered_sum: '11 996 900',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ракат'
      },

      {
        date: '29.06.2020',
        gathered_sum: '6 591 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Салом'
      },

      {
        date: '27.06.2020',
        gathered_sum: '22 246 900',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара-1'
      },

      {
        date: '27.06.2020',
        gathered_sum: '9 195 591',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара-2'
      },

      {
        date: '27.06.2020',
        gathered_sum: '9 715 750',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бухара-3'
      },

      {
        date: '27.06.2020',
        gathered_sum: '14 496 700',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Андижан'
      },

      {
        date: '27.06.2020',
        gathered_sum: '3 010 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Асака'
      },

      {
        date: '27.06.2020',
        gathered_sum: '14 650 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Коканд'
      },

      {
        date: '27.06.2020',
        gathered_sum: '20 304 700',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бунёдкор'
      },

      {
        date: '27.06.2020',
        gathered_sum: '7 714 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чилонзар'
      },

      {
        date: '27.06.2020',
        gathered_sum: '12 116 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бешкайрагач'
      },

      {
        date: '26.06.2020',
        gathered_sum: '19 132 150',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Next'
      },

      {
        date: '26.06.2020',
        gathered_sum: '19 684 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Алайский'
      },

      {
        date: '26.06.2020',
        gathered_sum: '3 407 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Mercato'
      },

      {
        date: '26.06.2020',
        gathered_sum: '16 803 450',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Vega'
      },

      {
        date: '26.06.2020',
        gathered_sum: '6 691 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '26.06.2020',
        gathered_sum: '6 397 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Назарбек'
      },

      {
        date: '26.06.2020',
        gathered_sum: '19 058 300',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бекабад'
      },

      {
        date: '26.06.2020',
        gathered_sum: '38 166 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Карасу'
      },

      {
        date: '26.06.2020',
        gathered_sum: '7 714 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Integro'
      },

      {
        date: '24.06.2020',
        gathered_sum: '14 755 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'А.Дониш'
      },

      {
        date: '24.06.2020',
        gathered_sum: '12 015 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Юнусабад'
      },

      {
        date: '23.06.2020',
        gathered_sum: '13 955 850',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Алмалык'
      },

      {
        date: '23.06.2020',
        gathered_sum: '14 056 050',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Себзар'
      },

      {
        date: '23.06.2020',
        gathered_sum: '11 113 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Куйлюк'
      },

      {
        date: '18.06.2020',
        gathered_sum: '13 831 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Янгийюль'
      },

      {
        date: '17.06.2020',
        gathered_sum: '26 147 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чирчик'
      },

      {
        date: '10.06.2020',
        gathered_sum: '21 885 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Спутник - Сергели'
      },

      {
        date: '09.06.2020',
        gathered_sum: '16 811 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Караташ'
      },

      {
        date: '05.06.2020',
        gathered_sum: '17 438 050',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ангрен'
      },

      {
        date: '04.06.2020',
        gathered_sum: '18 243 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Учтепа'
      },

      {
        date: '03.06.2020',
        gathered_sum: '23 867 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Авиасозлар'
      },

      {
        date: '28.05.2020',
        gathered_sum: '20 804 550',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Авиасозлар'
      },

      {
        date: '27.05.2020',
        gathered_sum: '28 051 500',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Аэропорт'
      },

      {
        date: '26.05.2020',
        gathered_sum: '34 882 250',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Абай'
      },

      {
        date: '21.05.2020',
        gathered_sum: '16 929 950',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Шедевр'
      },

      {
        date: '21.05.2020',
        gathered_sum: '30 662 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сайрам'
      },

      {
        date: '19.05.2020',
        gathered_sum: '23 506 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Салом'
      },

      {
        date: '18.05.2020',
        gathered_sum: '14 406 550',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ал-Хоразмий'
      },

      {
        date: '14.05.2020',
        gathered_sum: '19 100 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '13.05.2020',
        gathered_sum: '30 366 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Чиланзар'
      },

      {
        date: '08.05.2020',
        gathered_sum: '24 550 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'С. Азимова'
      },

      {
        date: '07.05.2020',
        gathered_sum: '32 900 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Риёзий'
      },

      {
        date: '06.05.2020',
        gathered_sum: '21 121 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Файзабад'
      },

      {
        date: '05.05.2020',
        gathered_sum: '36 176 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Сергели'
      },

      {
        date: '26.03.2020',
        gathered_sum: '35 313 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Туркменский'
      },

      {
        date: '19.03.2020',
        gathered_sum: '26 233 750',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Юнусабад'
      },

      {
        date: '16.03.2020',
        gathered_sum: '18 581 200',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Бешкайрагач'
      },

      {
        date: '	16.03.2020',
        gathered_sum: '21 977 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Камолон'
      },

      {
        date: '18.02.2020',
        gathered_sum: '23 726 650',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ракат'
      },

      {
        date: '11.02.2020',
        gathered_sum: '19 653 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Куйлюк'
      },

      {
        date: '08.02.2020',
        gathered_sum: '22 825 100',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Саракулька'
      },

      {
        date: '01.02.2020',
        gathered_sum: '6 415 850',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Ургут'
      },

      {
        date: '31.01.2020',
        gathered_sum: '28 513 150',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Карасу'
      },

      {
        date: '30.01.2020',
        gathered_sum: '13 169 000',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Бухара 3'
      },

      {
        date: '30.01.2020',
        gathered_sum: '11 240 000',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Бухара 2'
      },

      {
        date: '30.01.2020',
        gathered_sum: '35 287 500',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Бухара'
      },

      {
        date: '30.01.2020',
        gathered_sum: '21 288 700',
        where_passed:
          'Пожертвование мальчику, Бабаханову Убайдулле Ильхамжановичу',
        supermarket: 'Алмалык'
      },

      {
        date: '24.01.2020',
        gathered_sum: '17 363 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Караташ'
      },

      {
        date: '15.01.2020',
        gathered_sum: '22 100 000',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'ТТЗ'
      },

      {
        date: '08.01.2020',
        gathered_sum: '29 868 400',
        where_passed: 'Фонд "Эзгу Амал"',
        supermarket: 'Абай'
      }
    ],

    year: 'Итого за 2020 г.',
    sum: '1 521 686 691'
  },
  // 2019 year
  year_of_charity2019: {
    table: [
      {
        date: '26.12.2019',
        gathered_sum: '30 299 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Салом'
      },
      {
        date: '26.12.2019',
        gathered_sum: '29 162 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Бунёдкор'
      },
      {
        date: '11.12.2019',
        gathered_sum: '29 162 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Сайрам'
      },
      {
        date: '03.12.2019',
        gathered_sum: '32 419 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Туркменский'
      },
      {
        date: '29.11.2019',
        gathered_sum: '29 899 500',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'С.Азимова'
      },
      {
        date: '27.11.2019',
        gathered_sum: '24 733 750',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов "Мехрибонлик" Юнусабадского района города Ташкент',
        supermarket: 'Некст'
      },
      {
        date: '26.11.2019',
        gathered_sum: '24 457 000',
        where_passed: 'Детский дом №23',
        supermarket: 'Меркато'
      },
      {
        date: '21.11.2019',
        gathered_sum: '31 703 350',
        where_passed:
          '"Опа - Сингиллар" Кибрайское районное общество женщин-инвалидов.',
        supermarket: 'Чилонзар'
      },
      {
        date: '20.11.2019',
        gathered_sum: '31 574 250',
        where_passed:
          'Общества Инвалидов Узбекистана города Ташкента "Умид Нури"',
        supermarket: 'Аэропорт'
      },

      {
        date: '19.11.2019',
        gathered_sum: '28 955 600',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Риёзий'
      },

      {
        date: '15.11.2019',
        gathered_sum: '22 122 000',
        where_passed:
          'Общество Инвалидов Узбекистана города Ташкента отделение Яккасаройского района',
        supermarket: 'Учтепа'
      },

      {
        date: '13.11.2019',
        gathered_sum: '12 815 200',
        where_passed:
          'Зааминского районного фонда "Соглом авлод учун" Узбекистана',
        supermarket: 'Заамин'
      },

      {
        date: '07.11.2019',
        gathered_sum: '18 299 400',
        where_passed:
          'Ташкентский городской отдел объединения "VETERAN" воинов-ветеранов и инвалидов Узбекистана',
        supermarket: 'Бекабад'
      },

      {
        date: '01.11.2019',
        gathered_sum: '27 921 200',
        where_passed: 'РИО ва РИАТМТ Онкологиии Радиология г.Ташкента',
        supermarket: 'Сергели'
      },

      {
        date: '25.10.2019',
        gathered_sum: '19 382 300',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Бешкайрагач'
      },

      {
        date: '22.10.2019',
        gathered_sum: '10 532 150',
        where_passed: 'Бектимерское Общество Инвалидов города Ташкента',
        supermarket: 'Файзабад'
      },

      {
        date: '13.09.2019',
        gathered_sum: '15 651 000',
        where_passed: 'РЕСПУБЛИКА УЗБЕКИСТАН УП NOGIRONLARGA MARKAZI',
        supermarket: 'Шедевр'
      },

      {
        date: '06.09.2019',
        gathered_sum: '4 623 000',
        where_passed:
          'Районное Общество Инвалидов Булокбоши Андижанской области Республики Узбекистан',
        supermarket: 'Асака'
      },

      {
        date: '05.09.2019',
        gathered_sum: '16 605 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Андижан'
      },

      {
        date: '28.08.2019',
        gathered_sum: '25 352 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Абая'
      },

      {
        date: '16.08.2019',
        gathered_sum: '21 623 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'ТТЗ'
      },

      {
        date: '08.08.2019',
        gathered_sum: '20 927 100',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Юнусабад'
      },

      {
        date: '06.08.2019',
        gathered_sum: '29 007 200',
        where_passed: 'Ташкентский областной Дом Ребёнка "Кичкинтой"',
        supermarket: 'Туркменский'
      },

      {
        date: '25.07.2019',
        gathered_sum: '10 857 150',
        where_passed:
          'Ургутское отделение Узбекского Общества Инвалидов Самаркандской области',
        supermarket: 'Ургут'
      },

      {
        date: '23.07.2019',
        gathered_sum: '19 677 500',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Карасу'
      },

      {
        date: '16.07.2019',
        gathered_sum: '14 938 500',
        where_passed: 'Дом "Мехрибонлик" № 30',
        supermarket: 'Караташ'
      },

      {
        date: '09.07.2019',
        gathered_sum: '17 554 550',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Чирчик'
      },

      {
        date: '02.07.2019',
        gathered_sum: '25 720 850',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Риёзий'
      },

      {
        date: '27.06.2019',
        gathered_sum: '30 307 400',
        where_passed:
          '"Возможно Всё" Общественно-Благотворительное Объединение. г.Бухара',
        supermarket: 'Бухара 1'
      },

      {
        date: '27.06.2019',
        gathered_sum: '5 202 000',
        where_passed:
          'Бухарское городское отделение Общества слепых Узбекистана',
        supermarket: 'Бухара 3'
      },

      {
        date: '27.06.2019',
        gathered_sum: '13 042 200',
        where_passed:
          'Бухарское городское отделение Общества слепых Узбекистана',
        supermarket: 'Бухара 2'
      },

      {
        date: '11.06.2019',
        gathered_sum: '12 871 300',
        where_passed: 'Nogironlarni ximoya qilish uk',
        supermarket: 'А.Дониш'
      },

      {
        date: '28.03.2019',
        gathered_sum: '19 540 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Ракат'
      },

      {
        date: '27.03.2019',
        gathered_sum: '13 808 850',
        where_passed: 'Общество Инвалидов г.Коканд',
        supermarket: 'Коканд'
      },

      {
        date: '22.02.2019',
        gathered_sum: '10 441 600',
        where_passed: 'Бекабадское Общество Инвалидов',
        supermarket: 'Бекабад'
      },

      {
        date: '21.02.2019',
        gathered_sum: '22 569 850',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Караташ'
      },

      {
        date: '07.02.2019',
        gathered_sum: '15 760 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Алгоритм'
      },

      {
        date: '31.01.2019',
        gathered_sum: '16 850 000',
        where_passed: 'Букинское отделение Общества Инвалидов Узбекистана',
        supermarket: 'Алайский'
      },

      {
        date: '30.01.2019',
        gathered_sum: '19 513 000',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Карасу'
      },

      {
        date: '29.01.2019',
        gathered_sum: '30 120 920',
        where_passed: 'Фонд «Эзгу Амал»',
        supermarket: 'Туркменский'
      },

      {
        date: '22.01.2019',
        gathered_sum: '14 676 900',
        where_passed:
          '1. Фонд «Эзгу Амал» — 14 676 900 сум. 2. Отделение Узбекистанское общество инвалидов — 4 000 000 сум',
        supermarket: 'Next'
      },

      {
        date: '22.01.2019',
        gathered_sum: '4 000 000',
        where_passed: '',
        supermarket: 'Некст'
      },

      {
        date: '15.01.2019',
        gathered_sum: '33 274 400',
        where_passed: 'Онкология ва Радиология табиий маркази',
        supermarket: 'Mercato'
      },

      {
        date: '07.01.2019',
        gathered_sum: '27 393 100',
        where_passed: 'Общество инвалидов Узбекистана',
        supermarket: 'Риёзий'
      }
    ],

    year: 'Итого за 2019 г.',
    sum: '915 604 070'
  },

  // 2018 year
  year_of_charity2018: {
    table: [
      {
        date: '26.12.2018',
        gathered_sum: '28 022 000',
        where_passed:
          'аучно-исследовательский институт гематологии и переливания крови',
        supermarket: 'Сергели'
      },
      {
        date: '25.12.2018',
        gathered_sum: '17 036 000',
        where_passed:
          'Общество Инвалидов Узбекистана г.Ташкента. Отделение Студенческого городка «Ёшлик»',
        supermarket: 'Абай'
      },
      {
        date: '20.12.2018',
        gathered_sum: '15 323 350',
        where_passed:
          'Специализированная школа-интернат №102 для слабослышащих детей',
        supermarket: 'Учтепа'
      },
      {
        date: '11.12.2018',
        gathered_sum: '21 802 000',
        where_passed: 'Фонд «EZGU AMAL»',
        supermarket: 'Юнусабад'
      },
      {
        date: '28.11.2018',
        gathered_sum: '18 099 320',
        where_passed: 'ДПИ «MEHRIBON-NOGIRON»',
        supermarket: 'Чиланзар'
      },
      {
        date: '26.11.2018',
        gathered_sum: '10 610 900',
        where_passed:
          'Сергелинское, Яккасарайского района общество слепых Республика Узбекистана',
        supermarket: 'Куйлюк'
      },
      {
        date: '23.11.2018',
        gathered_sum: '16 920 950',
        where_passed:
          'Узбекское Общество Инвалидов Шайхонтахурское районное отделение города Ташкента',
        supermarket: 'Сайрам'
      },
      {
        date: '21.11.2018',
        gathered_sum: '17 626 300',
        where_passed: '«Центр Культуры Глухих» города Ташкента',
        supermarket: 'Аэропорт'
      },
      {
        date: '09.11.2018',
        gathered_sum: '14 349 945',
        where_passed:
          '«Общество Инвалидов» Чирчикского городского отделения Ташкентской области',
        supermarket: 'ТТЗ'
      },

      {
        date: '05.11.2018',
        gathered_sum: '18 238 000',
        where_passed:
          '«Общество Инвалидов» Юнусабадского отделения города Ташкента',
        supermarket: 'Бунедкор'
      },

      {
        date: '24.10.2018',
        gathered_sum: '11 725 300',
        where_passed: '«Общество Инвалидов Узбекистана» Навоинской области',
        supermarket: 'Бухара'
      },

      {
        date: '12.10.2018',
        gathered_sum: '10 663 000',
        where_passed: '«Сахобат» дом-интернат для престарелых города Андижан',
        supermarket: 'Андижан'
      },

      {
        date: '08.10.2018',
        gathered_sum: '29 621 945',
        where_passed: '«Узбекистон ногиронлар жамияти» Тошкент шахар булими',
        supermarket: 'Салом'
      },

      {
        date: '17.08.2018',
        gathered_sum: '19 824 000',
        where_passed: 'Специализированная Школа №36',
        supermarket: 'Туркменский'
      },

      {
        date: '15.08.2018',
        gathered_sum: '12 255 700',
        where_passed: '«Общество Инвалидов» города Ангрена',
        supermarket: 'Алмалык'
      },

      {
        date: '27.07.2018',
        gathered_sum: '10 344 500',
        where_passed:
          'Бухарское городское отделение Общества слепых Узбекистана',
        supermarket: 'Бухара 1'
      },

      {
        date: '25.07.2018',
        gathered_sum: '13 072 150',
        where_passed: '«Общество Инвалидов» города Ташкента',
        supermarket: 'Корасу'
      },

      {
        date: '13.07.2018',
        gathered_sum: '17 453 000',
        where_passed: 'Общество помощи инвалидам «Rumit-Inval»',
        supermarket: 'ТТЗ'
      },

      {
        date: '14.06.2018',
        gathered_sum: '14 082 175',
        where_passed:
          'Детям с нарушениями в психофизическом развитии «Областной специализированный дом ребенка»',
        supermarket: 'Аэропорт'
      },

      {
        date: '12.06.2018',
        gathered_sum: '18 418 661',
        where_passed: 'Дом ребенка города Янгиюль',
        supermarket: 'Сергели'
      },

      {
        date: '07.06.2018',
        gathered_sum: '14 460 525',
        where_passed:
          '«Узбекское общество инвалидов» Юнусабадкого районного отделения г.Ташкента',
        supermarket: 'Караташ'
      },

      {
        date: '29.05.2018',
        gathered_sum: '20 722 150',
        where_passed:
          '«Общество социальной взаимопомощи инвалидов» Мирабадского районного отделения г.Ташкента',
        supermarket: 'Азимова'
      },

      {
        date: '18.05.2018',
        gathered_sum: '14 280 750',
        where_passed:
          'Тошкент шахар «Ижтимоий Узаро Йордам МЕХРИБОНЛИК Ногиронлар Жамияти»',
        supermarket: 'Риёзий'
      },

      {
        date: '17.05.2018',
        gathered_sum: '12 091 100',
        where_passed:
          '«Общество Инвалидов Узбекистана» Яшнабадского районного отделения г.Ташкента',
        supermarket: 'Куйлюк'
      },

      {
        date: '15.05.2018',
        gathered_sum: '11 485 040',
        where_passed: 'Детский дом №23',
        supermarket: 'Integro'
      },

      {
        date: '04.05.2018',
        gathered_sum: '16 357 600',
        where_passed:
          'Детям с заболеваниями онкологии обществ «Возможно ВСЕ», «Давайте сотворим чудо»',
        supermarket: 'Бухара'
      },

      {
        date: '24.04.2018',
        gathered_sum: '11 591 900',
        where_passed:
          '«Узбекское Общество Инвалидов» Алмазарского районного отделения города Ташкента',
        supermarket: 'Vega'
      },

      {
        date: '16.04.2018',
        gathered_sum: '10 445 000',
        where_passed:
          'ОСВИ «Мехрибонлик» Яккасарайского района города Ташкента',
        supermarket: 'Next'
      },

      {
        date: '13.04.2018	',
        gathered_sum: '7 964 800',
        where_passed: 'Общество Женщин Инвалидов «Опа-Сингиллар» ЛИК',
        supermarket: 'Сайрам'
      },

      {
        date: '11.04.2018',
        gathered_sum: '16 367 400',
        where_passed: 'Научно-Исследовательский институт Гематологии',
        supermarket: 'Абая'
      },

      {
        date: '28.03.2018',
        gathered_sum: '5 813 900',
        where_passed:
          'Общество инвалидов «Мурувват» отделение Бутакора Андижанской области',
        supermarket: 'Андижан'
      },

      {
        date: '28.02.2018',
        gathered_sum: '12 787 800',
        where_passed: 'Ташкентский Онкологический диспансер',
        supermarket: 'Туркменский'
      },

      {
        date: '22.02.2018',
        gathered_sum: '13 876 800',
        where_passed: '«Общество Инвалидов Узбекистана» города Ташкента',
        supermarket: 'Карасу'
      },

      {
        date: '22.02.2018',
        gathered_sum: '17 925 830',
        where_passed: 'Специализированная школа №37',
        supermarket: 'Авиатор'
      },

      {
        date: '13.02.2018',
        gathered_sum: '17 428 960',
        where_passed:
          '«Общество Инвалидов Узбекистана» города Ташкента Отделение Студенческого городка «Ёшлик»',
        supermarket: 'Сергели'
      },

      {
        date: '06.02.2018',
        gathered_sum: '15 823 950',
        where_passed:
          'ОДИ «Ногирон–Болалар Камалак уйи». Приобретение лекарственных препаратов и продуктов питания, материальная помощь семьям с детьми инвалидами',
        supermarket: 'Ракат'
      },

      {
        date: '01.02.2018',
        gathered_sum: '15 650 800',
        where_passed:
          'Общество Женщин-Инвалидов Кибрайского района «Опа-Сингиллар»',
        supermarket: 'ТТЗ'
      },

      {
        date: '05.01.2018',
        gathered_sum: '7 666 625',
        where_passed: 'Бектемирское Общество Инвалидов города Ташкента',
        supermarket: 'Сайрам'
      },

      {
        date: '05.01.2018',
        gathered_sum: '7 129 660',
        where_passed:
          'Общество Социальной Взаимопомощи «Мехрибонлик» Юнусабадского районного отделения',
        supermarket: 'Учтепа'
      },

      {
        date: '04.01.2018',
        gathered_sum: '9 183 000',
        where_passed: '«Общество Инвалидов» Ташкентского района',
        supermarket: 'Аэропорт'
      },

      {
        date: '04.01.2018',
        gathered_sum: '13 019 900',
        where_passed: 'Международный Женский Общественный Фонд «Sharq Ayoli»',
        supermarket: 'Mercato'
      }
    ],

    year: 'Итого за 2018 г.',
    sum: '607 562 686'
  },

  //  2017 year
  year_of_charity2017: {
    table: [
      {
        date: '21.12.2017',
        gathered_sum: '7 045 900',
        where_passed: 'Школа интернат для глухонемых №80',
        supermarket: 'Алмалык'
      },
      {
        date: '19.12.2017',
        gathered_sum: '13 943 700',
        where_passed: 'Общество Инвалидов Юнусабадского отделение',
        supermarket: 'Абай'
      },
      {
        date: '13.12.2017',
        gathered_sum: '12 588 800',
        where_passed: 'Общество «Возможно Всё!», «Давайте сотворим чудо!»',
        supermarket: 'Бухара DISKONT'
      },
      {
        date: '11.12.2017',
        gathered_sum: '11 085 000',
        where_passed: 'Центр Культуры Глухих города Ташкента',
        supermarket: 'Integro'
      },
      {
        date: '09.11.2017',
        gathered_sum: '7 892 700',
        where_passed: 'Учтепинское Общество Инвалидов «Мехрибонлик»',
        supermarket: 'Караташ'
      },
      {
        date: '08.11.2017',
        gathered_sum: '11 514 200',
        where_passed: '«Мехрибонлик» №23',
        supermarket: 'Туркменский'
      },
      {
        date: '08.11.2017',
        gathered_sum: '12 445 560',
        where_passed: 'Чиланзарское Общество Инвалидов',
        supermarket: 'Юнусабад'
      },
      {
        date: '07.11.2017',
        gathered_sum: '15 021 300',
        where_passed:
          'Общество инвалидов Ташкентской области с целью организации праздников и оказания материальной помощи',
        supermarket: 'Салом'
      },
      {
        date: '05.10.2017',
        gathered_sum: '11 428 377',
        where_passed: 'Городское Общество Инвалидов г.Ташкента',
        supermarket: 'Риёзий'
      },

      {
        date: '21.08.2017',
        gathered_sum: '14 662 300',
        where_passed: 'Общество Инвалидов Алмазарского района',
        supermarket: 'Абай'
      },

      {
        date: '18.08.2017',
        gathered_sum: '13 664 700',
        where_passed: 'Общество Инвалидов Яшнобадского района',
        supermarket: 'ТТЗ'
      },

      {
        date: '18.08.2017',
        gathered_sum: '12 027 270',
        where_passed: '«Мехрибонлик» ОСВИ Мирабадского отделения',
        supermarket: 'Аэропорт'
      },

      {
        date: '16.08.2017',
        gathered_sum: '16 397 670',
        where_passed: 'ОСВИ « Мехрибонлик » Мирзо Улугбекского района',
        supermarket: 'Азимова'
      },

      {
        date: '16.08.2017',
        gathered_sum: '12 442 575',
        where_passed: 'Отделение Сергелинского района Общество Слепых',
        supermarket: 'Сергели'
      },

      {
        date: '06.07.2017',
        gathered_sum: '10 588 700',
        where_passed:
          'Узбекское Общество инвалидов Чирчикского городского отделения',
        supermarket: 'Алмалык'
      },

      {
        date: '03.07.2017',
        gathered_sum: '8 815 230',
        where_passed: 'Общество инвалидов Шайхонтахурского районного отделения',
        supermarket: 'Карасу'
      },

      {
        date: '29.06.2017',
        gathered_sum: '18 790 650',
        where_passed: 'ОО «АPLA»',
        supermarket: 'Mercato'
      },

      {
        date: '28.06.2017',
        gathered_sum: '11 490 350',
        where_passed: '«Ногирон Болалар Камалак уйи»',
        supermarket: 'Integro'
      },

      {
        date: '14.06.2017',
        gathered_sum: '10 402 135',
        where_passed: 'Институт Гематологии и переливания крови',
        supermarket: 'Туркменский'
      },

      {
        date: '12.04.2017',
        gathered_sum: '9 976 680',
        where_passed: 'Общество женщин-инвалидов «Опа-сингиллар»',
        supermarket: 'Караташ'
      },

      {
        date: '29.03.2017',
        gathered_sum: '11 958 800',
        where_passed: 'Ташкентский онкологический диспансер',
        supermarket: 'Абай'
      },

      {
        date: '24.03.2017',
        gathered_sum: '14 106 430',
        where_passed: 'Студентам-инвалидам Городского общества «Ёшлик»',
        supermarket: 'Авиатор'
      },

      {
        date: '17.03.2017',
        gathered_sum: '9 806 500',
        where_passed: 'Ташкентский онкологический диспансер',
        supermarket: 'Сергели'
      },

      {
        date: '10.03.2017',
        gathered_sum: '12 576 300',
        where_passed: 'Яккасарайский район «Мехрибонлик». Медикаменты',
        supermarket: 'ТТЗ'
      },

      {
        date: '03.03.2017',
        gathered_sum: '11 232 900',
        where_passed:
          'Юнусабадское районное отделение г.Ташкент «Узбекское общество инвалидов»',
        supermarket: ''
      },

      {
        date: '22.02.2017',
        gathered_sum: '9 961 910',
        where_passed: 'Бектемирское общество инвалидов',
        supermarket: 'Ракат'
      },

      {
        date: '12.01.2017',
        gathered_sum: '11 782 100',
        where_passed: 'Общество инвалидов Ташкентского городского отделения',
        supermarket: 'Туркменский'
      },

      {
        date: '09.01.2017',
        gathered_sum: '6 473 260',
        where_passed:
          'Общество социальной взаимопомощи инвалидов «Мехрибонлик»',
        supermarket: 'Карасу'
      },

      {
        date: '06.01.2017',
        gathered_sum: '13 424 400',
        where_passed: 'Общество Женщин-инвалидов «Опа-сингиллар»',
        supermarket: 'Integro'
      },

      {
        date: '05.01.2017',
        gathered_sum: '11 659 800',
        where_passed: 'Международный женский общественный фонд «Sharq Ayoli»',
        supermarket: 'Аэропорт'
      }
    ],

    year: 'Итого за 2017 г.',
    sum: '355 206 197'
  },

  //  2016 year
  year_of_charity2016: {
    table: [
      {
        date: '26.10.2016',
        gathered_sum: '11 510 920',
        where_passed:
          'Общество инвалидов Алмазарского районного отделения г.Ташкент',
        supermarket: 'Юнусабад'
      },
      {
        date: '31.08.2016',
        gathered_sum: '8 833 500',
        where_passed: 'Общество инвалидов Бектемирского районного отделения',
        supermarket: 'Карасу'
      },
      {
        date: '31.08.2016',
        gathered_sum: '6 729 200',
        where_passed: 'Центр культуры глухих г.Ташкент',
        supermarket: 'ТТЗ'
      },
      {
        date: '19.08.2016',
        gathered_sum: '12 395 700',
        where_passed: 'Детский дом №30',
        supermarket: 'Mercato'
      },
      {
        date: '17.08.2016',
        gathered_sum: '6 380 000',
        where_passed: 'Детский дом “Мехрибонлик” №23',
        supermarket: 'Туркменский'
      },
      {
        date: '15.06.2016',
        gathered_sum: '11 421 000',
        where_passed: 'РОСВИ “Мехрибонлик” Учтепинского района',
        supermarket: ''
      },
      {
        date: '14.06.2016',
        gathered_sum: '12 186 300',
        where_passed: 'Общество инвалидов Яшнабадского района',
        supermarket: ''
      },
      {
        date: '13.06.2016',
        gathered_sum: '6 673 000',
        where_passed: '«Sanvikt»',
        supermarket: ''
      },
      {
        date: '09.06.2016',
        gathered_sum: '12 522 500',
        where_passed: '«Status» Инвалиды спинальников',
        supermarket: ''
      },

      {
        date: '08.06.2016',
        gathered_sum: '17 173 700',
        where_passed: '«Лик»',
        supermarket: ''
      },

      {
        date: '18.05.2016',
        gathered_sum: '8 961 800',
        where_passed: 'Вспомогательная школа-интернат №105',
        supermarket: ''
      },

      {
        date: '11.05.2016',
        gathered_sum: '10 578 610',
        where_passed: 'ОО «APLA»',
        supermarket: ''
      },

      {
        date: '20.04.2016',
        gathered_sum: '8 862 500',
        where_passed: 'Общество инвалидов Мирзо-Улугбекского района',
        supermarket: ''
      },

      {
        date: '16.03.2016',
        gathered_sum: '7 647 700',
        where_passed:
          'Узбекское общество инвалидов и дети-инвалиды Алмалыкского района',
        supermarket: ''
      },

      {
        date: '24.02.2016',
        gathered_sum: '10 497 300',
        where_passed:
          'ОДИ «Ногирон –Болалар Камалак уйи». Приобретение лекарственных препаратов и продуктов питания, материальная помощь семьям с детьми инвалидами',
        supermarket: ''
      },

      {
        date: '17.02.2016',
        gathered_sum: '9 589 770',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов «Мехрибонлик» Миробадского района',
        supermarket: ''
      },

      {
        date: '27.01.2016',
        gathered_sum: '14 487 000',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов «Мехрибонлик» Миробадского района',
        supermarket: ''
      },

      {
        date: '20.01.2016',
        gathered_sum: '11 605 000',
        where_passed:
          'Общество Социальной взаимопомощи инвалидов «Мехрибонлик» Миробадского района',
        supermarket: ''
      }
    ],

    year: 'Итого за 2016 г.',
    sum: '188 055 500'
  },

  //  2015 year
  year_of_charity2015: {
    table: [
      {
        date: '23.12.2015',
        gathered_sum: '4 729 520',
        where_passed: 'Вспомогательная школа №36',
        supermarket: 'Аэропорт'
      },
      {
        date: '16.12.2015',
        gathered_sum: '11 940 800',
        where_passed: 'Детский дом №23',
        supermarket: 'Туркменский'
      },
      {
        date: '09.12.2015',
        gathered_sum: '9 878 000',
        where_passed: '«Опа-Сингиллар»',
        supermarket: 'Сергели'
      },
      {
        date: '02.12.2015',
        gathered_sum: '6 852 025',
        where_passed: '«Опа-Сингиллар». Лечение и обследование',
        supermarket: 'Integro'
      },
      {
        date: '25.11.2015',
        gathered_sum: '7 530 800',
        where_passed: '«Мурувват 2». Подгузники',
        supermarket: 'Караташ'
      },
      {
        date: '28.10.2015',
        gathered_sum: '11 652 920',
        where_passed: '«Мурувват 1». Лекарства и Витам. продукты',
        supermarket: 'Mercato'
      },
      {
        date: '04.10.2015',
        gathered_sum: '11 065 150',
        where_passed: 'Янгиюлский Дом Малютки. На мебель',
        supermarket: 'Юнусабад'
      },
      {
        date: '28.08.2015',
        gathered_sum: '9 460 955',
        where_passed: '«Sanvikt». Разв. кн., ремонт отоп. сист. каб.',
        supermarket: 'ТТЗ'
      },
      {
        date: '21.08.2015',
        gathered_sum: '14 904 500',
        where_passed: 'Детский дом №23. Портфели и учебные принадлежности',
        supermarket: 'Туркменский'
      },

      {
        date: '21.08.2015',
        gathered_sum: '7 505 600',
        where_passed: 'Детский дом №30. Учебные принадлежности и ремонт',
        supermarket: 'Ракат'
      },

      {
        date: '31.07.2015',
        gathered_sum: '12 266 200',
        where_passed: '«Umid-Nuri». Лечение и обследование детей',
        supermarket: 'Integro'
      },

      {
        date: '31.07.2015',
        gathered_sum: '14 969 970',
        where_passed: '«Мехрибонлик», ремонт крыши',
        supermarket: 'Абая'
      },

      {
        date: '31.07.2015',
        gathered_sum: '7 588 950',
        where_passed:
          'Бектемирское общество инвалидов. Оказание материальной помощи и прод. подарки',
        supermarket: 'Аэропорт'
      },

      {
        date: '31.07.2015',
        gathered_sum: '11 029 000',
        where_passed:
          'Бектемирское общество инвалидов. Ремонт учебных помещений',
        supermarket: 'Азимова'
      },

      {
        date: '12.06.2015',
        gathered_sum: '9 124 100',
        where_passed: 'Школа №37. Организация детского праздника',
        supermarket: 'Mercato'
      },

      {
        date: '15.05.2015',
        gathered_sum: '6 639 200',
        where_passed:
          'Городская Онкология, Детский отдел. На приобретение твёрдого инвентаря',
        supermarket: 'Караташ'
      },

      {
        date: '01.05.2015',
        gathered_sum: '8 379 500',
        where_passed: 'Общество «Veteran». Материальная помощь семьям погибших',
        supermarket: 'ТТЗ'
      },

      {
        date: '13.03.2015',
        gathered_sum: '10 635 500',
        where_passed: 'OO «APLA». Подарки и продукты к Наврузу',
        supermarket: 'Туркменский'
      },

      {
        date: '05.03.2015',
        gathered_sum: '7 659 500',
        where_passed: '«Sanvikt». На керамическую мастерскую',
        supermarket: 'Сергели'
      },

      {
        date: '12.02.2015',
        gathered_sum: '10 461 595',
        where_passed: 'Центр Культуры Глухих. Работа со слепоглухонемыми',
        supermarket: 'Integro'
      },

      {
        date: '05.02.2015',
        gathered_sum: '7 135 300',
        where_passed: 'Свято-Никол. Монастырь. Помощь нуждающимся',
        supermarket: 'Mercato'
      },

      {
        date: '16.01.2015',
        gathered_sum: '9 031 900',
        where_passed: 'Детский дом №2. Покупка обуви мальчикам',
        supermarket: 'Абай'
      },

      {
        date: '12.01.2015',
        gathered_sum: '7 253 300',
        where_passed:
          'Епархия. Оказание помощи социально уязвимым слоям населения',
        supermarket: 'ТТЗ'
      }
    ],

    year: 'Итого за 2015 г.',
    sum: '217 694 285'
  },

  //  2014 year
  year_of_charity2014: {
    table: [
      {
        date: '30.12.2014',
        gathered_sum: '4 682 500',
        where_passed: 'Серг.общ. «Мехрибон» Орг. Н.Г. и Помощь Лебещак Э.',
        supermarket: ''
      },
      {
        date: '26.11.2014',
        gathered_sum: '4 506 300',
        where_passed:
          'Бектемирское общ-во инвалидов, Организация праздника ко Дню Инвалидов',
        supermarket: ''
      },
      {
        date: '20.11.2014',
        gathered_sum: '7 934 200',
        where_passed: 'Абдулазизова Азиза. Порок сердца. Операция в Германии',
        supermarket: ''
      },
      {
        date: '07.11.2014',
        gathered_sum: '3 785 500',
        where_passed: 'Нуритдинов Самандар. ДЦП. Лечение КБ 5 в Ташкенте',
        supermarket: ''
      },
      {
        date: '31.10.2014',
        gathered_sum: '6 515 300',
        where_passed:
          'Гафуржанов Жахонгир. Острый лейкоз. Лечение в Москве, РДКБ',
        supermarket: 'Азимова'
      },
      {
        date: '24.10.2014',
        gathered_sum: '7 844 700',
        where_passed: 'Шелкунов Саша. Острый лейкоз. Лечение в НИИ Гематологии',
        supermarket: 'Integro'
      },
      {
        date: '10.10.2014',
        gathered_sum: '3 733 500',
        where_passed: 'Иргашева Жамиля. Кома 2. Лечение в России',
        supermarket: 'Ракат'
      },
      {
        date: '08.10.2014',
        gathered_sum: '4 327 400',
        where_passed:
          'Аракельян Карен. Аномалии мочевого пузыря. Операция в Индии',
        supermarket: 'ТТЗ'
      },
      {
        date: '26.09.2014',
        gathered_sum: '4 794 000',
        where_passed: 'Турсунбоев Жавохир. ДЦП после огнестрельного ранения',
        supermarket: 'Сергели'
      },

      {
        date: '11.09.2014',
        gathered_sum: '6 434 700',
        where_passed: 'Таирова Махлиё. Микроцефалия ЗПМР ДЦП. Лечение в России',
        supermarket: 'Mercato'
      },

      {
        date: '10.09.2014',
        gathered_sum: '8 817 200',
        where_passed:
          'Маъмуржановы Абдуллох и Азизбек. Врожденное наследственное ЗПМР ЗПРР. Лечение в Ташкенте',
        supermarket: 'Юнусабад'
      },

      {
        date: '09.09.2014',
        gathered_sum: '5 657 980',
        where_passed: 'Тулягановы Хумоюн и Аббос. Порок сердца',
        supermarket: 'Аэропорт'
      },

      {
        date: '08.09.2014',
        gathered_sum: '4 945 100',
        where_passed: 'Курбанов Тимур. Порок сердца. Операция в Индии',
        supermarket: 'Караташ'
      },

      {
        date: '06.08.2014',
        gathered_sum: '8 960 655',
        where_passed: 'Пардаева Камила. Порок сердца. Операция в Индии',
        supermarket: 'Туркменский'
      },

      {
        date: '05.08.2014',
        gathered_sum: '4 790 200',
        where_passed: 'Малыгин Миша. Острый Лейкоз. Лечение в НИИ Гематологии',
        supermarket: 'ТТЗ'
      },

      {
        date: '04.08.2014',
        gathered_sum: '5 485 050',
        where_passed: 'Эргашов Абдукадыр. ДЦП ЗПРР Лечение в Индии',
        supermarket: 'Абай'
      },

      {
        date: '25.06.2014',
        gathered_sum: '5 035 215',
        where_passed: 'Нажимова Мафтуна. Операция на глаза в Уфе',
        supermarket: 'Mercato'
      },

      {
        date: '24.06.2014',
        gathered_sum: '5 305 400',
        where_passed:
          'Центр Культуры Глухих. Благоустройство помещений работы с инвалидами',
        supermarket: 'Караташ'
      },

      {
        date: '26.05.2014',
        gathered_sum: '5 595 000',
        where_passed: 'Улькина Виктория. На лечение в Ж/Д больнице',
        supermarket: 'Сергели'
      },

      {
        date: '21.05.2014',
        gathered_sum: '5 725 000',
        where_passed:
          '«Sanvikt». На организацию летнего отдыха детей-инвалидов',
        supermarket: 'ТТЗ'
      },

      {
        date: '05.05.2014',
        gathered_sum: '8 485 000',
        where_passed: 'Муротова Умида. Операция на глаза в Уфе',
        supermarket: 'Азимова'
      },

      {
        date: '24.04.2014',
        gathered_sum: '7 925 000',
        where_passed: 'Бахтияров Мирзахид. Лечение в Гематологии',
        supermarket: 'Абай'
      },

      {
        date: '03.04.2014',
        gathered_sum: '8 525 000',
        where_passed: 'Театр «Лик» Помощь в теат. раб. с инвал',
        supermarket: 'Туркменский'
      },

      {
        date: '02.04.2014',
        gathered_sum: '4 145 000',
        where_passed: 'Музафарова Мафтуна. Лейкоз. Лечение в Москве',
        supermarket: 'Ракат'
      },

      {
        date: '26.03.2014',
        gathered_sum: '4 233 100',
        where_passed: 'Эргашева Сожидахон. ДЦП. Операция за границей',
        supermarket: 'Mercato'
      },

      {
        date: '14.03.2014',
        gathered_sum: '7 360 000',
        where_passed: 'Янгиюльский Дом Малютки. На мебель',
        supermarket: 'Аэропорт'
      },

      {
        date: '11.03.2014',
        gathered_sum: '5 462 500',
        where_passed: 'Нурмаев Григорий. Операция в Индии',
        supermarket: 'Караташ'
      },

      {
        date: '24.01.2014',
        gathered_sum: '5 655 000',
        where_passed: 'Шарипов Абдулазиз. Лечение в онкологии',
        supermarket: 'Юнусабад'
      },

      {
        date: '23.01.2014',
        gathered_sum: '3 455 000',
        where_passed: 'Юсупова Мохира. ДЦП. Лечение в Индии',
        supermarket: 'Mercato'
      },

      {
        date: '16.01.2014',
        gathered_sum: '6 691 500',
        where_passed: 'Занозин Арсений. Сморщив-е почки. На операцию',
        supermarket: 'Сергели'
      }
    ],

    year: 'Итого за 2014 г.',
    sum: '176 812 000'
  },

  // 2013 year
  year_of_charity2013: {
    table: [
      {
        date: '20.12.2013',
        gathered_sum: '7 710 000',
        where_passed: '«Мурувват 1» на лекарства, витамины и продовольствие',
        supermarket: 'Туркменский'
      },
      {
        date: '13.12.2013',
        gathered_sum: '7 826 240',
        where_passed: 'Камилов Мурод. Операция на сердце в Индии',
        supermarket: 'Абай'
      },
      {
        date: '29.11.2013',
        gathered_sum: '3 423 000',
        where_passed: 'Тухтамурадов Сирож. Операция на сердце в Индии',
        supermarket: 'Mercato'
      },
      {
        date: '05.11.2013',
        gathered_sum: '5 344 990',
        where_passed: 'Аракельян Карен. Трансплантология почки',
        supermarket: 'Караташ'
      },
      {
        date: '24.10.2013',
        gathered_sum: '6 496 000',
        where_passed: 'Кит Дарья. Лечение печени в Москве',
        supermarket: 'Азимова'
      },
      {
        date: '23.09.2013',
        gathered_sum: '5 114 200',
        where_passed: 'Аноним. Операция в Индии',
        supermarket: 'Mercato'
      },
      {
        date: '19.09.2013',
        gathered_sum: '8 060 000',
        where_passed: 'Сергелийское общество инвалидов. К Курбан-хайиту',
        supermarket: 'Аэропорт'
      },
      {
        date: '13.09.2013',
        gathered_sum: '4 051 300',
        where_passed: '«Sanvikt» на ремонт помещений для занятий',
        supermarket: 'Юнусабад'
      },
      {
        date: '13.08.2013',
        gathered_sum: '3 780 000',
        where_passed: 'Бектемирское общество инвалидов. Ко Дню Независимости',
        supermarket: 'Ракат'
      },

      {
        date: '26.07.2013',
        gathered_sum: '3 892 000',
        where_passed: 'Аноним',
        supermarket: 'Караташ'
      },

      {
        date: '25.07.2013',
        gathered_sum: '6 793 000',
        where_passed: 'Фарходова Шахризода. Опухоль мозга – Индия',
        supermarket: 'Абай'
      },

      {
        date: '23.07.2013',
        gathered_sum: '4 827 000',
        where_passed: 'Фаткулина Сабрина. Операция за границей',
        supermarket: 'Туркменский'
      },

      {
        date: '05.07.2013',
        gathered_sum: '5 089 950',
        where_passed: 'Театр «Лик». На аренду и образ. прогр',
        supermarket: 'Mercato'
      },

      {
        date: '10.06.2013',
        gathered_sum: '5 533 695',
        where_passed: 'Городское общество «УМР», на День Защиты детей',
        supermarket: 'Сергели'
      },

      {
        date: '17.05.2013',
        gathered_sum: '4 451 600',
        where_passed:
          'Алмазарское отделение инвалидов. Материальная помощь малоимущим',
        supermarket: 'Караташ'
      },

      {
        date: '16.05.2013',
        gathered_sum: '6 265 200',
        where_passed: 'Тулкинжинов Фаррух. Операция Имплантат за границей',
        supermarket: 'Туркменский'
      },

      {
        date: '17.04.2013',
        gathered_sum: '4 836 295',
        where_passed: 'Аноним',
        supermarket: 'Азимова'
      },

      {
        date: '16.04.2013',
        gathered_sum: '3 149 900',
        where_passed: 'Аноним',
        supermarket: 'Mercato'
      },

      {
        date: '15.03.2013',
        gathered_sum: '4 553 025',
        where_passed: 'Амели Джовидон. Операция в Японии',
        supermarket: 'Абай'
      },

      {
        date: '12.03.2013',
        gathered_sum: '6 278 725',
        where_passed:
          'Городской Онкологический диспансер. На лекарственные средства для детского отделения',
        supermarket: 'Аэропорт'
      },

      {
        date: '27.02.2013',
        gathered_sum: '5 052 500',
        where_passed: 'НИИ Гематологии и Переливания крови',
        supermarket: 'Караташ'
      },

      {
        date: '19.02.2013',
        gathered_sum: '5 630 400',
        where_passed: 'Махалля «Чоштепа»',
        supermarket: 'Туркменский'
      },

      {
        date: '08.02.2013',
        gathered_sum: '5 660 450',
        where_passed: 'Детский дом «Мурувват 2»',
        supermarket: 'Mercato'
      },

      {
        date: '31.01.2013',
        gathered_sum: '6 252 440',
        where_passed: 'Саидова Муштарийбону',
        supermarket: 'Сергели'
      }
    ],

    year: 'Итого за 2013 г.',
    sum: '130 071 910'
  },

  // 2012 year
  year_of_charity2012: {
    table: [
      {
        date: '19.11.2012',
        gathered_sum: '3 728 565',
        where_passed: 'Общество глухих',
        supermarket: 'Абай'
      },
      {
        date: '16.11.2012',
        gathered_sum: '3 248 550',
        where_passed: 'Детский дом «Мурувват 1»',
        supermarket: 'Караташ'
      },
      {
        date: '30.10.2012',
        gathered_sum: '5 194 235',
        where_passed: 'Театр «Лик»',
        supermarket: ''
      },
      {
        date: '02.10.2012',
        gathered_sum: '4 607 425',
        where_passed: 'Школа-интернат №98. Для малообеспеченных',
        supermarket: 'Аэропорт'
      },
      {
        date: '21.09.2012',
        gathered_sum: '5 297 100',
        where_passed: 'Детский дом №31',
        supermarket: 'Mercato'
      },
      {
        date: '10.08.2012',
        gathered_sum: '2 978 950',
        where_passed: 'Алмазарский филиал городского Общества инвалидов',
        supermarket: 'Абай'
      },
      {
        date: '26.07.2012',
        gathered_sum: '1 943 800',
        where_passed: 'Детский дом №30',
        supermarket: 'Караташ'
      },
      {
        date: '24.07.2012',
        gathered_sum: '3 373 040',
        where_passed: 'Бектемирское отделение общества инвалидов',
        supermarket: ''
      },
      {
        date: '24.05.2012',
        gathered_sum: '1 469 830',
        where_passed: 'Марин Алексей',
        supermarket: 'Караташ'
      },

      {
        date: '16.05.2012',
        gathered_sum: '3 276 330',
        where_passed: 'Детская Площадка Детского дома №14',
        supermarket: ''
      },

      {
        date: '15.05.2012',
        gathered_sum: '2 487 350',
        where_passed: 'Детская Площадка Детского дома №14',
        supermarket: 'Абай'
      },

      {
        date: '27.04.2012',
        gathered_sum: '5 662 105',
        where_passed: 'Детский дом №15 г.Бука',
        supermarket: 'Mercato'
      },

      {
        date: '19.04.2012',
        gathered_sum: '3 623 700',
        where_passed: '«Sanvikt»',
        supermarket: 'Аэропорт'
      },

      {
        date: '30.03.2012',
        gathered_sum: '2 474 330',
        where_passed:
          'Детское отделение Республиканского Онкологического научного центра',
        supermarket: 'Караташ'
      },

      {
        date: '16.03.2012',
        gathered_sum: '2 900 400',
        where_passed: 'Дом Ребенка «Кичкинтой» г.Янгиюль',
        supermarket: ''
      },

      {
        date: '02.02.2012',
        gathered_sum: '4 208 450',
        where_passed: 'Детский дом №14 г.Чирчик',
        supermarket: 'Абай'
      },

      {
        date: '20.01.2012',
        gathered_sum: '2 785 105',
        where_passed: 'Республиканская центральная библиотека для слепых',
        supermarket: ''
      }
    ],

    year: 'Итого за 2012 г.',
    sum: '59 259 265'
  },

  // 2011 year
  year_of_charity2011: {
    table: [
      {
        date: '23.12.2011',
        gathered_sum: '2 164 400',
        where_passed: 'Детское отделение Городского Онкологического Диспансера',
        supermarket: 'Караташ'
      },
      {
        date: '09.12.2011',
        gathered_sum: '2 565 445',
        where_passed: 'Об-во социал. Взаимопомощи инв-в «Мехрибонлик»',
        supermarket: 'Аэропорт'
      },
      {
        date: '25.11.2011',
        gathered_sum: '3 000 950',
        where_passed: 'Городское отделение Узбекского общества Инвалидов',
        supermarket: ''
      },
      {
        date: '04.11.2011',
        gathered_sum: '2 751 725',
        where_passed: 'Общество глухих',
        supermarket: 'Абай'
      },
      {
        date: '30.09.2011',
        gathered_sum: '2 348 455',
        where_passed: 'Интернат для слабослышащих №102',
        supermarket: 'Караташ'
      },
      {
        date: '16.09.2011',
        gathered_sum: '2 498 410',
        where_passed: '«Дом Малютки»',
        supermarket: ''
      },
      {
        date: '28.07.2011',
        gathered_sum: '1 790 170',
        where_passed: '«Sanvikt»',
        supermarket: 'Караташ'
      },
      {
        date: '27.07.2011',
        gathered_sum: '1 913 700',
        where_passed: 'Детский дом «Мурувват 1»',
        supermarket: 'Абай'
      },
      {
        date: '26.07.2011',
        gathered_sum: '2 853 845',
        where_passed: 'Детский дом «Мурувват 2»',
        supermarket: ''
      },

      {
        date: '10.06.2011',
        gathered_sum: '2 455 490',
        where_passed: 'Бектемирский филиал общества Инвалидов',
        supermarket: 'Аэропорт'
      },

      {
        date: '20.05.2011',
        gathered_sum: '1 816 255',
        where_passed: '«Умидварлик»',
        supermarket: ''
      },

      {
        date: '06.05.2011',
        gathered_sum: '2 281 350',
        where_passed: 'Вспомогательная школа №37',
        supermarket: 'Абай'
      },

      {
        date: '08.04.2011',
        gathered_sum: '2 714 760',
        where_passed: 'Театр «Лик»',
        supermarket: ''
      },

      {
        date: '04.03.2011',
        gathered_sum: '2 924 205',
        where_passed: 'Общество «SOS Деревня»',
        supermarket: ''
      },

      {
        date: '18.02.2011',
        gathered_sum: '2 751 515',
        where_passed: 'ОО «APLA»',
        supermarket: 'Аэропорт'
      },

      {
        date: '04.01.2011',
        gathered_sum: '2 101 040',
        where_passed: '«Sanvikt»',
        supermarket: ''
      }
    ],

    year: 'Итого за 2011 г.',
    sum: '	38 931 715'
  },

  // 2010 year
  year_of_charity2010: {
    table: [
      {
        date: '11.12.2010',
        gathered_sum: '2 322 360',
        where_passed: 'Дет.ПНБ.',
        supermarket: ''
      },
      {
        date: '08.10.2010',
        gathered_sum: '2 000 600',
        where_passed: '5 ГКДБ',
        supermarket: 'Аэропорт'
      },
      {
        date: '24.09.2010',
        gathered_sum: '2 401 600',
        where_passed: 'Детский дом №23',
        supermarket: ''
      },
      {
        date: '05.08.2010',
        gathered_sum: '1 627 730',
        where_passed: 'НИИ Гематологии',
        supermarket: ''
      },
      {
        date: '15.06.2010',
        gathered_sum: '1 918 010',
        where_passed: 'Детский дом №22',
        supermarket: ''
      },
      {
        date: '27.04.2010',
        gathered_sum: '1 919 430',
        where_passed: 'Детский дом «Мурувват 2»',
        supermarket: ''
      },
      {
        date: '22.02.2010',
        gathered_sum: '1 533 100',
        where_passed: 'Детский дом «Мурувват 1»',
        supermarket: ''
      }
    ],

    year: 'Итого за 2010 г.',
    sum: '13 722 830'
  },

  // 2009 year
  year_of_charity2009: {
    table: [
      {
        date: '18.12.2009',
        gathered_sum: '1 130 800',
        where_passed: 'Детский дом №30',
        supermarket: ''
      },
      {
        date: '23.10.2009',
        gathered_sum: '1 000 085',
        where_passed: 'Детский дом №31',
        supermarket: ''
      },
      {
        date: '20.05.2009',
        gathered_sum: '1 573 125',
        where_passed: 'Детский дом №21',
        supermarket: ''
      },
      {
        date: '23.01.2009',
        gathered_sum: '970 350',
        where_passed: 'Детский дом №24',
        supermarket: ''
      }
    ],

    year: 'Итого за 2009 г.',
    sum: '4 674 360'
  },

  // 2008 year
  year_of_charity2008: {
    table: [
      {
        date: '14.11.2008',
        gathered_sum: '773 335',
        where_passed: '«Дом Малютки»',
        supermarket: ''
      }
    ],

    year: 'Итого за 2008 г.',
    sum: '773 335'
  },
  faq_title: 'Часто задаваемые вопросы',
  faq_loyalty_title: 'Как это работает:',
  share: 'Поделиться',
  // footer_disclamer: "Информация на сайте имеет ознакомительный характер и не является публичной офертой. Информация может включать или не включать в себя такие пункты, как:<br /><br /> <ul><li>Стоимость товаров указана в сумах.</li><li>Все предложения распространяются только на сеть супермаркетов Корзинка.</li> <li>Внешний вид рекламируемого товара может отличаться от представленного в магазине.</li> <li>Цены на товары в магазине могут быть ниже, чем указано в рекламных материалах.</li> <li>Количество акционного товара может быть ограничено.</li> <li>Акция не суммируется с другими предложениями по данным товарам.</li> <li>Кешбэк не начисляется на карту лояльности Korzinka Plus при оплате подарочными сертификатами, а также накопленными бонусами.</li> <li>Цены и ассортимент товаров могут не совпадать с ценами и ассортиментом товаров в магазинах Корзинка DISKONT.</li> <li>Все товары сертифицированы.</li></ul>",
  // footer_disclamer: "Информация на сайте имеет ознакомительный характер и не является публичной офертой. Информация может включать или не включать в себя такие пункты, как:<br /><br /> — Стоимость товаров указана в сумах.<br /> — Все предложения распространяются только на сеть супермаркетов Корзинка.<br />— Внешний вид рекламируемого товара может отличаться от представленного в магазине.<br />— Цены на товары в магазине могут быть ниже, чем указано в рекламных материалах.<br />— Количество акционного товара может быть ограничено.<br />— Акция не суммируется с другими предложениями по данным товарам.<br />— Кешбэк не начисляется на карту лояльности Korzinka Plus при оплате подарочными сертификатами, а также накопленными бонусами.<br />— Цены и ассортимент товаров могут не совпадать с ценами и ассортиментом товаров в магазинах Корзинка DISKONT.<br />— Все товары сертифицированы.",
  footer_disclamer: `
    <p>Информация на сайте имеет ознакомительный характер и не является публичной офертой. Информация может включать или не включать в себя такие пункты, как:</p>
    <br />
    <p>•&nbsp;&nbsp; Стоимость товаров указана в сумах.</p>
    <p>•&nbsp;&nbsp; Все предложения распространяются только на сеть супермаркетов Корзинка.</p>
    <p>•&nbsp;&nbsp; Внешний вид рекламируемого товара может отличаться от представленного в магазине.</p>
    <p>•&nbsp;&nbsp; Цены на товары в магазине могут быть ниже, чем указано в рекламных материалах.</p>
    <p>•&nbsp;&nbsp; Количество акционного товара может быть ограничено.</p>
    <p>•&nbsp;&nbsp; Акция не суммируется с другими предложениями по данным товарам.</p>
    <p>•&nbsp;&nbsp; Кешбэк не начисляется на карту лояльности Korzinka Plus при оплате подарочными сертификатами, а также накопленными бонусами.</p>
    <p>•&nbsp;&nbsp; Цены и ассортимент товаров могут не совпадать с ценами и ассортиментом товаров в магазинах Корзинка DISKONT.</p>
    <p>•&nbsp;&nbsp; Все товары сертифицированы.</p>
  `,
  ctm_iz_korzinki_text: 'Премиальное качество',
  ctm_365kun_text: 'Доступность и качество',
  cashback_tag_label: 'кэшбэк',
  novelty_tag_label: 'новинка',
  selfmade_tag_label: 'сделано нами',
  discount_by_card: 'по карте',
  coupon_checker_button_label: 'Проверить участие',
  coupon_checker_input_label: 'Введите номер карты лояльности',
  coupon_checker_schedules_title: 'У вас',
  coupon_checker_number: 'Номера участия',
  chances_title: 'шанс',
  chances_title_2: 'шанса',
  chances_title_3: 'шансов',
  chances_error:
    'Похоже, вы пока не участвуете в акции или данные еще не обновлены. Если с момента покупки прошло больше 60 минут — свяжитесь с нами по телефону <a href="tel:+998781401414">+998 (78) 140-14-14</a>',
  // spec-catalog footer content
  spec_catalog_footer: `<h2>С заботой о вас: мы предлагаем самые низкие цены для ежедневных покупок!</h2>
<p>&nbsp;</p>
<p>Мы в Корзинке всегда стремимся сделать покупки более выгодными для вас, а процесс &ndash; комфортным и приятным.&nbsp;</p>
<p>В продолжении этой инициативы мы запустили акцию Eng arzon narx, в рамках которой предлагаем нашим гостям самые низкие цены на корзину из товаров для ежедневных покупок. Для этого мы на постоянной основе проводим ценовой мониторинг среди супермаркетов и базаров Ташкента и Ташкентской области:</p>
<p>&ndash;&nbsp;OOO Darvoza Savdo (Makro)</p>
<p>&ndash;&nbsp;СП ООО Havas Food (Havas)</p>
<p>&ndash;&nbsp;СП ООО &laquo;Urban Retail&raquo; (Baraka)</p>
<p>&ndash;&nbsp;ИП ООО &laquo;Magnum Retail&raquo; (Magnum)</p>
<p>&ndash;&nbsp;OOО &laquo;NEW RETAIL&raquo; (bi1)</p>
<p>&ndash;&nbsp;ИП ООО &laquo;Магнит Средняя Азия&raquo; (M COSMETIC)</p>
<p>&ndash;&nbsp;OOO &laquo;Xalq retail&raquo; (Olma)</p>
<p>&ndash;&nbsp;Фархадский рынок&nbsp;</p>
<p>&ndash;&nbsp;Юнусабадский дехканский рынок</p>
<p>&ndash;&nbsp;Рынок Чорсу</p>
<p>&nbsp;</p>
<p>Для сравнения цен были взяты товары, идентичные по бренду, модели, сорту, калибру, размеру, качеству, весу и цвету, представленным в Корзинке. Мы еженедельно отслеживаем на них цены, а каждый из товаров, участвующих в акции отмечен рекламным материалом и ценником &ldquo;Eng arzon narx&rdquo; (&laquo;Самая низкая цена&raquo;). За сравнение берется общая цена корзины. Товары по скидкам и акциям не учитываются при мониторинге.</p>
<p>Акция &ldquo;Eng arzon narx&rdquo; действует во всех супермаркетах сети, включая форматы Корзинка, Корзинка Махалля, Korzinka Diskont, Smart и сервис Korzinka Go. Специальные цены не распространяются на товары, приобретенные за пределами Ташкента и Ташкентской области. Мы работаем над тем, чтобы в ближайшее время запустить акцию по всей Республике Узбекистан.</p>
<p>Сроки проведения акции: по 31 декабря 2024 г. с возможностью продления.</p>
<p>Данная акция действует исключительно по усмотрению компании OOO Anglesey Food и может быть изменена в любое время без предварительного уведомления.&nbsp; Корзинка оставляет за собой право отказать в корректировке цен, которые не могут быть проверены или выходят за рамки нашей политики. При возникновении дополнительных вопросов, пожалуйста, обратитесь к кассиру, менеджеру магазина или позвоните нашим специалистам по работе с клиентами по телефону: +998 (78) 140-14-14.</p>
<p>* Стоимость товаров в каталоге указана в сумах</p>
<p>* Стоимость некоторых товаров может быть ниже, чем указана в каталоге</p>
<p>* Список товаров, участвующих в акции &ldquo;Eng arzon narx&rdquo;, может быть изменен по усмотрению компании OOO Anglesey Food в любое время без предварительного уведомления.</p>
<p>* Бонусы по программе лояльности Korzinka Plus на товары Eng arzon narx не начисляются.</p>
`,
  // sales
  sales_text: ` Предложения <br/> для вас`
}
