export default function ({ route, app }) {
  const isReferralPage = route.path === '/go' && 'referrer' in route.query

  if (isReferralPage) {
    // Удаление существующего мета-тега 'robots'
    const filteredMeta = app.head.meta.filter((meta) => meta.hid !== 'robots')

    // Добавление нового мета-тега 'noindex, nofollow'
    const noIndexMeta = {
      hid: 'robots',
      name: 'robots',
      content: 'noindex, nofollow'
    }

    // Обновление массива meta в head
    app.head.meta = [...filteredMeta, noIndexMeta]
  }
}
