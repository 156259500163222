import locale6c33751b from '../../locales/uz'
import locale6c3374b9 from '../../locales/ru'
import locale6c33731f from '../../locales/en'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"uz","name":"O'zbek","file":"uz"},{"code":"ru","name":"Русский","file":"ru"},{"code":"en","name":"English","file":"en"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"uz","name":"O'zbek","file":"uz"},{"code":"ru","name":"Русский","file":"ru"},{"code":"en","name":"English","file":"en"}],
  localeCodes: ["uz","ru","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "u",
  3: "z",
  4: "\"",
  5: ":",
  6: "\"",
  7: ".",
  8: ".",
  9: "/",
  10: "l",
  11: "o",
  12: "c",
  13: "a",
  14: "l",
  15: "e",
  16: "s",
  17: "/",
  18: "u",
  19: "z",
  20: "\"",
  21: ",",
  22: "\"",
  23: "r",
  24: "u",
  25: "\"",
  26: ":",
  27: "\"",
  28: ".",
  29: ".",
  30: "/",
  31: "l",
  32: "o",
  33: "c",
  34: "a",
  35: "l",
  36: "e",
  37: "s",
  38: "/",
  39: "r",
  40: "u",
  41: "\"",
  42: ",",
  43: "\"",
  44: "e",
  45: "n",
  46: "\"",
  47: ":",
  48: "\"",
  49: ".",
  50: ".",
  51: "/",
  52: "l",
  53: "o",
  54: "c",
  55: "a",
  56: "l",
  57: "e",
  58: "s",
  59: "/",
  60: "e",
  61: "n",
  62: "\"",
  63: "}",
}

export const localeMessages = {
  'uz': () => Promise.resolve(locale6c33751b),
  'ru': () => Promise.resolve(locale6c3374b9),
  'en': () => Promise.resolve(locale6c33731f),
}
