const middleware = {}

middleware['ABRoute'] = require('../middleware/ABRoute.js')
middleware['ABRoute'] = middleware['ABRoute'].default || middleware['ABRoute']

middleware['language'] = require('../middleware/language.js')
middleware['language'] = middleware['language'].default || middleware['language']

middleware['noindex'] = require('../middleware/noindex.js')
middleware['noindex'] = middleware['noindex'].default || middleware['noindex']

middleware['robots'] = require('../middleware/robots.js')
middleware['robots'] = middleware['robots'].default || middleware['robots']

export default middleware
