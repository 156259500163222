export const BestOffer = () => import('../../components/BestOffer.vue' /* webpackChunkName: "components/best-offer" */).then(c => wrapFunctional(c.default || c))
export const Charity = () => import('../../components/Charity.vue' /* webpackChunkName: "components/charity" */).then(c => wrapFunctional(c.default || c))
export const CouponChecker = () => import('../../components/CouponChecker.vue' /* webpackChunkName: "components/coupon-checker" */).then(c => wrapFunctional(c.default || c))
export const CouponItem = () => import('../../components/CouponItem.vue' /* webpackChunkName: "components/coupon-item" */).then(c => wrapFunctional(c.default || c))
export const CouponWrapper = () => import('../../components/CouponWrapper.vue' /* webpackChunkName: "components/coupon-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const Mobi = () => import('../../components/Mobi.vue' /* webpackChunkName: "components/mobi" */).then(c => wrapFunctional(c.default || c))
export const News = () => import('../../components/News.vue' /* webpackChunkName: "components/news" */).then(c => wrapFunctional(c.default || c))
export const NewsForm = () => import('../../components/NewsForm.vue' /* webpackChunkName: "components/news-form" */).then(c => wrapFunctional(c.default || c))
export const PermanentPromotion = () => import('../../components/PermanentPromotion.vue' /* webpackChunkName: "components/permanent-promotion" */).then(c => wrapFunctional(c.default || c))
export const RealEstatePortal = () => import('../../components/RealEstatePortal.vue' /* webpackChunkName: "components/real-estate-portal" */).then(c => wrapFunctional(c.default || c))
export const RegionSeoBlock = () => import('../../components/RegionSeoBlock.vue' /* webpackChunkName: "components/region-seo-block" */).then(c => wrapFunctional(c.default || c))
export const Sales = () => import('../../components/Sales.vue' /* webpackChunkName: "components/sales" */).then(c => wrapFunctional(c.default || c))
export const SearchTools = () => import('../../components/SearchTools.vue' /* webpackChunkName: "components/search-tools" */).then(c => wrapFunctional(c.default || c))
export const SeoBlock = () => import('../../components/SeoBlock.vue' /* webpackChunkName: "components/seo-block" */).then(c => wrapFunctional(c.default || c))
export const SpecialMultiCategory = () => import('../../components/SpecialMultiCategory.vue' /* webpackChunkName: "components/special-multi-category" */).then(c => wrapFunctional(c.default || c))
export const SpecialSingleCategory = () => import('../../components/SpecialSingleCategory.vue' /* webpackChunkName: "components/special-single-category" */).then(c => wrapFunctional(c.default || c))
export const TheAccordion = () => import('../../components/TheAccordion.vue' /* webpackChunkName: "components/the-accordion" */).then(c => wrapFunctional(c.default || c))
export const TheAccordionItem = () => import('../../components/TheAccordionItem.vue' /* webpackChunkName: "components/the-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const TheCollapse = () => import('../../components/TheCollapse.vue' /* webpackChunkName: "components/the-collapse" */).then(c => wrapFunctional(c.default || c))
export const TheShare = () => import('../../components/TheShare.vue' /* webpackChunkName: "components/the-share" */).then(c => wrapFunctional(c.default || c))
export const TopProducts = () => import('../../components/TopProducts.vue' /* webpackChunkName: "components/top-products" */).then(c => wrapFunctional(c.default || c))
export const Work = () => import('../../components/Work.vue' /* webpackChunkName: "components/work" */).then(c => wrapFunctional(c.default || c))
export const AboutAwards = () => import('../../components/About/Awards.vue' /* webpackChunkName: "components/about-awards" */).then(c => wrapFunctional(c.default || c))
export const AboutHistory = () => import('../../components/About/History.vue' /* webpackChunkName: "components/about-history" */).then(c => wrapFunctional(c.default || c))
export const AboutStaff = () => import('../../components/About/Staff.vue' /* webpackChunkName: "components/about-staff" */).then(c => wrapFunctional(c.default || c))
export const AboutStores = () => import('../../components/About/Stores.vue' /* webpackChunkName: "components/about-stores" */).then(c => wrapFunctional(c.default || c))
export const AboutStoresModal = () => import('../../components/About/StoresModal.vue' /* webpackChunkName: "components/about-stores-modal" */).then(c => wrapFunctional(c.default || c))
export const AboutTestimonials = () => import('../../components/About/Testimonials.vue' /* webpackChunkName: "components/about-testimonials" */).then(c => wrapFunctional(c.default || c))
export const AppCookie = () => import('../../components/App/Cookie.vue' /* webpackChunkName: "components/app-cookie" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/App/Footer.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/App/Header.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppLoader = () => import('../../components/App/Loader.vue' /* webpackChunkName: "components/app-loader" */).then(c => wrapFunctional(c.default || c))
export const MainBannersBigBanner = () => import('../../components/MainBanners/BigBanner.vue' /* webpackChunkName: "components/main-banners-big-banner" */).then(c => wrapFunctional(c.default || c))
export const MainBannersStandartBanner = () => import('../../components/MainBanners/StandartBanner.vue' /* webpackChunkName: "components/main-banners-standart-banner" */).then(c => wrapFunctional(c.default || c))
export const MainBannersStandartWithoutEllipseBanner = () => import('../../components/MainBanners/StandartWithoutEllipseBanner.vue' /* webpackChunkName: "components/main-banners-standart-without-ellipse-banner" */).then(c => wrapFunctional(c.default || c))
export const PostBuilder = () => import('../../components/Post/Builder.vue' /* webpackChunkName: "components/post-builder" */).then(c => wrapFunctional(c.default || c))
export const PostLarge = () => import('../../components/Post/Large.vue' /* webpackChunkName: "components/post-large" */).then(c => wrapFunctional(c.default || c))
export const PostMiddle = () => import('../../components/Post/Middle.vue' /* webpackChunkName: "components/post-middle" */).then(c => wrapFunctional(c.default || c))
export const PostStandard = () => import('../../components/Post/Standard.vue' /* webpackChunkName: "components/post-standard" */).then(c => wrapFunctional(c.default || c))
export const SalesLayoutFirst = () => import('../../components/Sales/LayoutFirst.vue' /* webpackChunkName: "components/sales-layout-first" */).then(c => wrapFunctional(c.default || c))
export const SalesLayoutFive = () => import('../../components/Sales/LayoutFive.vue' /* webpackChunkName: "components/sales-layout-five" */).then(c => wrapFunctional(c.default || c))
export const SalesLayoutFourth = () => import('../../components/Sales/LayoutFourth.vue' /* webpackChunkName: "components/sales-layout-fourth" */).then(c => wrapFunctional(c.default || c))
export const SalesLayoutSecond = () => import('../../components/Sales/LayoutSecond.vue' /* webpackChunkName: "components/sales-layout-second" */).then(c => wrapFunctional(c.default || c))
export const SalesLayoutThird = () => import('../../components/Sales/LayoutThird.vue' /* webpackChunkName: "components/sales-layout-third" */).then(c => wrapFunctional(c.default || c))
export const SpecialLayoutFirst = () => import('../../components/Special/LayoutFirst.vue' /* webpackChunkName: "components/special-layout-first" */).then(c => wrapFunctional(c.default || c))
export const SpecialLayoutFive = () => import('../../components/Special/LayoutFive.vue' /* webpackChunkName: "components/special-layout-five" */).then(c => wrapFunctional(c.default || c))
export const SpecialLayoutFourth = () => import('../../components/Special/LayoutFourth.vue' /* webpackChunkName: "components/special-layout-fourth" */).then(c => wrapFunctional(c.default || c))
export const SpecialLayoutSecond = () => import('../../components/Special/LayoutSecond.vue' /* webpackChunkName: "components/special-layout-second" */).then(c => wrapFunctional(c.default || c))
export const SpecialLayoutThird = () => import('../../components/Special/LayoutThird.vue' /* webpackChunkName: "components/special-layout-third" */).then(c => wrapFunctional(c.default || c))
export const BaseBigBanner = () => import('../../components/base/BigBanner.vue' /* webpackChunkName: "components/base-big-banner" */).then(c => wrapFunctional(c.default || c))
export const BaseCustomBanner = () => import('../../components/base/CustomBanner.vue' /* webpackChunkName: "components/base-custom-banner" */).then(c => wrapFunctional(c.default || c))
export const BaseEmptyResult = () => import('../../components/base/EmptyResult.vue' /* webpackChunkName: "components/base-empty-result" */).then(c => wrapFunctional(c.default || c))
export const BaseProduct = () => import('../../components/base/Product.vue' /* webpackChunkName: "components/base-product" */).then(c => wrapFunctional(c.default || c))
export const GrievancepolicyContentEN = () => import('../../components/grievancepolicy/ContentEN.vue' /* webpackChunkName: "components/grievancepolicy-content-e-n" */).then(c => wrapFunctional(c.default || c))
export const GrievancepolicyContentRU = () => import('../../components/grievancepolicy/ContentRU.vue' /* webpackChunkName: "components/grievancepolicy-content-r-u" */).then(c => wrapFunctional(c.default || c))
export const GrievancepolicyContentUZ = () => import('../../components/grievancepolicy/ContentUZ.vue' /* webpackChunkName: "components/grievancepolicy-content-u-z" */).then(c => wrapFunctional(c.default || c))
export const AboutComponentsBusinessIcon = () => import('../../components/About/components/BusinessIcon.vue' /* webpackChunkName: "components/about-components-business-icon" */).then(c => wrapFunctional(c.default || c))
export const AboutComponentsKorzinkaGoIcon = () => import('../../components/About/components/KorzinkaGoIcon.vue' /* webpackChunkName: "components/about-components-korzinka-go-icon" */).then(c => wrapFunctional(c.default || c))
export const AboutComponentsKorzinkaIcon = () => import('../../components/About/components/KorzinkaIcon.vue' /* webpackChunkName: "components/about-components-korzinka-icon" */).then(c => wrapFunctional(c.default || c))
export const AboutComponentsMahallaIcon = () => import('../../components/About/components/MahallaIcon.vue' /* webpackChunkName: "components/about-components-mahalla-icon" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
