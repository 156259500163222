
export default function ({ app, req, route, redirect, res }) {
  if (process.server) {
    const cookies = req?.headers?.cookie?.split('; ')?.reduce((acc, cookie) => {
      const [name, value] = cookie.split('=');
      acc[name] = value;
      return acc;
    }, {}) || null;
    
    const isRedirected = cookies?.redirected || null;
  
    if(isRedirected) {
      res.setHeader('Set-Cookie', 'redirected=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT');
      return true;
    }
  
  
    let preferredLanguage;
    try {
      preferredLanguage = app.$cookies.get('preferred_language');
    } catch (error) {
      console.error("Error reading cookies:", error);
    }
  
    const supportedLanguages = ['ru', 'en', 'uz'];
    const currentPath = route.fullPath;
    const pathSegments = currentPath.split('/');
    const currentLanguage = pathSegments.length > 1 && supportedLanguages.includes(pathSegments[1]) ? pathSegments[1] : null;
  
    // console.log(currentLanguage);
    // Если в URL уже есть языковой префикс, не выполняем перенаправление
    if (currentLanguage) return;
  
    // Определяем язык для перенаправления
    let languageForRedirect = preferredLanguage;
    if (!preferredLanguage) {
      const languageFromBrowser = req.headers['accept-language'].split(',')[0].split('-')[0];
      languageForRedirect = supportedLanguages.includes(languageFromBrowser) ? languageFromBrowser : 'uz';
    }
  
    // Формируем и выполняем перенаправление, если текущий язык отличается
    if (app.i18n.locale !== languageForRedirect) {
      const redirectPath = languageForRedirect === 'ru' ? currentPath : `/${languageForRedirect}${currentPath}`;
      redirect(redirectPath);
    }
  }
}