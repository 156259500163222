export default function ({ route, redirect, $axios }) {
  $axios
    .post(`${process.env.API_URL_SITE}/redirect`, {
      url: route.path
    })
    .then((res) => {
      if (res.data.data.item !== '') {
        return redirect(res.data.data.item)
      }
    })
}
