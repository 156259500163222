// middleware/robots.js
const axios = require('axios');

export default async function (req, res) {
  try {
    const response = await axios.get(`${process.env.API_URL_SITE}/robots/get`);

    // Проверим, что ответ содержит данные и они являются строкой
    if (response && response.data.data && response.data.data.robots && typeof response.data.data.robots === 'string') {
      const data = response.data.data.robots;

      res.setHeader('Content-Type', 'text/plain');
      res.end(data);
    } else {
      console.error('Invalid data format received from the API');
      // You may want to provide a default robots.txt in case of an error
      res.setHeader('Content-Type', 'text/plain');
      res.end('User-agent: *\nDisallow: /');
    }
  } catch (error) {
    console.error('Error fetching data from the API:', error.message);
    // You may want to provide a default robots.txt in case of an error
    res.setHeader('Content-Type', 'text/plain');
    res.end('User-agent: *\nDisallow: /');
  }
}
